import React, { useState } from 'react';
import { withContext } from '../../../../context';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, StepLabel, Step, Stepper } from '@material-ui/core';
import { Box, Button, Paper } from '@material-ui/core';
import FeatureSelectionResults from './Steps/FeatureSelection/FeatureSelectionResults';
import Hidden from '@material-ui/core/Hidden';
import { Progress, Alert } from '../../../../Components';
import StepContent from "./StepContent";

import { addName, addProblemType, handelNewData, addData, normalize, validate, features, cluster } from './Controller';


const useStyles = makeStyles(theme => ({
  root: { background: 'linear-gradient(#1e1e2f,#1e1e24)' },
  instructions: { marginTop: theme.spacing(1), paddingBottom: 20 },
  paper: {
    background: "#27293d", margin: theme.spacing(2, 2), padding: theme.spacing(2, 2),
    borderRadius: 10, minHeight: '85vh'
  },
  select: { minWidth: 100, width: 150, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  button: { marginLeft: theme.spacing(1), marginBottom: theme.spacing(1), marginTop: 0, width: 100 },
  input: { width: 300, [theme.breakpoints.down('sm')]: { width: '100%' }, }
}));

const getStep = (category) => {
  return category === "Clustering" ?
    ['Model Information', 'Problem Type', 'Load Data', 'Preview Data', 'Preprocessing',
      'Clustering', "Clustering Results", 'Visualization', 'Validation', 'Feature Selection'] :
    ['Model Information', 'Problem Type', 'Load Data', 'Preview Data', 'Preprocessing',
      'Visualization', 'Validation', 'Feature Selection']
};

const MutiStep = ({ history, state, setState, resetState, fetchData, token, path }) => {


  const
    classes = useStyles(),
    [loading, setLoading] = useState(false),
    [alert, setAlert] = useState({ alert: false, message: "", severity: "info" }),
    [disableHeader, setDisableHeader] = useState(false),
    [disabled, setDisabled] = useState(false),
    [isExist, setExist] = useState(false),
    [file, setFile] = useState(null),
    steps = getStep(state.ProblemType, path);

  const
    handleNext = () => {
      setState(prevState => ({ ...prevState, ActiveStep: prevState.ActiveStep + 1 }));      // if (state.ActiveStep === 1 && !state.ProblemType) setDisabled(true);
      if (state.ActiveStep === 2 && state.Data.length === 0) setDisabled(true);
      if (state.ActiveStep === 3 && state.Target.length === 0 && state.ProblemType !== "Clustering") setDisabled(true);
      if (state.ActiveStep === 4 && !state.Normalizer) setDisabled(true);
      if (state.ActiveStep === 8 && state.ValidMethod === "HoldOut" && (!state.TrainRate || state.TrainRate === 0)) setDisabled(true);
    },

    handleBack = () => {
      setState(prevState => ({ ...prevState, ActiveStep: prevState.ActiveStep - 1 }));
    },

    newHandleNext = () => {
      if (state.ActiveStep === 0)
        return addName(history, state, setState, handleNext, setAlert, setLoading);
      else if (state.ActiveStep === 1)
        return addProblemType(history, state, handleNext, setAlert, setLoading);
      else if (state.ActiveStep === 2)
        return handelNewData(history, state, setState, isExist, setAlert, setLoading, setDisableHeader, handleNext, file);
      else if (state.ActiveStep === 3)
        return addData(history, state, setState, handleNext, setAlert, setLoading);
      else if (state.ActiveStep === 4)
        return normalize(history, state, setState, handleNext, setAlert, setLoading);
      else if (state.ActiveStep === 5)
        return state.ProblemType === "Clustering" ? cluster(history, state, setState, handleNext, setAlert, setLoading) : handleNext();
      else if (state.ActiveStep === 6)
        return state.ProblemType === "Clustering" ? handleNext() : validate(history, state, setState, handleNext, setAlert, setLoading);
      else if (state.ActiveStep === 7)
        return state.ProblemType === "Clustering" ? handleNext() : features(history, state, setState, handleNext, setAlert, setLoading);
      else if (state.ActiveStep === 8)
        return state.ProblemType === "Clustering" ?
          validate(history, state, setState, handleNext, setAlert, setLoading) :
          features(history, state, setState, handleNext, setAlert, setLoading);
      else if (state.ActiveStep === 9)
        return features(history, state, setState, handleNext, setAlert, setLoading);
      else return handleNext();
    };

  return <Grid container className={classes.root}>

    <Hidden xsDown>
      <Grid item style={{ width: 'fit-content' }}>
        <Paper className={classes.paper}>
          <Stepper activeStep={state.ActiveStep} orientation="vertical" style={{ background: "#27293d" }} >
            {steps.map(label => (<Step style={{ height: state.ProblemType === "Clustering" ? 20 : 30 }} key={label}> <StepLabel> {label} </StepLabel> </Step>))}
          </Stepper>
        </Paper>
      </Grid>
    </Hidden>

    <Grid item xs={true} style={{ minWidth: 250 }}>
      {
        state.ActiveStep === steps.length ?
          <Paper className={classes.paper}>
            <p>Results</p>
            <FeatureSelectionResults
              handleBack={handleBack}
              setBuildLoading={setLoading}
            />
            {loading ? <Progress /> : null}
          </Paper>
          :
          <Paper className={classes.paper}>
            <div className={classes.instructions}>
              {/* {getStepContent(state.ActiveStep)} */}
              <StepContent
                state={state}
                setState={setState}
                disabled={disabled}
                setDisabled={setDisabled}
                stepIndex={state.ActiveStep}
                disableHeader={disableHeader}
                setFile={setFile}
                setExist={setExist}
              />
            </div>

            <Box className={classes.box}>
              <Button className={classes.button} variant="contained" color="primary" size='small' disabled={state.ActiveStep === 0} onClick={handleBack} > Back </Button>
              <Button className={classes.button} variant="contained" color="primary" size='small' onClick={newHandleNext} disabled={disabled}>{state.ActiveStep === steps.length - 1 ? 'Finish' : 'Next'}</Button>
            </Box>

            <Alert alert={alert} setAlert={setAlert} />

            {loading ? <Progress /> : null}

          </Paper>
      }
    </Grid>

  </Grid>

};

export default withContext(MutiStep);



