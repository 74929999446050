import React, { useState } from 'react';
import { withContext } from '../../../../../../../context';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import { IconButton, FormControl, RadioGroup, FormControlLabel, Dialog, GridList, GridListTile, GridListTileBar, ListItem, ListItemText } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'underscore';
import MatrixView from '../../Components/Matrix_view';
import { Row, Col } from "reactstrap";
import StorageIcon from '@material-ui/icons/Storage';
import BarChartIcon from '@material-ui/icons/BarChart';
import PcaChart from '../../Visualization/VisualizationGrpahs/PcaChart';
import NumHistoChart from '../../Visualization/VisualizationGrpahs/NumHistoChart';
import { live_options } from '../../../Controller';
import { saveImage, saveCsv } from "../../../../../../../Components/Exports/Exports";
import { LineChart , CheckBox  } from "../../../../../../../Components";


const useStyles = makeStyles(() => ({
	root: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	label: {
		fontSize: 12,
		fontFamily: 'sans-serif',
		fontStyle: 'normal',
	},

	gridListele: { border: '2px solid  #212121', marginBottom: 10 },

	dialogPaper: {
		minHeight: '95vh', maxHeight: '95vh', minWidth: '95vw', maxWidth: '95vw',
		background: 'linear-gradient(#1e1e2f,#1e1e24)'
	},
}));

const Results = ({ state, setState, input, setInput }) => {

	const classes = useStyles();

	const [open, setOpen] = useState("O1");
	const handleIconClick = (e) => setOpen(e.currentTarget.dataset.value);

	const [checked, setChecked] = useState("Absolute");
	const handleCheck = (e) => setChecked(e.target.value);

	const [open_chart, setOpenChart] = React.useState({ O1: false, O2: false });

	const handleOpen = (e) => setOpenChart({ ...open_chart, [e.currentTarget.dataset.value]: true });

	const handleClose = () => setOpenChart({ O1: false, O2: false });

	return <>
		<div className="content">
			<Row className={"justify-content-center"}>
				{open === "O2" ?
					<Col className="text-center text-md-left">
						<FormControl component="fieldset">
							<RadioGroup row value={checked} onChange={handleCheck}>
								<FormControlLabel classes={{ label: classes.label }} value="Absolute" control={<CheckBox />} label=" Absolute Values" />
								<FormControlLabel classes={{ label: classes.label }} value="Normalized" control={<CheckBox />} label="Normalized Values" />
							</RadioGroup>
						</FormControl>
					</Col> : null
				}
				<Col className="text-center text-md-right" >
					<IconButton color={open === "O1" ? "primary" : "default"} data-value="O1" onClick={handleIconClick} size='small'>
						<StorageIcon style={{ marginRight: 10, color: open.O1 ? "#4728f5" : null }} /></IconButton>
					{_.isEmpty(state.PCAResult1) ? null :
						<IconButton color={open === "O2" ? "primary" : "default"} data-value="O2" onClick={handleIconClick} size='small'>
							<BarChartIcon style={{ marginRight: 10, color: open.O2 ? "#4728f5" : null }} /></IconButton>
					}
				</Col>
			</Row>

			<Divider/>

			<Row style={{ marginTop: 10, height: 400 }}>
				<Col lg="7">
					{
						open === "O1" ?

							<MatrixView height={370} matrix={state.MembershipMatrix} name="Membership" />

							: checked === "Absolute" ?

								<MatrixView height={380} matrix={state.Data} name="PCA" />

								: checked === "Normalized" ?

									<MatrixView height={380} matrix={state.NormalizedNumData} name="Normalized PCA" />

									: null
					}
				</Col>
				<Col lg="4">
					<GridList cellHeight={180} cols={1} style={{ justifyContent: "center" }}>
						<GridListTile data-value="O1" className={classes.gridListele} key={1} onClick={(e) => handleOpen(e)}>
							{
								open === "O1" ?
								<LineChart
									name="Membership"
									RowNames={["-", ...state.RowNames]}
									matrix={state.MembershipMatrix.map((arr) => arr.slice(1))}
									Target={state.Target}
									live={true}
									ProblemType={state.ProblemType}
									GadNic={state.GadNic}
								/>
									:
									checked === "Absolute" ?
										<PcaChart
											PCAResult1={state.PCAResultOrg1}
											PCAResult2={state.PCAResultOrg2}
											PCAScore1={state.ScoreOrg1}
											PCAScore2={state.ScoreOrg1}
											RowNames={["-", ...state.RowNames]}
											Target={state.Target}
											ProblemType={state.ProblemType}
											MarkerNamesNum={state.MarkerNamesNum}
											setState={setState}
											live={true}
										/>
										:
										<PcaChart
											PCAResult1={state.PCAResult1}
											PCAResult2={state.PCAResult2}
											PCAScore1={state.Score1}
											PCAScore2={state.Score2}
											RowNames={["-", ...state.RowNames]}
											Target={state.Target}
											ProblemType={state.ProblemType}
											MarkerNamesNum={state.MarkerNamesNum}
											setState={setState}
											live={true}
										/>
							}

							<GridListTileBar title={open === "O1" ? "Membership Matrix" : "Principal Component Analysis"} style={{ textAlign: "center" }} />

						</GridListTile>

						{open !== "O1" ?
							<GridListTile data-value="O2" className={classes.gridListele} key={2} onClick={(e) => handleOpen(e)}>
								{
									checked === "Absolute" ?
										<NumHistoChart
											matrix={state.NumData}
											Target={state.Target}
											input={input}
											setInput={setInput}
											MarkerNamesNum={state.MarkerNamesNum}
											live={true}
											live_options={live_options} />
										:
										<NumHistoChart
											matrix={state.NormalizedNumData.slice(1)}
											Target={state.Target}
											input={input}
											setInput={setInput}
											MarkerNamesNum={state.MarkerNamesNum}
											live={true}
											live_options={live_options}
										/>
								}
								<GridListTileBar title="Classes Profile" style={{ textAlign: "center" }} />
							</GridListTile> : null
						}
					</GridList>
				</Col>
			</Row>

			<Dialog classes={{ paper: classes.dialogPaper }} open={open_chart.O1 || open_chart.O2} onClose={handleClose}>
				<ListItem dense onClick={handleClose}> <ListItemText primary='' /> <IconButton><CloseIcon /></IconButton></ListItem>
				<div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', textAlign: "right", height: '80vh', width: '80vw' }}>
					{open === "O1" ?
						open_chart.O1 ?

							<LineChart
								name="Membership"
								matrix={state.MembershipMatrix.map((arr) => arr.slice(1))}
								RowNames={["-", ...state.RowNames]}
								Target={state.Target}
								live={false}
								ProblemType={state.ProblemType}
								GadNic={state.GadNic}
							/>

							: open_chart.O2 ?
								<NumHistoChart
									matrix={state.NormalizedNumData.slice(1)}
									Target={state.Target}
									MarkerNamesNum={state.MarkerNamesNum}
									saveCsv={saveCsv}
									saveImage={saveImage}
								/>
								: null
						:
						checked === "Absolute" ?
							open_chart.O1 ?
								_.isEmpty(state.PCAResult1) ? null :
									<PcaChart
										PCAResult1={state.PCAResultOrg1}
										PCAResult2={state.PCAResultOrg2}
										PCAScore1={state.ScoreOrg1}
										PCAScore2={state.ScoreOrg1}
										RowNames={["-", ...state.RowNames]}
										Target={state.Target}
										ProblemType={state.ProblemType}
										MarkerNamesNum={state.MarkerNamesNum}
										setState={setState}
										saveCsv={saveCsv}
										saveImage={saveImage}
										live={false}
									/>

								:
								open_chart.O2 ?
									<NumHistoChart
										matrix={state.NumData}
										Target={state.Target}
										MarkerNamesNum={state.MarkerNamesNum}
										saveCsv={saveCsv}
										saveImage={saveImage}
									/>
									: null
							:
							checked === "Normalized" ?
								open_chart.O1 ?
									_.isEmpty(state.PCAResult1) ? null :
										<PcaChart
											PCAResult1={state.PCAResult1}
											PCAResult2={state.PCAResult2}
											PCAScore1={state.Score1}
											PCAScore2={state.Score2}
											RowNames={["-", ...state.RowNames]}
											Target={state.Target}
											ProblemType={state.ProblemType}
											MarkerNamesNum={state.MarkerNamesNum}
											setState={setState}
											saveCsv={saveCsv}
											saveImage={saveImage}
											live={false}
										/>
									:
									open_chart.O2 ?
										<NumHistoChart
											matrix={state.NormalizedNumData.slice(1)}
											Target={state.Target}
											MarkerNamesNum={state.MarkerNamesNum}
											saveCsv={saveCsv}
											saveImage={saveImage}
										/>
										: null
								: null
					}
				</div>
			</Dialog>

		</div>
	</>
}
export default withContext(Results);




