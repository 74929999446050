import React, { useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './Components';
import { getToken, getTokenExpiration, getUser, removeUserSession, getRole } from './Utils/common';
import { Main as MainLayout, Minimal as MinimalLayout, Show as ShowLayout } from './layouts';
import {
    UserList as UserListView,
    ModelList as ModelListView,
    Account as AccountView,
    Settings as SettingsView,
    SignIn as SignInView,
    SignUp as SignUpView,
    NotFound as NotFoundView,
    RoleList as RoleListView,
    Dashboard as DashboardView,
    ShowModel as ShowModelView
} from './views';

import helpers from "./api"; 

const path =  process.env.REACT_APP_PATH; 

const Routes = ({ history }) => {

    const tokenexpiration = getTokenExpiration();

    // if ( tokenexpiration && tokenexpiration < Math.round(Date.now() / 1000) ){
    //     localStorage.clear();
    //     history.push('/sign-in');
    // }

    useEffect(() => {
        const token = getToken();
        if (!token ) return;
        helpers.verifyToken().then((response) =>{})
          .catch((err) => { removeUserSession() });
    }, []);


    const
        user = getUser(),
        role = getRole();
       

    let connect = false;
    if (user) { connect = true; }

    return (
        <Switch>
            
            {
                connect ?
                    <Redirect exact from="/" to="/dashboard" /> :
                    <Redirect exact from="/" to="/sign-in" />
            }
            {
                connect && (user.admin || role.permission.readModel || role.permission.allModel) ?
                    <RouteWithLayout
                        component={DashboardView}
                        exact
                        layout={MainLayout}
                        path="/dashboard"
                        url={path}
                        history={history}
                    /> : null
            }

            <RouteWithLayout
                component={SignUpView}
                exact
                layout={MinimalLayout}
                path="/sign-up"
                url={path}
                history={history}
            />

            <RouteWithLayout
                component={SignInView}
                exact
                layout={MinimalLayout}
                path="/sign-in"
                url={path}
                history={history}
            />

           
            {
                connect && (user.admin || role.permission.readUser) ?
                <RouteWithLayout
                    component={UserListView}
                    exact
                    layout={MainLayout}
                    path="/users"
                    url={path}
                    history={history}
                /> : null
            }
            {
                connect ?
                <RouteWithLayout
                    component={ModelListView}
                    exact
                    layout={MainLayout}
                    path="/models"
                    url={path}
                    history={history}
                /> : null
            }
            {
                connect ?
                <RouteWithLayout
                    component={ShowModelView}
                    exact
                    layout={ShowLayout}
                    path='/show/:id'
                    url={path}
                    history={history}

                /> : null
            }
            {
                connect ?
                <RouteWithLayout
                    component={AccountView}
                    exact
                    layout={MainLayout}
                    path="/account"
                    history={history}
                    url={path}
                /> : null
            }

            {
                connect && (user.admin || role.permission.readUser) ?
                <RouteWithLayout
                    component={RoleListView}
                    exact
                    layout={MainLayout}
                    path="/roles"
                    url={path}
                    history={history}
                /> : null
            }

            {
                connect ?
                <RouteWithLayout
                    component={SettingsView}
                    exact
                    layout={MainLayout}
                    path="/settings"
                    history={history}
                    url={path}
                /> : null
            }

            {
                tokenexpiration &&  tokenexpiration > Math.round(Date.now() / 1000) ? null :  <Redirect to="/sign-in" />
            }

            <RouteWithLayout
                component={NotFoundView}
                exact
                layout={MinimalLayout} 
                path="/not-found"
                history={history}
            />

            <Redirect to="/not-found" />

        </Switch>
    );
};

export default Routes;
