import React from 'react';
import _ from 'underscore'
import { Bar } from 'react-chartjs-2';
import { AutoComplete } from "../../../../../../../Components";

const Chart = ({ matrix, Target, MarkerNamesNum, live, live_options, ExportAs }) => {

    const [input, setInput] = React.useState(_.first(MarkerNamesNum, 10));
    let options = _.without(MarkerNamesNum, ...input);
    const ref = React.useRef(null);
   
   


    const getHistoData = (j) => {
        let HistoData = [];
        for (var i = 0, len = matrix.length; i < len; i++)
            HistoData.push(_.object(['x', 'y'], [matrix[i][j], Target[i]]))
        return HistoData
    }

    const getBarData = () => {
        let dataset = [], labels = [];
        for (var i = 0; i < input.length; i++) {
            let data = getHistoData(i);
            let sumArr = [];
            let color = (`hsl(${(MarkerNamesNum.indexOf(input[i]) * 100)}, 75%, 50%)`)
            let groups = Object.entries(_.groupBy(data, 'y'));

            for (var j = 0; j < groups.length; j++) {
                sumArr.push(groups[j][1].reduce((s, f) => s + f.x, 0) / data.length);
                if (i === 0) labels.push(groups[j][0])
            }

            dataset.push({
                label: input[i],
                fill: false,
                backgroundColor: color,
                barThickness: live ? 10 : 20,
                data: sumArr
            })
        }
        const BarData = { labels: labels, datasets: dataset }
        return BarData;
    }

    const BarOptions = {
        hover: { mode: null },
        title: { display: true, text: 'Classes Profile', fontColor: '#9a9a9a' },
        legend: {
            display: true, position: 'right', labels: { fontColor: "#9a9a9a" },
            onClick: (e, legendItem) => setInput(input => _.without(input, legendItem.text))
        },
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                scaleLabel: { display: true, labelString: 'Classes', fontColor: '#9a9a9a', fontSize: 16, lineHeight: 2.5 },
                ticks: { fontColor: '#9a9a9a' }
            }],
            yAxes: [{
                scaleLabel: { display: true, labelString: 'Mean', fontColor: '#9a9a9a', fontSize: 16, lineHeight: 2.5 },
                ticks: { fontColor: '#9a9a9a' }
            }]
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem, data) => {
                    return " " + data.datasets[tooltipItem.datasetIndex].label +
                        " : " + tooltipItem.yLabel || '';
                },
                title: () => { return }
            }
        }
    }

    const getExcelData = () => {
        let dataset = [], labels = [], dataSum = [];
        for (let i = 0; i < input.length; i++) {
            let data = getHistoData(i);
            let sumArr = [];
            let groups = Object.entries(_.groupBy(data, 'y'));

            for (let j = 0; j < groups.length; j++) {
                sumArr.push(groups[j][1].reduce((s, f) => s + f.x, 0) / data.length);
                if (i === 0) labels.push(groups[j][0])
            }

            dataSum.push(sumArr);
        }

        let dataSum_T = _.zip.apply(_, dataSum);

        for (let i = 0, len = labels.length; i < len; i++)
            dataset.push(_.object(['target', ...input], [labels[i], ...dataSum_T[i]]))

        return dataset;
    }

    const handleChange = (ev, value) => {
        if (ev.target.value !== "" || ev.target.value !== null) {
            setInput(input => [...input, value]);
        }
    }

    return <>
        { live ? null : <div className="row justify-content-end mr-5">

            <div className="col-3">

            <AutoComplete options={options} handleChange={handleChange} title={"Select..."} />

                {/* <Select styles={customStyles}
                    options={options}
                    onChange={(value) => { if (value) setInput(input => [...input, value.value]) }}
                    theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: '#1e1e2f' } })}
                /> */}
            </div>

            <div className="col-1">
                {ExportAs("Numeric Histogram", getExcelData(), ref)}
            </div>
        </div>
        }
        <Bar ref={ref} data={getBarData()} options={live ? live_options("category") : BarOptions} />
    </>
}
export default React.memo(Chart);