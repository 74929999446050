import React from 'react';
import PcaChart from "../../../Dashboard/components/AddModel/Steps/Visualization/VisualizationGrpahs/PcaChart"
import {ExportAs} from "../../../../Components/Exports/Exports";
import Dialog from "@material-ui/core/Dialog";
import {makeStyles} from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";


const useStyles = makeStyles(theme => ({
  Dialog: { minHeight: '80vh', minWidth: '80vw', background: '#1e1e2f' },
}));


const Visualization = ({ Model , state , setState , handelClose , open }) => {

  const classes = useStyles();

  return (
      <Dialog classes={{ paper: classes.Dialog }} open={open} onClose={handelClose}>
        <DialogContent dividers style={{background: '#27293d'}}>
            <div style={{ background: '#27293d' , height: '70vh' }}>

              <PcaChart
                  PCAResult1={state.PCAResult1}
                  PCAResult2={state.PCAResult2}
                  PCAScore1={state.PCAScore0}
                  PCAScore2={state.PCAScore1}
                  RowNames={state.rowNames}
                  Target={state.target}
                  ProblemType={state.problemType}
                  MarkerNamesNum={state.markerNamesNum}
                  setState={setState}
                  ExportAs={ExportAs}
                  live={false} />
            </div>
        </DialogContent>
      </Dialog>
  );
}

export default Visualization;