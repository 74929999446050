
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Dialog, DialogTitle, DialogContent, TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogActions from "@material-ui/core/DialogActions";
import { CardContent, Checkbox, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'underscore';
import helpers from '../../api';

const Share = ({ history, open, handleClose, path, id, setAlert }) => {

    const
        [ShareUser, setShareUser] = useState(false),
        [users, setUser] = useState([]),
        [sending, setSending] = useState(false),
        [ApiModels, setApiModels] = useState([]),
        [values, setValues] = useState({ supp: false, read: false, update: false }),
        [email, setEmail] = useState(''),
        [options, setOptions] = useState([]),
        loadingUsers = ShareUser && options.length === 0;

    const sharableUsers = (active) => {
        setOptions([]);
        helpers.sharableUsers(id).then((response) => {
            if (active) setOptions(response.data);
        })
            .catch((err) => {
                err.response.status === 401 ?
                    helpers.tokenExpirationAlert(history) :
                    setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
            });
    };

    const usersSharing = () => {

        let body = { users: users, permissions: values, modelID: id };

        helpers.usersSharing(body).then((response) => {
            setAlert({ alert: true, severity: "success", message: "Model shared successfully" });
        })
            .catch((err) => {
                err.response.status === 401 ?
                    helpers.tokenExpirationAlert(history) :
                    setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
            });
    };

    const emailSharing = () => {

        let body = { id: id, email: email };
        setSending(true);

        helpers.emailSharing(body).then((response) => {
            setSending(false); setAlert({ alert: true, severity: "success", message: "Email sent successfully" });
        })
            .catch((err) => {
                err.response.status === 401 ?
                    helpers.tokenExpirationAlert(history) :
                    setSending(false); setAlert({ alert: true, severity: "error", message: "Email was not sent" });
                console.error(err)
            });
    };

    useEffect(() => {
        helpers.apiModels().then((response) => {
            setApiModels(response.data);
        })
            .catch((err) => {
                err.response.status === 401 ?
                    helpers.tokenExpirationAlert(history) :
                    setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
            });
    }, []);

    useEffect(() => { if (!ShareUser) setOptions([]) }, [ShareUser]);

    useEffect(() => {
        let active = true;
        if (loadingUsers) { sharableUsers(active); return () => { active = false }; }
        else return undefined;
    }, [loadingUsers, path]);


    const handleCheckBox = (e, val) => {
        setValues(prevState => ({ ...prevState, [e.target.name]: val }))
    }

    return <>

        <Dialog open={open} onClose={() => { setAlert({ alert: false, severity: "info", message: "" }); handleClose(); }}>

            <DialogTitle style={{ background: '#27293d' }} disableTypography >Share your models with other users </DialogTitle>

            <DialogContent style={{ background: '#27293d' }} dividers>

                <DialogTitle style={{ background: '#27293d' }} disableTypography >Selecet a user </DialogTitle>

                <Autocomplete
                    multiple
                    id="asynchronous-demo"
                    onChange={(ev, value) => {
                        // dont fire API if the user delete or not entered anything
                        if (ev.target.value !== "" || ev.target.value !== null) {
                            setUser(value);
                        }
                    }}
                    open={ShareUser}
                    onOpen={() => { setShareUser(true); }}
                    onClose={() => { setShareUser(false); }}
                    getOptionSelected={(option, value) => option.username === value.username}
                    getOptionLabel={(option) => option.username}
                    options={options}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Users"
                            style={{ width: 300 }}
                            variant="outlined"
                            InputProps={{ ...params.InputProps }}
                        />
                    )}
                />
                <CardContent>
                    <Grid container spacing={6} wrap="wrap">
                        <Grid item md={4} sm={6} xs={12}>
                            <FormControlLabel
                                label="Read"
                                control={
                                    <Checkbox
                                        name="read"
                                        color="primary"
                                        onChange={handleCheckBox}
                                    />}
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="update"
                                        color="primary"
                                        onChange={handleCheckBox}
                                    />
                                }
                                label="Update"
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12} >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="supp"
                                        color="primary"
                                        onChange={handleCheckBox}
                                    />
                                }

                                label="Delete"
                            />
                        </Grid>
                    </Grid>
                </CardContent>

                <DialogActions style={{ background: '#27293d' }}>
                    <Button disabled={_.isEmpty(users)} size='small' variant="contained" color="primary" onClick={usersSharing} >Share</Button>
                </DialogActions>

            </DialogContent>


            {ApiModels.includes(id) ?
                <>
                    <DialogTitle style={{ background: '#27293d' }} disableTypography >Share your model API with external user </DialogTitle>

                    <DialogContent style={{ background: '#27293d' }} dividers>
                        <DialogTitle style={{ background: '#27293d' }} disableTypography > Enter user email  </DialogTitle>

                        <TextField
                            label="Email"
                            style={{ width: 300 }}
                            variant="outlined"
                            onChange={e => setEmail(e.target.value)}
                        />

                        <DialogActions style={{ background: '#27293d' }}>
                            <Button disabled={!email || sending} size='small' variant="contained" color="primary" onClick={emailSharing} > {sending ? "Sending ..." : "Share"}</Button>
                        </DialogActions>

                    </DialogContent>

                </>
                :
                null
            }
        </Dialog>
    </>


}

export default Share;
