import React, { useState } from 'react';
import { Alert, CustomLineChart, Progress } from "../../../../Components";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import { DialogActions, DialogTitle } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import _ from "underscore";

import helpers from '../../../../api';


const useStyles = makeStyles(theme => ({
    Dialog: { minHeight: '95vh', minWidth: '80vw', background: '#1e1e2f' },
}));


const Build = ({ history , Model, state, setState, handelClose, open, fetchData, path, token, step, disabled, setDisabled }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ alert: false, severity: "", message: "" });

    const BuildFnc = async () => {

        setLoading(true);
        let selectedHeader = _.intersection(state.markerNames, _.first(state.rankedMarkers, state.NbrOfVariables))
        const body = { selectedHeader: selectedHeader, numOptimFeat: state.NbrOfVariables };


        helpers.build(body, Model.id).then((response) => {
            fetchData(Model.id);
            setLoading(false);
            handelClose();
        })
        .catch((err) => {
            console.log(err);
            err.response.status === 401 ?
                helpers.tokenExpirationAlert(history) :
                setAlert({ alert: true, severity: "error", message: err || 'Error occured , please try again' });
                setLoading(false);
        });


        // try {
        //     setLoading(true);
        //     let selectedHeader = _.intersection(state.markerNames, _.first(state.rankedMarkers, state.NbrOfVariables))
        //     const body = { selectedHeader: selectedHeader, numOptimFeat: state.NbrOfVariables };


        //     const res = await fetch(`${path}/build/${Model.id}`, {
        //         method: 'POST', mode: 'cors',
        //         body: JSON.stringify(body),
        //         headers: { 'Content-Type': 'application/json', "xauthtoken": token, 'Authorization': 'Bearer ' + token },
        //     });

        //     const json = await res.json();

        //     if (res.status === 200) {
        //         await fetchData(Model.id);
        //         await setLoading(false);
        //         await handelClose();
        //     }
        //     else { setAlert({ alert: true, severity: "error", message: json.message || 'Error occured , please try again' }); setLoading(false); }
        // } catch (err) { console.log(err); setAlert({ alert: true, severity: "error", message: 'Build function error' }); setLoading(false); return; }
    };

    return (
        <Dialog classes={{ paper: classes.Dialog }} open={open}>
            <DialogTitle disableTypography style={{ background: '#27293d' }}>
                Select the Number of Features
            </DialogTitle>
            <DialogContent dividers style={{ background: '#27293d' }}>
                <div style={{ background: '#27293d' }}>
                    <CustomLineChart state={state} setState={setState} live={false} />
                </div>
            </DialogContent>
            <DialogActions style={{ background: '#27293d' }}>
                <Button disabled={disabled} onClick={() => { handelClose(); setDisabled(false) }} color="primary" variant={"contained"}> cancel </Button>
                <Button disabled={step === 0 && state.NbrOfVariables === Model.numOptimFeat} onClick={BuildFnc} color="primary" variant={"contained"}> Build </Button>
            </DialogActions>
            {loading ? <Progress /> : null}
            <Alert alert={alert} setAlert={setAlert} />
        </Dialog>
    );
}

export default Build;