import React from 'react';
import { withContext } from '../../../../../../context';
import { ListItemText } from '@material-ui/core';
import { Card, CardTitle, CardHeader, CardBody, Row, Col } from "reactstrap";
import { InfoSquare } from 'react-bootstrap-icons';
import {SelectForm , InputForm } from '../../../../../../Components' ;

const ConnectiveOptions = [
    {
        label: "MINMAX",
        value: "MINMAX"
    },
    {
        label: "PROBABILISTIC",
        value: "PROBABILISTIC"
    },
];

const LamdaFunctionOptions = [
    {
        label: "FUZZY BINOMIAL",
        value: "FUZZY_BINOMIAL"
    },
    {
        label: "FUZZY CENTERED BINOMIAL",
        value: "FUZZY_CENTERED_BINOMIAL"
    },
    {
        label: "NORMAL GAUSSIAN",
        value: "NORMAL_GAUSSIAN"
    },
];

const Clustering = ({ state , setState }) => {

    return <>
        <Row> <Col> <ListItemText primary='Select your parameters' /> </Col></Row>
        <Row style={{ marginTop: 10 }}>
            <Col lg="4">
                <Card style={{ background: "#27293d", border: "1px solid #4728f5 ", paddingBottom: 4 }} >
                    <CardHeader>
                        <CardTitle tag="h6">
                            <InfoSquare color="royalblue" size={20} style={{ marginRight: 10 }} />
                            Connective
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <SelectForm setState = {setState} stateName={state} stateParam = {"Connective"} options={ConnectiveOptions}/>
                    </CardBody>
                </Card>
            </Col>
            <Col lg="4">
                <Card style={{ background: "#27293d", border: "1px solid #4728f5 ", paddingBottom: 4 }} >
                    <CardHeader>
                        <CardTitle tag="h6">
                            <InfoSquare color="royalblue" size={20} style={{ marginRight: 10 }} />
                            Threshold
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <SelectForm setState = {setState} stateName={state} stateParam = {"LamdaFunction"} options={LamdaFunctionOptions}/>
                    </CardBody>
                </Card>
            </Col>
            <Col lg="4">
                <Card style={{ background: "#27293d", border: "1px solid #4728f5 ", paddingBottom: 4 }} >
                    <CardHeader>
                        <CardTitle tag="h6">
                            <InfoSquare color="royalblue" size={20} style={{ marginRight: 10 }} />
                            Exigency Index
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <InputForm setState={setState} stateName={state} stateParam={"ExegencyLevel"} title={"Must be number between 0 and 1"} type={"number"} min = {0} max = {1} step = {0.1}  />
                    </CardBody>
                </Card>
            </Col>
        </Row>

    </>
}
export default withContext(Clustering)