import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { DropzoneArea } from 'material-ui-dropzone'
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  LinearProgress
} from '@material-ui/core';
import { getToken, getUser, setUserSession, getRole } from '../../../../Utils/common';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import axios from "axios";
import { Progress } from "reactstrap";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  dropzoneClass: {
    background: '#212121',
    fontSize: 8,
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AccountProfile = props => {
  const { className, url, ...rest } = props;
  const classes = useStyles();
  const [files, setFiles] = React.useState([]);
  const [load, setload] = React.useState(0);

  const user = getUser()

  const postfiles = () => {
    const data = new FormData()
    for (var x = 0; x < files.length; x++) data.append('file', files[x])

    axios.post(`${url}/updatePhoto/` + getUser()._id, data, {
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() },
      onUploadProgress(progressEvent) {
        setload(progressEvent.loaded / progressEvent.total * 100);
      }
    }).then(res => { // then print response status

      const urlimage = user._id + '-' + files[0].name
      setimagepath(urlimage);
      user.image = urlimage
      handleClose();
      setUserSession(getToken(), user, getRole())
      setnot(true);
    })
  }

  const [open, setOpen] = React.useState(false);
  const [not, setnot] = React.useState(false);
  const [imagepath, setimagepath] = React.useState(user.image);
  const handleChange = (files) => setFiles(files);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);


  const handleCloseNot = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setnot(false);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Snackbar open={not} autoHideDuration={6000} onClose={handleCloseNot}>
        <Alert onClose={handleCloseNot} severity="success">
          info updated with success
        </Alert>
      </Snackbar>
      <CardContent style = {{minHeight : 265}}>
        <div className={classes.details}>
          <div>
            <Typography
              gutterBottom
              variant="body1"
            >
              {user.name}

            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              {user.email}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              {moment().format('hh:mm A')}
            </Typography>
          </div>


          <Avatar
            className={classes.avatar}
            src={url + '/' + imagepath}
          >

          </Avatar>
        </div>
        <div className={classes.progress}>
          <Typography variant="body1"></Typography>
          <LinearProgress
            value={100}
            variant="determinate"
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions>
          <Button 
           color="primary"
           variant="contained"
            component="label"
            onClick={handleOpen}
          >
            Update picture
          </Button>
      </CardActions>
     
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle disableTypography={true}> </DialogTitle>
        <DialogContent dividers >
          <DropzoneArea onChange={handleChange} dropzoneClass={classes.dropzoneClass} />
          <div className="form-group">
            <Progress max="100" color="success"
              value={load}>{Math.round(load, 2)}%
            </Progress>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary"> Cancel </Button>
          <Button onClick={postfiles} color="primary"> Upload </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string
};

export default AccountProfile;
