import React, { useState } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { withContext } from '../../../../../../context';
import { ListItemText, ListItem, Checkbox } from "@material-ui/core";
import _ from 'underscore';
import Pagination from "./Pagination";
import { makeStyles } from '@material-ui/core/styles';
import { AutoComplete } from '../../../../../../Components';
import { Row, Col } from "reactstrap";


const useStyles = makeStyles((theme) => ({
    headerBar: {
        display: "flex",
        justifyContent: "space-between",
        fontSize: "20px",
    }
}));


const DataTable = ({ state, setState, matrix }) => {

    const classes = useStyles();
    const [page, setPage] = useState(1);
    const nbrOfPage = matrix[0].length > 10 ? Math.ceil(matrix[0].length / 10) : 1;

    const handleChange = (ev, value) => {
        if (ev.target.value !== "" || ev.target.value !== null) {
            setPage(matrix[0].indexOf(value) < 10 ? 1 :
                (matrix[0].indexOf(value) % 10) === 0 ?
                    (matrix[0].indexOf(value) / 10 + 1) :
                    (Math.ceil(matrix[0].indexOf(value) / 10)))
        }
    }

    const handleCheck = (event, idx) => {
        if (state.Filtered.includes(idx)) {
            state.Filtered = state.Filtered.filter(item => item !== idx);
        } else {
            state.Filtered = [...state.Filtered, idx];
        }
    };

    const columns = React.useMemo(() => {
        let columns = [];
        var len = page === nbrOfPage ? matrix[0].length : page * 10;
        for (let i = (page * 10 - 10); i < len; i++) {
            columns.push({
                Header: props =>
                    state.ProblemType !== "Clustering" ?
                        <div style={{ textAlign: "left" }}> <ListItem style={{ textAlign: "left" }} dense>
                            {i !== 0 && i !== state.Index ?
                                <Checkbox
                                    key={i}
                                    defaultChecked={!state.Filtered || !state.Filtered.includes(i)}
                                    // checked={!state.Filtered || !state.Filtered.includes(i)}
                                    onChange={(e) => handleCheck(e, i)}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                /> : null
                            }
                            <ListItemText onClick={() => getSelectedCol(i)} primary={matrix[0][i].toString()} />
                        </ListItem>
                        </div>
                        :
                        <div style={{ textAlign: "left" }}>
                            <ListItem dense >
                                {i !== 0 && i !== state.Index ?
                                    <Checkbox
                                        defaultChecked={!state.Filtered || !state.Filtered.includes(i)}
                                        key={i}
                                        // checked={!state.Filtered || !state.Filtered.includes(i)}
                                        onChange={(e) => handleCheck(e, i)}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    /> : null
                                }
                                <ListItemText primary={matrix[0][i].toString()} /> </ListItem>
                        </div>,
                id: i,
                accessor: matrix[0][i].toString(),
                style: { textAlign: 'left' },

                getProps: () => ({ style: { background: i === state.Index ? '#808080' : '' } }),
                Cell: props => <ListItem dense><ListItemText primary={props.value} /></ListItem>
            })
        }
        return columns;
    }, [matrix, nbrOfPage, page, state.ProblemType, state.Index, state.Filtered]);


    const rows = React.useMemo(() => {
        let Data = [];
        let RowLen = 10;
        for (let i = 1; i < RowLen; i++) Data.push(_.object(matrix[0], matrix[i]))
        return Data
    }, [matrix]);

    const getSelectedCol = (id) => {
        let data_T = _.zip.apply(_, matrix);
        let column = data_T[id].slice(1).join().split(',');
        setState(prevState => ({ ...prevState, Index: id, Target: column }));
    };

    return <>
        {
            state.ProblemType !== "Clustering" ?
                <div className={classes.headerBar}>
                    <div> <ListItemText style={{ marginBottom: 10 }} primary='Select your target variable' /></div>
                    <div> <AutoComplete style={{ marginBottom: 10 }} options={matrix[0]} handleChange={handleChange} title = {"Search Column"} /></div>
                </div>
                :
                <ListItemText style={{ marginBottom: 10 }} primary='Dataset Table' />
        }

        <Row style={{ marginTop: 10 }}>
            <Col>
                <ReactTable
                    minRows={0}
                    data={rows}
                    columns={columns}
                    defaultPageSize={10}
                    style={{ marginTop: 10, background: '#1e1e2f', height: "50vh" }}
                    sortable={false}
                    showPagination={false}
                />
            </Col>
        </Row>


        {
            matrix[0].length > 10 ?
                <Pagination
                    page={page}
                    setPage={setPage}
                    nbrOfPage={nbrOfPage}
                /> : null
        }
    </>
};

export default withContext(React.memo(DataTable));
