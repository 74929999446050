import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import fetch from 'cross-fetch';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { getToken } from "../../../Utils/common";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import helpers from '../../../api';

const Generate = ({ history, path, open, handleClose, handlesend, Model, setAlert }) => {

    const [models, setModels] = useState([]);
    const [process, setProcess] = useState(false);
    const [bloc, setbloc] = useState(false);
    const [op, setOp] = useState(false);
    const [options, setOptions] = useState([]);

    const loading = op && options.length === 0;

    useEffect(() => { if (!op) setOptions([]) }, [op]);

    useEffect(() => {

        let active = true;

        if (!loading) { return undefined; }

        helpers.swaggerModels().then((response) => {
            if (active) setModels(response.data);
        })
            .catch((err) => {
                console.log(err);
                err.response.status === 401 ?
                    helpers.tokenExpirationAlert(history) :
                    setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
            });
        return () => { active = false };
    }, [op]);


    const handleDownload = () => {

        //  helpers.apiDownload(Model.id).then((response) => {
        //     console.log(response)
        //     const downloadUrl = URL.createObjectURL(response.data);
        //     let a = document.createElement("a");
        //     a.href = downloadUrl;
        //     a.download = Model.id;
        //     document.body.appendChild(a);
        //     a.click();
        // })

        fetch(`${path}/download/${Model.id}`, {
            method: 'GET', mode: 'cors',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
        }).then(res => {
            console.log(res._bodyBlob);
            const downloadUrl = URL.createObjectURL(res._bodyBlob);
            let a = document.createElement("a");
            a.href = downloadUrl;
            a.download = Model.id;
            document.body.appendChild(a);
            a.click();
        })
            .catch(err => {
                setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
            })

    };

    const addSwagger = async (id) => {
        setProcess(true);
            const body = { id: id }
            helpers.addSwagger(body).then(() => {
                setbloc(true);
                setAlert({ alert: true, message: 'API added successfully', severity: "success" });
                setProcess(false);

            })
                .catch((err) => {
                    console.log(err);
                    err.response.status === 401 ?
                        helpers.tokenExpirationAlert(history) :
                        setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
                        setProcess(false);

                });

    };

    const updateSwagger = async (id, exName, exId) => {

        const body = { id: id, exName: exName, exId: exId };

        helpers.updateSwagger(body).then(() => {
            setbloc(true);
            setAlert({ alert: true, message: 'API updated successfully', severity: "success" });
        })
            .catch((err) => {
                console.log(err);
                err.response.status === 401 ?
                    helpers.tokenExpirationAlert(history) :
                    setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
            });
    };

    return <Dialog open={open} onClose={handleClose}>

        <DialogTitle style={{ background: '#27293d' }} > API Generation </DialogTitle>

        <DialogContent dividers style={{ background: '#27293d' }}>
            <div>
                <h6> Selecet your action to generte an API </h6>
            </div>

            <DialogContent dividers style={{ background: '#27293d' }}>

                <div className="row justify-content-between">
                    <div style={{ marginTop: 8 }} className="col-7">
                        <span style={{ fontSize: 16 }}> Add a new API </span>
                    </div>
                    <div className="col-3">
                        <Button variant='contained' color="primary" onClick={() => { addSwagger(Model.id); }} style={{ float: 'right' }}>{process ? "Processing..." : "Add"} </Button>
                    </div>
                </div>

            </DialogContent>

            <DialogContent dividers style={{ background: '#27293d' }}>

                <div>
                    <span style={{ flex: 1, fontSize: 16 }}> Or update an existing API </span>
                    <Autocomplete
                        id="asynchronous-demo"
                        onChange={(ev, value) => {
                            // dont fire API if the user delete or not entered anything
                            if (ev.target.value !== "" || ev.target.value !== null) {
                                updateSwagger(Model.id, value.name, value._id);
                            }
                        }}
                        open={op}
                        onOpen={() => { setOp(true); }}
                        onClose={() => { setOp(false); }}
                        getOptionSelected={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name}
                        options={models}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin="dense"
                                label="Select"
                                style={{ width: 200 }}
                                InputProps={{
                                    ...params.InputProps,
                                }}
                            />
                        )}
                    />
                </div>

            </DialogContent>

        </DialogContent>

        {bloc ? <DialogActions style={{ background: '#27293d' }} >
            <Button onClick={() => { handlesend(); handleClose() }} > Explore </Button>
            <Button onClick={() => { handleDownload(); handleClose() }} > Download</Button>
                </DialogActions> : null
        }

    </Dialog>

}
export default Generate;
