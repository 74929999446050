import React  from 'react';
import _ from 'underscore';
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./table_style.css";
import Pagination from "./pagination";
import { ListItem , ListItemText ,IconButton  } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { saveCsv } from '../../../../../../Components/Exports/Exports'


const Matrix_view = ({ matrix,  height , name}) => {

  const columns = (matrix[0])
    .map((item, index) => ({ 
        Header: props => <ListItem dense ><ListItemText primary={item.toString()} /> </ListItem>,
        id: `${index}`, 
        accessor: `${item.toString()}`, 
        style: { textAlign: 'left' } ,
        maxWidth: index === 0 ? 100 : 200,
        Cell: props => <ListItem dense style={{ margin: 5 , padding : 5  }}><ListItemText primary={props.value} /></ListItem> 
    }));

    const rows = (matrix.slice(1).map( (row)  =>  _.object(matrix[0], row) ));

  
  
  return   <div style={{ background: '#1e1e2f', border: '2px solid  #212121'  }}>
                <div className={"text-right"}> 
                    <IconButton  onClick={ () => {  saveCsv( name , matrix )}}>  
                    <SaveAltIcon fontSize = 'small'/>  
                    </IconButton>
                </div>
                <ReactTable
                    PaginationComponent={Pagination}
                    minRows={0}
                    data={rows}
                    columns={columns}
                    defaultPageSize={20}
                    style={{padding : 5 , background: '#1e1e2f' , height : height }}
                    sortable={false}
                    showPagination={rows.length  > 20 ? true : false  }
                />
            </div>
  
}


export default (Matrix_view)

