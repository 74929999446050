import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar , Typography ,IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  }
}));

const Topbar = props => {
  const { className,history , id  , setDeleted_id, setOpenDelete, ...rest } = props;

  const classes = useStyles();
  const handleDelete = (id_model) => { setOpenDelete(true); setDeleted_id(id_model) }


  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar variant = 'dense'>
      <Typography style={{ flex: 1 }} variant="h6" noWrap  > Adding a Model... </Typography>
          
            <IconButton onClick={() => {id === '000000000000000000000000'? history.push('/dashboard') : 
                handleDelete(id)
            }} >
                <CloseIcon/> </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
