import React from 'react';
import _ from 'underscore';
import { Chart, Line } from 'react-chartjs-2';
import Select from 'react-select';
import DownsamplePlugin from 'chartjs-plugin-downsample';
import { customStyles, getOptions as SelectOptions } from "../../../Controller";
import { ExportAs } from "../../../../../../../Components/Exports/Exports";

import { AutoComplete } from "../../../../../../../Components";

const ObjectData = (matrix, idx1, RowNames) => {
    let LineData = [];
    for (let i = 1, len = matrix.length; i < len; ++i)
        LineData.push({ x: i, y: matrix[i][idx1], 'r': RowNames[i - 1] });
    return LineData;
};

let downsample = {
    enabled: true,
    threshold: 1000,
    auto: false,
    onInit: true,
    preferOriginalData: true,
    restoreOriginalData: false,
};

let gridLines = {
    drawBorder: false,
    color: "rgba(29,140,248,0.1)"
};

const LineChart = ({ matrix, live, RowNames }) => {

    let tooltips = {
        enabled: true,
        bodySpacing: 4,
        xPadding: 12,
        intersect: 0,
        callbacks: {
            label: (tooltipItem, data) => {
                return ' ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].r + ' : ' + tooltipItem.yLabel || '';
            },
            title: (tooltipItem, data) => { return data.datasets[tooltipItem[0].datasetIndex].label || ''; }
        },
    };

    const liveOptions = {
        downsample: {
            enabled: true,
            threshold: (400),
            auto: false,
            onInit: true,
            preferOriginalData: true,
            restoreOriginalData: false,
        },
        hover: { mode: null },
        title: { display: false },
        scales: {
            xAxes: [{ scaleLabel: { display: false }, type: "linear", minRotation: 45, ticks: { beginAtZero: true, max: matrix.length } }],
            yAxes: [{ scaleLabel: { display: false } }]
        },
        legend: { display: false },
        tooltips: { enabled: false },
        animation: { duration: 0 },
        responsiveAnimationDuration: 0,
        line: { tension: 0 }
    };

    const MarkerNames = matrix[0];
    const ref = React.useRef(null);

    Chart.plugins.register(DownsamplePlugin);

    const [input, setInput] = React.useState(_.first(MarkerNames, 5));
    let options = _.without(MarkerNames, ...input);
    let DataSet = [];
    let ChartOptions = {};
    let ChartData = [];
    let color = [];

    for (let i = 0, len = input.length; i < len; ++i) {
        let data = ObjectData(matrix, MarkerNames.indexOf(input[i]), RowNames);
        color = (`hsl(${(MarkerNames.indexOf(input[i]) * 50)}, 75%, 50%)`);
        DataSet.push({
            label: input[i],
            fill: false,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 1,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: color,
            pointHoverBackgroundColor: "white",
            pointHoverRadius: 2,
            pointHoverBorderWidth: 5,
            pointRadius: 2,
            showLine: true,
            data: data
        });
        ChartData[i] = data;
    };

    ChartOptions = {
        downsample: downsample,
        maintainAspectRatio: false,
        tooltips: tooltips,
        title: {
            display: true,
            text: 'Membership Profile',
            fontColor: '#9a9a9a'
        },
        legend: {
            display: true, position: 'right', labels: { fontColor: '#9a9a9a' },
            onClick: (e, legendItem) => { setInput(input => _.without(input, legendItem.text)) }
        },
        scales: {
            xAxes: [{
                type: "linear",
                scaleLabel: {
                    display: true,
                    labelString: "Individuals Number",
                    fontColor: '#9a9a9a',
                    fontSize: 16,
                    lineHeight: 2.5
                },
                ticks: {
                    padding: 20,
                    fontColor: "#9a9a9a",
                    autoSkip: true,
                    minRotation: 45,
                    max: matrix.length
                },
                gridLines: gridLines,
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Membership Degree',
                    fontColor: '#9a9a9a',
                    fontSize: 16,
                    ineHeight: 2.5,
                    beginAtZero: true,
                },
                ticks: {
                    fontColor: '#9a9a9a',
                    padding: 20
                },
                gridLines: gridLines,
            }]
        },
    };

    let CanvasChart = {
        data: canvas => { return { datasets: DataSet } },
        options: live ? liveOptions : ChartOptions
    };

    const handleChange = (ev, value) => {
        if (ev.target.value !== "" || ev.target.value !== null) {
            setInput(input => [...input, value]);
        }
    }

    return <>
        {
            (live) ? null :
                <div className={"row justify-content-end mr-5"}>
                    <div className="col-3">
                        <AutoComplete options={options} handleChange={handleChange} title={"Select..."} />
                    </div>
                    <div className={"col-1"}>
                        {ExportAs("Feature Profile", matrix, ref)}
                    </div>
                </div>
        }

        <Line ref={ref} data={CanvasChart.data} options={live ? liveOptions : CanvasChart.options} /> </>
}

export default React.memo(LineChart);
