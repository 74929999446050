// return the user data from the session storage
export const getUser = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
}
export const getRole = () => {
    const roleStr = localStorage.getItem('role');
    if (roleStr) return JSON.parse(roleStr);
    else return null;
}
// return the token from the session storage
export const getToken = () => {
    return localStorage.getItem('token') || null;
}

export const getTokenExpiration = () => {
    return localStorage.getItem('tokenexpiration') || null;
}

export const getNotifications = () => {
    const notifications = localStorage.getItem('notifications');
    if (notifications) return JSON.parse(notifications);
    else return null;
}

export const getSetting = () => {
    const setting = localStorage.getItem('setting');
    if (setting) return JSON.parse(setting);
    else return null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('notifications');
    localStorage.removeItem('tokenexpiration');

}

// set the token and user from the session storage
export const setUserSession = (token,user,role,tokenexpiration) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('role',JSON.stringify(role));
    localStorage.setItem('tokenexpiration',JSON.stringify(tokenexpiration));
}

export const setNotifications = (notifications) => {
    localStorage.setItem('notifications', JSON.stringify(notifications));
}

export const setSetting = (setting) => {
    localStorage.setItem('setting', JSON.stringify(setting));
}