import React from 'react';
import { ListItemText } from '@material-ui/core';
import { withContext } from '../../../../../../context'
import { Card, CardTitle, CardHeader, CardBody, Row, Col } from "reactstrap";
import { InfoSquare } from 'react-bootstrap-icons';
import { SelectForm, InputForm } from "../../../../../../Components";
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
    normal : { } ,
    disabledbutton: {
        pointerEvents: 'none',
        opacity: 0.4
    }
}));

const FeatureSelection = ({ state, setState }) => {

    const classes = useStyles();
    const membershipOptions = [
        {
            label: "Gaussian",
            value: "gauss"
        },
        {
            label: "Binomial",
            value: "f_lamda"
        },
    ];

    return <>
        <Row> <Col> <ListItemText primary='Select your parameters' /> </Col></Row>
        <Row style={{ marginTop: 10 }}>

            <Col lg="4">
                <Card style={{ background: "#27293d", border: "1px solid #4728f5 ", paddingBottom: 4 }} >
                    <CardHeader>
                        <CardTitle tag="h6">
                            <InfoSquare color="royalblue" size={20} style={{ marginRight: 10 }} />
                                Membership Function
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <SelectForm setState = {setState} stateName={state} stateParam = {"MembershipFS"} options={membershipOptions}/>
                    </CardBody>
                </Card>
            </Col>

            <Col className = { state.N == state.MarkerNames.length ? classes.normal : classes.disabledbutton  } lg="4">
                <Card style={{ background: "#27293d", border: "1px solid #4728f5 ", paddingBottom: 4 }} >
                    <CardHeader>
                        <CardTitle tag="h6">
                            <InfoSquare color="royalblue" size={20} style={{ marginRight: 10 }} />
                            Threshold
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <InputForm setState={setState} stateName={state} stateParam={"Threshold"} title={"Must be number between 0 and 1"} type={"number"} min = {0} max = {1} step = {0.1}  />
                    </CardBody>
                </Card>
            </Col>

            <Col className = { state.Threshold === 0 ? classes.normal : classes.disabledbutton  } lg="4" >
                <Card style={{ background: "#27293d", border: "1px solid #4728f5 ", paddingBottom: 4 }} >
                    <CardHeader>
                        <CardTitle tag="h6">
                            <InfoSquare color="royalblue" size={20} style={{ marginRight: 10 }} />
                            Number of Features
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <InputForm setState={setState} stateName={state} stateParam={"N"} title={`Must be number between 0 and ${state.MarkerNames.length}`} type={"number"} min = {0} max = {state.MarkerNames.length} step = {1}/>
                    </CardBody>
                </Card>
            </Col>

        </Row>
    </>

}
export default withContext(FeatureSelection)