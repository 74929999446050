import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import fetch from 'cross-fetch';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { ExcelRenderer } from 'react-excel-renderer';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Paper } from '@material-ui/core';
import { Progress, Alert } from '../../../Components';
import { getToken } from "../../../Utils/common";

import helpers from '../../../api';


const useStyles = makeStyles(() => ({
    inputFile1: {
        fontSize: 30,
        height: 150,
        width: 400,
        position: "absolute",
        opacity: 0
    },
    dropzone: {
        borderRadius: 0,
        padding: 10,
        width: 400,
        height: 150,
        borderColor: '#FFFAFA',
        background: "#27293d",
        margin: 10,
        textAlign: 'center',
    },
}));

const Prediction = ({history, open, handleClose, Data, setData, Model, path, setOutClassPr, setEnablePr, setMadXpPr, setType, alert, setAlert }) => {

    const classes = useStyles();
    const token = getToken();
    const [loading, setLoading] = useState(false);
    const [disable, setdisable] = useState(true);

    let checker = (arr, target) => target.every(v => arr.includes(v));

    const handleUpload = async (e) => {
        setAlert({ alert: false, message: '', severity: "" });
        setdisable(true);
        let fileObj = e.target.files[0];

        if (!fileObj.name.match(/.(csv || xlsx || txt)$/i))
            setAlert({ alert: true, message: 'file format is not correct , please select a csv or txt file', severity: "error" });

        else if (fileObj.size > (1048576 * 500))
            setAlert({ alert: true, message: 'Max file size is 500 Mb', severity: "error" });

        else
            await ExcelRenderer(fileObj, async (err, res) => {
                if (err) setAlert({ alert: true, message: 'Something went wrong , please try again', severity: "error" });
                else if (!checker(res.rows[0], Model.selectedHeader)) {
                    setAlert({ alert: true, message: 'Your header does not match the selected header , please select a valid data', severity: "error" });
                }
                else {
                    await setData(res.rows);
                    await setAlert({ alert: true, message: 'Data uploaded successfully', severity: "success" });
                    setdisable(false);
                }
            });
    };

    const PredictionFcn = async () => {

        setLoading(true);

        helpers.deploy( {newData : Data} ,Model.id).then((response) => {
            setOutClassPr(response.data.OutClassPr);
            setMadXpPr(response.data.MadXpPr);
            handleClose();
            setType("Prediction");
            setLoading(false);
            setEnablePr(false);
        })
        .catch((err) => {
            console.log(err);
            err.response.status === 401 ?
                helpers.tokenExpirationAlert(history) :
                setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
                setLoading(false);
        });

        // try {
        //     setLoading(true);
        //     const res = await fetch(`${path}/deploy/${Model.id}`, {
        //         method: 'POST', mode: 'cors', body: JSON.stringify({ newData: Data }),
        //         headers: { 'Content-Type': 'application/json', 'xauthtoken': token, 'Authorization': 'Bearer ' + token },
        //     });

           


        //     let json = await res.json();

        //     if (res.status === 200) {
        //         await setOutClassPr(json.OutClassPr);
        //         await setMadXpPr(json.MadXpPr);
        //         await handleClose();
        //         await setType("Prediction");
        //     }
        //     else { setAlert({ alert: true, message: json.message || 'Error occured , please try again', severity: "error" }); setLoading(false); }

        //     await setLoading(false);
        //     await setEnablePr(false);

        // } catch (err) { setAlert({ alert: true, message: 'post New Data fnc error ' + err, severity: "error" }); setLoading(false); }
    
    };

    return <>

        <Dialog open={open} onClose={handleClose}>

            <DialogTitle style={{ background: '#27293d' }} disableTypography>Drag or click to upload a file ... </DialogTitle>

            <DialogContent style={{ background: '#27293d' }} dividers>

                {loading ? <Progress/> : null}

                <input className={classes.inputFile1} type="file" name="file" accept=".xlsx , .csv , .txt"
                    onChange={handleUpload.bind(this)} />

                <Paper className={classes.dropzone} >
                    <CloudUploadIcon style={{ marginTop: '10%' }} />
                </Paper>

            </DialogContent>

            <DialogActions style={{ background: '#27293d' }}>
                <Button disabled={disable} size='small' variant="contained" color="primary" onClick={PredictionFcn} >Save</Button>
            </DialogActions>

            <Alert alert={alert} setAlert={setAlert} />

        </Dialog>
    </>

}
export default Prediction;