import React from 'react';
import { withContext } from '../../../../context'
import { ListItemText } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import UploadData from './Steps/UploadData/UploadData';
import Preprocessing from './Steps/Preprocessing/Preprocessing';
import TrainingSetting from './Steps/TrainingSetting/TrainingSetting';
import FeatureSelection from './Steps/FeatureSelection/FeatureSelection';
import FeatureSelectionReg from './Steps/FeatureSelection/FeatureSelectionReg';
import DataTable from './Steps/DataTable/DataTable';
import Visualization from './Steps/Visualization/Visualization';
import ClusterView from './Steps/Cluster/Clustering';
import ClusterResults from './Steps/Cluster/ClusterResults';
import { Card, CardTitle, CardHeader, CardBody, Row, Col } from "reactstrap";
import { CardText, InfoSquare } from 'react-bootstrap-icons';
import _ from 'underscore';
import { InputForm, SelectForm } from '../../../../Components';



const StepContent = ({ state, setState, disabled, setDisabled , stepIndex, disableHeader, setFile , setExist }) => {

  const ProblemTypeOptions = [
    { label: 'Classification', value: 'Classification' },
    { label: 'Regression', value: 'Regression' },
    { label: 'Clustering', value: 'Clustering' }
  ];

  switch (stepIndex) {
    case 0: return <>  {  }
      <Typography > What would you like to call your Model ? </Typography>
      <Row style={{ marginTop: 10 }}>
        <Col lg="4">
          <Card style={{ background: "#27293d", border: "1px solid #4728f5 ", paddingBottom: 10 }} >
            <CardHeader>
              <CardTitle tag="h6">
                <CardText color="royalblue" size={25} style={{ marginRight: 5 }} />
                Model Name
              </CardTitle>
            </CardHeader>
            <CardBody>
              <InputForm setState={setState} stateName={state} stateParam={"Name"} title={"Name must be a string between 2 and 50 characters"} type={"text"} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
    case 1: return <>  {disabled = !state.ProblemType}
      <ListItemText primary='What sort of probleme are you trying to solve today ?'
        secondary="Select the appropriate task from below" />
      <Row style={{ marginTop: 10 }}>
        <Col lg="4">
          <Card style={{ background: "#27293d", border: "1px solid #4728f5 ", paddingBottom: 4 }}>
            <CardHeader>
              <CardTitle tag="h6">
                <InfoSquare color="royalblue" size={20} style={{ marginRight: 10 }} />
                Problem Type
              </CardTitle>
            </CardHeader>
            <CardBody>
              <SelectForm setState={setState} stateName={state} stateParam={"ProblemType"} options={ProblemTypeOptions} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
    case 2: return <>  {disabled = state.Data.length === 0 }
      <UploadData state={state} setState={setState} disabled={disableHeader} setFile={setFile} setExist={setExist} />
    </>
    case 3: return <>  {disabled = (state.Target.length === 0 && state.ProblemType !== "Clustering")}
      <DataTable matrix={state.Data}/>
    </>
    case 4: return <>  {disabled = (!state.Normalizer)}
      <Preprocessing />
    </>
    case 5: return <>  {state.ProblemType === "Clustering" ?
      <ClusterView />
      :
      <Visualization />
    }
    </>
    case 6: return <>  {state.ProblemType === "Clustering" ?
      <ClusterResults /> :
      <>
        {disabled = ((state.ValidMethod === "HoldOut" && state.TrainRate === 0)
          || (state.ValidMethod === "HoldOut" && state.TrainRate === 100)
          || (!state.ValidMethod)
          || (!state.TrainingMethod)
        ) ? true : false}
        <TrainingSetting />
      </>}
    </>
    case 7: return <>  {state.ProblemType === "Clustering" ?
      <Visualization />
      : <>
        {disabled = (!state.MembershipFS || !state.N) ? true : false}
        {state.ProblemType === "Regression" ? <FeatureSelectionReg /> : <FeatureSelection />}
      </>
    }
    </>
    case 8: return <>  {disabled = ((state.ValidMethod === "HoldOut" && state.TrainRate === 0)
      || (state.ValidMethod === "HoldOut" && state.TrainRate === 100)
      || (!state.ValidMethod)
      || (!state.TrainingMethod)
    ) ? true : false}
      <TrainingSetting />
    </>
    case 9: return <>  {state.ProblemType === "Regression" ?
      <>
        {disabled = (!state.N || !state.Lambda || !state.FitMethodFS || !state.SolverFS
          || !state.LossFunctionFS || !state.KernelScale || !state.SolverReg) ? true : false}
        <FeatureSelectionReg />
      </>
      :
      <>
        {disabled = (!state.MembershipFS || !state.N) ? true : false}
        <FeatureSelection />
      </>
    }
    </>
    default: return <></>
  }
};

export default withContext(StepContent);



{/*
 const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0: return <>  {disabled = !state.Name ? true : false}
        <Typography > What would you like to call your Model ? </Typography>
        <Row style={{ marginTop: 10 }}>
          <Col lg="4">
            <Card style={{ background: "#27293d", border: "1px solid #4728f5 ", paddingBottom: 10 }} >
              <CardHeader>
                <CardTitle tag="h6">
                  <CardText color="royalblue" size={25} style={{ marginRight: 5 }} />
                  Model Name
                </CardTitle>
              </CardHeader>
              <CardBody>
                <InputForm setState={setState} stateName={state} stateParam={"Name"} title={"Name must be a string between 2 and 50 characters"} type={"text"} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
      case 1: return <>  {disabled = !state.ProblemType ? true : false}
        <ListItemText primary='What sort of probleme are you trying to solve today ?'
          secondary="Select the appropriate task from below" />
        <Row style={{ marginTop: 10 }}>
          <Col lg="4">
            <Card style={{ background: "#27293d", border: "1px solid #4728f5 ", paddingBottom: 4 }}>
              <CardHeader>
                <CardTitle tag="h6">
                  <InfoSquare color="royalblue" size={20} style={{ marginRight: 10 }} />
                  Problem Type
                </CardTitle>
              </CardHeader>
              <CardBody>
                <SelectForm setState={setState} stateName={state} stateParam={"ProblemType"} options={ProblemTypeOptions} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
      case 2: return <>  {disabled = state.Data.length === 0 ? true : false}
        <UploadData disabled={disableHeader} path={path} setFile={setFile} />
      </>
      case 3: return <>  {disabled = (state.Target.length !== 0 || state.ProblemType === "Clustering") ? false : true}
        <DataTable matrix={state.Data} />
      </>
      case 4: return <>  {disabled = (!state.Normalizer) ? true : false}
        <Preprocessing />
      </>
      case 5: return <>  {state.ProblemType === "Clustering" ?
        <ClusterView />
        :
        <Visualization />
      }
      </>
      case 6: return <>  {state.ProblemType === "Clustering" ?
        <ClusterResults /> :
        <>
          {disabled = ((state.ValidMethod === "HoldOut" && state.TrainRate === 0)
            || (state.ValidMethod === "HoldOut" && state.TrainRate === 100)
            || (!state.ValidMethod)
            || (!state.TrainingMethod)
          ) ? true : false}
          <TrainingSetting />
        </>}
      </>
      case 7: return <>  {state.ProblemType === "Clustering" ?
        <Visualization />
        : <>
          {disabled = (!state.MembershipFS || !state.N) ? true : false}
          {state.ProblemType === "Regression" ? <FeatureSelectionReg /> : <FeatureSelection />}
        </>
      }
      </>
      case 8: return <>  {disabled = ((state.ValidMethod === "HoldOut" && state.TrainRate === 0)
        || (state.ValidMethod === "HoldOut" && state.TrainRate === 100)
        || (!state.ValidMethod)
        || (!state.TrainingMethod)
      ) ? true : false}
        <TrainingSetting />
      </>
      case 9: return <>  {state.ProblemType === "Regression" ?
        <>
          {disabled = (!state.N || !state.Lambda || !state.FitMethodFS || !state.SolverFS
            || !state.LossFunctionFS || !state.KernelScale || !state.SolverReg) ? true : false}
          <FeatureSelectionReg />
        </>
        :
        <>
          {disabled = (!state.MembershipFS || !state.N) ? true : false}
          <FeatureSelection />
        </>
      }
      </>
      default: return <></>
    }
  };
*/}
