import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { ModelToolbar, ModelCard, AddModel } from './components';
import { withContext } from '../../context';
import { Share, Progress, DropdownItem } from "../../Components";
import { Dialog, DialogActions, DialogTitle, DialogContent, Button, GridList, GridListTile } from '@material-ui/core';
import {Alert} from "../../Components"
import helpers from '../../api';

const useStyles = makeStyles(theme => ({

  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',

  },
  gridList: {    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    paddingBottom : 10 ,
  },
  App: {
    fontSize: 20,
    width: "100%",
    marginLeft: 15,
    marginRight: 10,
    borderBottom: "2px solid #4728f5",
    borderTop: "2px solid #4728f5",
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  empty: {
    paddingTop: 150,
    display: "flex",
    margin: "auto",
  },
}));

const ModelList = ({ path, history, loading, setLoading, error, openAdd, setOpenAdd, draft, setDraft, state, setState, resetState, fetchData, token, getCurrentState }) => {

  const
    classes = useStyles(),
    [modelId, setModelId] = useState(),
    [openDelete, setOpenDelete] = useState(false),
    [openShare, setOpenShare] = useState(false),
    [search, setSearch] = useState(''),
    [alert, setAlert] = useState({ alert: false, message: "", severity: "info" }),


    handleDeleteClick = (id_model) => {
      setOpenDelete(true);
      setModelId(id_model);
    },

    handleShareClick = (id_model) => {
      setOpenShare(true);
      setModelId(id_model);
    },
    
    handleDeleteCancel = () => setOpenDelete(false),
    
    handleShareclose = () => setOpenShare(false),

    handleDeleteConfirm = async (id) => {
      helpers.deleteModel(id).then(() => {
         resetState();
         handleDeleteCancel();
         setState(prevState => ({
          ...prevState,
          Models : state.Models.filter( obj => obj._id !== id)
        }));
      })
        .catch((err) => {
          console.log(err);
          err.response.status === 401 ?
          helpers.tokenExpirationAlert(history) :
            setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
        });
    },

    handleContinue = (model_id) => {
      setState(prevState => ({ ...prevState, Id: model_id }));
      setOpenAdd(true);
      if (model_id !== '000000000000000000000000')
        getCurrentState(model_id);
    },

    change = (object, key, value) => {
      object[key] = value;
      return object;
    },

    handleFavorite = async (id) => {
      helpers.setFavorite(id).then((response) => { 
        setState(prevState => ({
          ...prevState,
          Models: state.Models.map(model => 
            model._id === id ? 
            change(model, "favorite", response.data.favorite) : model)
        }));
     })
       .catch((err) => {
         console.log(err);
         err.response.status === 401 ?
         helpers.tokenExpirationAlert(history) :
           setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
       });
    };

  let filtredModels = state.Models
    .filter(model => model.name ? model.name.toLowerCase().includes(search) : [])
    .filter(ele => draft ? !ele.updatedDate : ele.updatedDate);

  return <>

    <ModelToolbar
      style={{ marginBottom: 10 }}
      setOpen={setOpenAdd}
      history={history}
      setSearch={setSearch}
      draft={draft}
      path={path}
      setDraft={setDraft}
    />

    {loading ? <Progress /> :
      <div className={classes.content}>
        {
          error ?
            <Grid container spacing={3}>
              <div className={classes.empty} > <Typography>{error}</Typography>  </div>
            </Grid>
            :
            <Grid container spacing={3}>
              {
                filtredModels.length === 0 ?
                  <div className={classes.empty} > <Typography>  No models found  </Typography> </div>
                  :
                  <>
                    <div className={classes.App}>
                      <DropdownItem title="Favorites" theme="dark">
                        {
                          <GridList className={classes.gridList} cols={4.2}>
                            {
                              filtredModels.map((model) => {
                                if (model.favorite)
                                  return (
                                    <GridListTile  lg={3} md={4} sm={6} xs={12} style = {{ height : "fit-content" , marginRight : 18}} key={model._id}>
                                      <ModelCard
                                        model={model}
                                        history={history}
                                        path={path}
                                        handleFavorite={handleFavorite}
                                        handleDeleteClick={handleDeleteClick}
                                        handleShareClick={handleShareClick}
                                        handleContinue={handleContinue}
                                      />
                                    </GridListTile>
                                  )
                              })
                            }
                          </GridList>
                        }
                      </DropdownItem>
                    </div>

                    <br/>

                      {
                        filtredModels.map(model => {
                          if (!model.favorite)
                            return (
                              <Grid style = {{height : "fit-content"}} item key={model._id} lg={3} md={4} sm={6} xs={12}>
                                <ModelCard
                                  model={model}
                                  history={history}
                                  path={path}
                                  handleFavorite={handleFavorite}
                                  handleDeleteClick={handleDeleteClick}
                                  handleShareClick={handleShareClick}
                                  handleContinue={handleContinue}
                                />
                              </Grid>
                            )
                        })
                      }
                  </>
              }
            </Grid>
        }
      </div>
    }

    <Dialog open={openDelete} onClose={handleDeleteCancel}>
      <div style={{ background: "#27293d" }}>
        <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
        <DialogContent dividers>
          Are you sure you want to delete this model ?
        </DialogContent>
        <DialogActions>

          <Button onClick={handleDeleteCancel} color='primary' size='small'> Cancel </Button>
          <Button onClick={async () => { await handleDeleteConfirm(modelId); setOpenAdd(false); }} color='primary' size='small'> Delete </Button>
          {openAdd ? <Button onClick={() => { handleDeleteCancel(); resetState(); setOpenAdd(false); fetchData(); }} color='primary' size='small'> Save as draft </Button>
            : null}
        </DialogActions>
      </div>
    </Dialog>

    <AddModel
      open={openAdd}
      setOpen={setOpenAdd}
      id={state.Id}
      handleDeleteClick={handleDeleteClick}
      loading={loading}
    />

    <Share open={openShare} handleClose={handleShareclose} id={modelId} path={path} setAlert={setAlert} />
    <Alert alert={alert} setAlert={setAlert} />

  </>
};

export default withContext(ModelList);
