import React, { useState, useEffect, useCallback } from 'react';
import { getToken } from '../../Utils/common';
import { Provider } from '../../context';
import ModelList from "./ModelList";
import helpers from '../../api';
import {Alert} from "../../Components";

const App = ({ url, history }) => {

  const
    [alert, setAlert] = useState({ alert: false, message: "", severity: "info" }),
   [draft, setDraft] = useState(false),
   [openAdd, setOpenAdd] = useState(false),
   [loading, setLoading] = useState(false),
   token = getToken();

  const [state, setState] = useState({
    path: url,
    Models: [],
    Id: "000000000000000000000000",
    Name: "",
    ProblemType: "Classification",
    Data: [],
    Filtered :[],
    Target: [],
    Normalizer: "scaling",
    ValidMethod: "LeaveOneOut",
    TrainingMethod: "",
    TrainRate: 80,
    MembershipModel: "gauss",
    MembershipIndex: 0,
    KNumber: 1,
    KernelFunction: "linear",
    KernelParam: "",
    BoxConstraint: "",
    MembershipFS: "gauss",
    Threshold: 0,
    N: 0,
    DataIsSelected:false,
    NbrOfVariables: 0,
    Connective: "PROBABILISTIC",
    LamdaFunction: "FUZZY_BINOMIAL",
    ExegencyLevel: 1.0,
    Lambda: 0,
    FitMethodFS: "exact",
    SolverFS: "lbfgs",
    LossFunctionFS: "mad",
    SolverReg: "ISDA",
    KernelScale: 1,
    ActiveStep: 0,
    NameFile: "",
    TableHeader: false,
    TableLabels: false,
    OutClassNbr: 0,
    Index: null,
    MarkerNames: [],
    MarkerNamesNum: [],
    MarkerNamesInt: [],
    MarkerNamesTxt: [],
    RowNames: [],
    NumData: [],
    IntData: [],
    TextData: [],
    NormalizedNumData: [],
    NormalizedIntData: [],
    RankedMarkers: [],
    RankedWeights: [],
    F: [],
    Err: [],
    Auc: [],
    FlagErr: false,
    GadNic: 0,
    ClassCount: 0,
    MembershipMatrix: [],
  });

  const resetState = () => {
    setDraft(false);
    setOpenAdd(false);
    setState({
      path: url,
      Models: [],
      Id: "000000000000000000000000",
      Name: "",
      ProblemType: "Classification",
      Data: [],
      Target: [],
      Normalizer: "scaling",
      ValidMethod: "LeaveOneOut",
      TrainingMethod: "LAMDA",
      TrainRate: 80,
      MembershipModel: "gauss",
      MembershipIndex: 0,
      KNumber: 1,
      KernelFunction: "linear",
      KernelParam: "",
      BoxConstraint: "",
      MembershipFS: "gauss",
      Threshold: 0,
      N: 0,
      NbrOfVariables: 0,
      Connective: "PROBABILISTIC",
      LamdaFunction: "FUZZY_BINOMIAL",
      ExegencyLevel: 1.0,
      Lambda: 0,
      FitMethodFS: "exact",
      SolverFS: "lbfgs",
      LossFunctionFS: "mad",
      SolverReg: "ISDA",
      KernelScale: 1,
      ActiveStep: 0,
      NameFile:"",
      TableHeader: false,
      TableLabels: false,
      OutClassNbr: 0,
      Index: null,
      Indexsaved:null,
      MarkerNames: [],
      MarkerNamesNum: [],
      MarkerNamesInt: [],
      MarkerNamesTxt: [],
      RowNames: [],
      NumData: [],
      IntData: [],
      TextData: [],
      NormalizedNumData: [],
      NormalizedIntData: [],
      PCAResult1: [],
      PCAResult2: [],
      Score1: "",
      Score2: "",
      PCAResultOrg1: [],
      PCAResultOrg2: [],
      ScoreOrg1: "",
      ScoreOrg2: "",
      RankedMarkers: [],
      RankedWeights: [],
      F: [],
      Err: [],
      Auc: [],
      FlagErr: false,
      GadNic: 0,
      ClassCount: 0,
      MembershipMatrix: [],
    });
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    
    helpers.models().then((response) =>{
      setState(prevState => ({ ...prevState, Models: response.data }));
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      err.response.status === 401 ?
      helpers.tokenExpirationAlert(history) :
        setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
        setLoading(false);
    });

}, [history]);

  useEffect(() => { fetchData() }, [fetchData]);
  
  const getCurrentState = (modelId) => {

    setLoading(true);

    helpers.modelData(modelId).then((response) => {
     
      setState(prevState => ({
        ...prevState,
        ActiveStep: response.data.activeStep ? response.data.activeStep : 0,
        Name: response.data.name,
        ProblemType: response.data.problemType ? response.data.problemType : 'Classification',
        Data: response.data.initData,
        Target: response.data.target,
        MarkerNames: response.data.markerNames,
        MarkerNamesNum: response.data.markerNamesNum,
        MarkerNamesInt: response.data.markerNamesInt,
        MarkerNamesTxt: response.data.markerNamesTxt,
        RowNames: response.data.rowNames,
        NumData: response.data.numData,
        IntData: response.data.intData,
        TextData: response.data.textData,
        Normalizer: response.data.normalizer ? response.data.normalizer : "scaling",
        ValidMethod: response.data.validMethod ? response.data.validMethod : "LeaveOneOut",
        TrainingMethod: response.data.trainingMethod,
        TrainRate: response.data.trainRate ? response.data.trainRate : 0,
        MembershipModel: response.data.membershipModel ? response.data.membershipModel : 'gauss',
        MembershipIndex: response.data.membershipIndex ? response.data.membershipIndex : 0,
        KNumber: response.data.kNumber ? response.data.kNumber : 1,
        KernelFunction: response.data.kernelFnc ? response.data.kernelFnc : 'linear',
        KernelParam: "",
        BoxConstraint: "",
        MembershipFS: response.data.membershipFS ? response.data.membershipFS : "gauss",
        Threshold: response.data.threshold ? response.data.threshold : 0,
        N: response.data.n ? response.data.n : response.data.markerNames ? response.data.markerNames.length : 0,
        NbrOfVariables: response.data.markerNames ? response.data.markerNames.length : 0,
        Connective: response.data.connective ? response.data.connective : "PROBABILISTIC",
        LamdaFunction: response.data.lamdaFunction ? response.data.lamdaFunction : "FUZZY_BINOMIAL",
        ExegencyLevel: response.data.exegencyLevel ? response.data.exegencyLevel : 1.0,
        Lambda: response.data.lambda ? response.data.lambda : 0,
        FitMethodFS: response.data.fitMethodFS ? response.data.fitMethodFS : "exact",
        SolverFS: response.data.solverFS ? response.data.solverFS : "lbfgs",
        LossFunctionFS: response.data.lossFunctionFS ? response.data.lossFunctionFS : "mad",
        SolverReg: response.data.solverReg ? response.data.solverReg : "ISDA",
        KernelScale: response.data.kernelScale ? response.data.kernelScale : 1,
        NormalizedNumData: response.data.normalizedData,
        PCAResult1: response.data.PCA0,
        PCAResult2: response.data.PCA1,
        Score1: response.data.PCAScore0 ? response.data.PCAScore0 : "",
        Score2: response.data.PCAScore1 ? response.data.PCAScore1 : "",
        PCAResultOrg1: response.data.PCAOrg0,
        PCAResultOrg2: response.data.PCAOrg1,
        ScoreOrg1: response.data.PCAOrgScore0 ? response.data.PCAOrgScore0 : "",
        ScoreOrg2: response.data.PCAOrgScore1 ? response.data.PCAOrgScore1 : "",
        RankedMarkers: response.data.RankedMarkers,
        RankedWeights: response.data.RankedWeights,
        F: response.data.F,
        Err: response.data.Err,
        Auc: response.data.Auc,
        FlagErr: response.data.flagErr,
        GadNic: response.data.gadNIC ? response.data.gadNIC : 0,
        ClassCount: response.data.classCount ? response.data.classCount : 0,
        MembershipMatrix: response.data.membershipMatrix
      }));

      setLoading(false);
    })
      .catch((err) => {
        console.log(err);
        err.response.status === 401 ?
        helpers.tokenExpirationAlert(history) :
          setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
          setLoading(false);
      });
  };

  const getContext = () => ({ state, setState, history, token , resetState, fetchData });

  return (
    <Provider value={getContext()}>
      <ModelList
        path={url}
        openAdd={openAdd}
        setOpenAdd={setOpenAdd}
        loading={loading}
        setLoading={setLoading}
        draft={draft}
        setDraft={setDraft}
        getCurrentState={getCurrentState}
      />

    <Alert alert={alert} setAlert={setAlert} />

    </Provider>
  );
}

export default App;
