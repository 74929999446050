import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TableChartIcon from '@material-ui/icons/TableChart';
import HeatMap from "react-heatmap-grid";
import Table from './Table'
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BorderAllIcon from '@material-ui/icons/BorderAll';
import { Row, Col } from "reactstrap";
import { LineChart, BarChart, CheckBox } from "../../../Components";
import { FormControl, RadioGroup, FormControlLabel } from '@material-ui/core';
import _ from 'underscore';
import { Progress } from '../../../Components';

const useStyles = makeStyles(theme => ({

  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  paper: {
    borderRadius: 0,
    height: '63vh',
    minWidth: 200,
    background: "#27293d",
    padding: 10,
  },
  HeatMap: {
    overflow: "auto",
    display: 'block',
    height: 'fit-content',
    width: 'fit-content',
    maxWidth: '98%',
    maxHeight: '60vh',
    textAlign: "center",
  },
  nested: {
    marginLeft: 30, marginBottom: 5
  },
  label: {
    fontSize: 14,
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
  },
}));

const mergeWith = (arr1, arr2) => arr1.map((item, index) => [item, arr2[index]]);

const TrainingResults = ({ Model, path, id, token , step }) => {

  const classes = useStyles();
  
  const
    confMatOrder = (Model.confMatOrder),
    confMat = (Model.confMat),
    problemType = Model.problemType,
    name = Model.name,
    outComeTest = (Model.outComeTest),
    outClass = (Model.outClass),
    Spec = (Model.Spec),
    Sens = (Model.Sens),
    ACC = Model.ACC,
    MSE = Model.MSE;

  const
    xLabels = confMatOrder,
    yLabels = xLabels;

  const
    [checked, setChecked] = useState("observation"),

    [State, setState] = React.useState('HM'),

    handleCheck = (e) => setChecked(e.target.value),

    handleClick = (e) => setState(e.currentTarget.dataset.value),

    getSum = (Arr) => Arr.reduce(function (a, b) { return a + b; }, 0),

    getPartialSum = (Arr) => {
      let partialSumArr = [];
      for (let i = 0; i < Arr.length; i++) {
        let partialSum = 0
        for (let j = 0; j < Arr[0].length; j++) {
          if (i === j) continue; partialSum += parseFloat(Arr[i][j]);
        }
        partialSumArr.push(partialSum)
      }
      return partialSumArr;
    },

    getHorizantalSum = (Arr) => {
      let SumArr = [], partialSumArr = [], TData = [];
      for (let j = 0; j < Arr[0].length; j++) {
        let sum = 0, partialSum = 0;
        for (let i = 0; i < Arr.length; i++) {
          sum += parseFloat(Arr[i][j]);
          if (i === j) { TData.push(Arr[i][j]); continue; }
          partialSum += parseFloat(Arr[i][j]);
        }
        SumArr.push(sum)
        partialSumArr.push(partialSum.toFixed(2))
      }
      return { SumArr: SumArr, partialSumArr: partialSumArr, TData: TData }
    },

    getPData = () => {
      let PData = [];
      for (let i = 0; i < confMat.length; i++) {
        let row = [], sum = getSum(confMat[i]);
        for (let j = 0; j < confMat[0].length; j++)
          row.push((confMat[i][j] / sum * 100).toFixed(2))
        PData.push(row)
      }
      let partialSum = getPartialSum(PData);

      for (let i = 0; i < PData.length; i++)
        PData[i] = [...PData[i], PData[i][i], partialSum[i].toFixed(2)]

      return PData;
    },

    getPHData = () => {
      let PHData = [];
      let HSum = getHorizantalSum(confMat)
      for (let j = 0; j < confMat[0].length; j++) {
        let row = [];
        for (let i = 0; i < confMat.length; i++)
          row.push(HSum.SumArr[j] === 0 ? 0 : (confMat[i][j] / HSum.SumArr[j] * 100).toFixed(2))
        PHData.push(row)
      }
      let PartialHsum = getHorizantalSum(PHData)
      PHData.push(PartialHsum.TData)
      PHData.push(PartialHsum.partialSumArr)
      return PHData;
    };

  const getHeatmapData = () => {

    const
      xLabels1 = [...xLabels, 'True Positive', 'False Negative'],
      yLabels1 = [...yLabels, 'True Positive', 'False discovery'];

    if (checked === "observation") return { HeatMapdata: confMat, xLabels: xLabels, yLabels: yLabels }
    if (checked === "rates") return { HeatMapdata: getPData(), xLabels: xLabels1, yLabels: yLabels }
    if (checked === "predictive") return { HeatMapdata: getPHData(), xLabels: xLabels, yLabels: yLabels1 }
  }

  const HeatMapParam = getHeatmapData();

  const
    XLabels = HeatMapParam.xLabels,
    YLabels = HeatMapParam.yLabels,
    HeatMapdata = HeatMapParam.HeatMapdata;


  const getBackground = (x, y, min, max, value) => {
    if (x === confMat[0].length + 1 || y === confMat.length + 1) return 'rgb(200, 0, 0)'
    else if (x === confMat[0].length || y === confMat.length) return 'rgb(0, 100, 0)'
    else return `rgb(0, 204, 0, ${1 - (max - value) / (max - min)})`
  }

  return  ( 
    _.isEmpty(Model) ? <Progress />  
    : 
    <Row className="m-0">

    <Col lg={3}>

      <ListItem dense ><ListItemText primary='Training Sets' /> </ListItem>

      <Paper className={classes.paper}>
        <Divider />
        <Toolbar variant='dense'>
          <ListItem dense style={{ textAlign: 'left' }}  >
            <ListItemText primary='Model Name' secondary={name} />
          </ListItem>
          <ListItem dense style={{ textAlign: 'start' }}  >
            {problemType !== 'Regression' ?

              <ListItemText primary='Accuracy' secondary={(100 - (ACC * 100)).toFixed(2) + '%'} />
              :
              <ListItemText primary='MSE' secondary={MSE.toFixed(2)} />
            }
          </ListItem>
        </Toolbar>
        <Divider />
      </Paper>
    </Col>

    <Col lg={9}>
      <ListItem dense>
        <ListItemText primary='Results' />

        <IconButton color={State === "HM" ? "primary" : "default"} size='small' data-value='HM' onClick={handleClick}  >
          <BorderAllIcon fontSize='small' />
        </IconButton>

        <IconButton color={State === "CH" ? "primary" : "default"} size='small' data-value='CH' onClick={handleClick} style={{ marginLeft: 10 }} >
          <ShowChartIcon fontSize='small' />
        </IconButton>

        <IconButton color={State === "TB" ? "primary" : "default"} size='small' data-value='TB' onClick={handleClick} style={{ marginLeft: 10 }} >
          <TableChartIcon fontSize='small' />
        </IconButton>

      </ListItem>

      <Paper className={classes.paper}>
        {

          State === 'HM' ?
            problemType === "Regression" ?

              <div style={{ height: "90%" }}>
                <BarChart
                  labels={Array.from(Array(outClass.length)).map((v, i) => i)}
                  data={outComeTest.map((v, i) => v - outClass[i])}
                  name="Residuals"
                />
              </div>
              :
              <>
                <Divider />
                <div className="row  mt-2 justify-content-md-center" >
                  <FormControl component="fieldset">
                    <RadioGroup row value={checked} onChange={handleCheck}>
                      <div className="col-3">
                        <FormControlLabel style={{ marginRight: 20 }} classes={{ label: classes.label }} value="observation" control={<CheckBox />} label="Number of Observation" />
                      </div>
                      <div className="col-4">
                        <FormControlLabel style={{ marginRight: 20 }} classes={{ label: classes.label }} value="rates" control={<CheckBox />} label="True Positive Rates / Negative Fales Rates" />
                      </div>
                      <div className="col-4">
                        <FormControlLabel classes={{ label: classes.label }} value="predictive" control={<CheckBox />} label="Positive Predictive Values / Fales Discovery Rates" />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>

                <Divider />

                <div className="row mt-2 justify-content-md-center">

                  <div style={{ marginTop: checked === "predictive" ? confMatOrder.length * 15 + 30 : confMatOrder.length * 15 }} className="col-2">
                    <p style={{ transform: "rotate(-90deg)" }}> True Class</p>
                  </div>

                  <div style={{ maxHeight: 400, overflow: "auto" }} className="col-9 mt-2">
                    <HeatMap
                      xLabels={XLabels}
                      yLabels={YLabels}
                      data={HeatMapdata}
                      xLabelsLocation={"bottom"}
                      height={50}
                      cellStyle={(background, value, min, max, data, x, y) => ({
                        background: getBackground(x, y, min, max, value),
                        fontSize: "12px",
                        border: '2px solid white',
                        marginLeft: x === confMat[0].length || x === 0 ? 25 : null,
                        width: 100,
                        marginTop: y === confMat.length || y === 0 ? 10 : null,
                        marginRight: x === (confMat[0].length + 1) ? 20 : null,
                      })}
                      cellRender={value => checked === "observation" ? value : value + "%"}
                    />

                    <div className="row justify-content-md-center mt-3" >
                      <p> Predicted Class</p>
                    </div>

                  </div>
                </div>
              </>
            :
            State === 'TB' ? <div style={{ margin: "auto" }}> <Table Model={Model} /></div>
              :
              problemType === "Regression" ?
                <div style={{ height: "90%" }}>
                  <LineChart
                    name="Prediction"
                    matrix={mergeWith(["OutComeTest", ...outComeTest], ["OutClass", ...outClass])}
                    ProblemType={problemType}
                    Target={[]}
                    live={false}
                  />
                </div>
                :
                <div style={{ height: "90%" }}>
                  <LineChart
                    name="Sensitivity"
                    matrix={mergeWith(["Specificity", ...Spec], ["Sensitivity", ...Sens])}
                    ProblemType={problemType}
                    Target={[]}
                    live={false}
                  />
                </div>
        }
      </Paper>
    </Col>
    
  </Row>
  
  )
}
export default TrainingResults;

