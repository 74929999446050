import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Badge } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import { Sidebar, Topbar, Footer } from './components';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { Profile, SidebarNav } from "./components/Sidebar/components";
import { getRole, getUser, removeUserSession } from "../../Utils/common";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import SettingsIcon from "@material-ui/icons/Settings";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import { withStyles } from "@material-ui/core/styles";
import Menu from '@material-ui/core/Menu';

import { Alert } from '../../Components';
import helpers from "../../api";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  notification: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: "#27293d",
  },
  inline: {
    display: 'inline',
  },
  appBar: {
    borderTop: "1px solid #e44cc4 ",
    background: "#4728f5",
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    background: "#4728f5",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  signOutButton: {
    marginLeft: theme.spacing(150)
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: "#27293d",
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  flexGrow: {
    flexGrow: 1
  },

}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: 5,
    background: "#27293d"
  },
})(props => (<Menu elevation={0}
  getContentAnchorEl={null}
  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  {...props} />));


const Main = props => {

  const { children, url, history } = props,
    classes = useStyles(),
    theme = useTheme(),
    [open, setOpen] = useState(false),
    handleDrawerOpen = () => setOpen(true),
    handleDrawerClose = () => setOpen(false),
    [anchorEl, setAnchorEl] = useState(null),
    [alert, setAlert] = useState({ alert: false, message: "", severity: "info" }),
    pages = [],
    role = getRole(),
    user = getUser();

  if (user != null) {
    if (user.admin || role.permission.readModel || role.permission.allModel) {
      pages.push({
        title: 'Dashboard',
        href: '/dashboard',
        icon: <DashboardIcon />
      });
    }

    if (user.admin || role.permission.readUser) {
      pages.push(
        {
          title: 'Users',
          href: '/users',
          icon: <PeopleIcon />
        },
        {
          title: 'Models',
          href: '/models',
          icon: <LockOpenIcon />
        },
        {
          title: 'Roles',
          href: '/roles',
          icon: <LockOpenIcon />
        }

      )
    }

    pages.push(
      {
        title: 'Account',
        href: '/account',
        icon: <AccountBoxIcon />
      },
      {
        title: 'Settings',
        href: '/settings',
        icon: <SettingsIcon />
      })
  } else {
    history.push('/sign-in')
  }

  const
    [notifications, setNotifications] = useState([]),
    [notificationsall, setNotificationsall] = useState([]),
    handleMenuClose = () => { setAnchorEl(null); setNotifications([]) },
    handlelogout = () => { removeUserSession(); history.push('/sign-in'); };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    helpers.viewNotifications().then(() => {
      fetchOldNotification();
    })
      .catch((err) => {
        err.response.status === 401 ?
          helpers.tokenExpirationAlert(history) :
          setAlert({ alert: true, message: 'Error occured , please try again', severity: "error" });
      });
  }


  const fetchOldNotification = async () => {

    helpers.getNotifications(true).then((response) => {
      setNotificationsall(response.data);
    })
      .catch((err) => {
        err.response.status === 401 ?
          helpers.tokenExpirationAlert(history) :
          setAlert({ alert: true, message: 'Error occured , please try again', severity: "error" });
      });
  }

  const fetchNewNotification = async () => {

    helpers.getNotifications(false).then((response) => {
      setNotifications(response.data);
    })
      .catch((err) => {
        err.response.status === 401 ?
          helpers.tokenExpirationAlert(history) :
          setAlert({ alert: true, message: 'Error occured , please try again', severity: "error" });
      });
  }


  useEffect(() => { 
    fetchOldNotification();
    fetchNewNotification() 
  }, [notifications.length]);

  


  console.log(notifications)
  console.log(notificationsall)
  return (
    <div className={classes.root}>
      <CssBaseline />

      <StyledMenu id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >

        <List className={classes.notification}>
          {notifications.length !== 0 ? <ListSubheader>{"New Notifications"}</ListSubheader> : null}
          {notifications.map((notification) => (
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="" src={url + '/' + notification.fromuserIDimage} />
              </ListItemAvatar>
              <ListItemText
                primary={"New model"}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                    </Typography>
                    {notification.Message}
                  </React.Fragment>
                }
              />
            </ListItem>

          ))}

          <Divider variant="inset" component="li" />

          {
            notificationsall.length !== 0 ?
              <ListSubheader>
                {"Old Notifications"}
              </ListSubheader> : null
          }
          {notificationsall.map((notificationall) => (
            <ListItem key={notificationall._id}  alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="" src={url + '/' + notificationall.fromuserIDimage} />
              </ListItemAvatar>
              <ListItemText
                primary={"New model"}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >

                    </Typography>
                    {notificationall.Message}
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}

          {(notifications.length === 0 && notificationsall.length === 0) ?

            <ListItem button>
              <ListItemText primary="No notifications" />
            </ListItem>
            : null}
        </List>

      </StyledMenu>

      <AppBar

        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Minerworx
          </Typography>
          <div className={classes.flexGrow} />
          <IconButton color="inherit">
            <Badge
              color="secondary"
              badgeContent={notifications.length}
            >
              <NotificationsIcon onClick={handleMenuClick} />
            </Badge>
          </IconButton>
          <IconButton

            color="inherit"
            onClick={handlelogout}
          >
            <InputIcon />

          </IconButton>

        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>

        <Profile url={url} />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />

      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>

      <Alert alert={alert} setAlert={setAlert} />


    </div>
  );
}


Main.propTypes = {
  children: PropTypes.node
};

export default Main;
