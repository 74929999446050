import React from 'react';
import _ from 'underscore'
import { Chart, Line } from 'react-chartjs-2';
import { FormControlLabel, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import DownsamplePlugin from 'chartjs-plugin-downsample';
import { ExportAs } from "../../../../../../../Components/Exports/Exports";
import { AutoComplete } from "../../../../../../../Components";

const ObjectData = (matrix, idx1, idx2, target, checked, RowNames) => {

    let LineData = [];

    for (let i = 1, len = matrix.length; i < len; ++i)
        checked ?
            LineData.push({ 'x': matrix[i][idx1], 'y': matrix[i][idx2], 'r': RowNames[i - 1], 'z': target[i - 1] })
            :
            LineData.push({ 'x': matrix[i][idx1], 'y': matrix[i][idx2], 'r': RowNames[i - 1] });

    return LineData;
};

let gridLines = {
    drawBorder: false,
    color: "rgba(29,140,248,0.1)"
};

const TwoDScatterChart = ({ matrix, ProblemType, Target, live, RowNames }) => {

    const liveOptions = {
        downsample: {
            enabled: true,
            threshold: (400),
            auto: false,
            onInit: true,
            preferOriginalData: true,
            restoreOriginalData: false,
        },
        hover: { mode: null },
        title: { display: false },
        scales: {
            xAxes: [{ scaleLabel: { display: false }, type: "linear", minRotation: 45 }],
            yAxes: [{ scaleLabel: { display: false } }]
        },
        legend: { display: false },
        tooltips: { enabled: false },
        animation: { duration: 0 },
        responsiveAnimationDuration: 0,
        line: { tension: 0 }
    };

    const MarkerNames = matrix[0];
    const ref = React.useRef(null);

    const [checked, setChecked] = React.useState(false);
    const handleCheck = () => setChecked(checked => !checked);

    Chart.plugins.register(DownsamplePlugin);

    const [idx, setIdx] = React.useState({ S0: 0, S1: 1 });

    let DataSet = [];

    let ChartData = ObjectData(matrix, idx.S0, idx.S1, Target, checked, RowNames);

    if (checked) {
        let groups = Object.entries(_.groupBy(ChartData, 'z'));
        for (let i = 0; i < groups.length; i++) {
            let color = (`hsl(${(i + 1) * 100}, 75%, 50%)`);
            DataSet.push({
                label: groups[i][0],
                fill: false,
                backgroundColor: color,
                pointBackgroundColor: color,
                pointHoverBackgroundColor: '#9a9a9a',
                pointHoverBorderColor: 'black',
                showLine: false,
                borderColor: color,
                pointRadius: 2,
                data: groups[i][1]
            })
        }
    }
    else {
        DataSet.push({
            fill: false,
            backgroundColor: '#3498db',
            pointBackgroundColor: '#3498db',
            pointHoverBackgroundColor: '#9a9a9a',
            pointHoverBorderColor: 'black',
            showLine: false,
            borderColor: '#3498db',
            pointRadius: 2,
            data: ChartData
        })
    };

    let ChartOptions = {
        tooltips: {
            enabled: true,
            bodySpacing: 4,
            xPadding: 12,
            intersect: 0,
            callbacks: {
                label: (tooltipItem, data) => {
                    return ' ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].r +
                        ' ( ' + tooltipItem.xLabel + ' , ' + tooltipItem.yLabel + ' ) ' || '';
                },
                title: (tooltipItem, data) => {
                    return data.datasets[tooltipItem[0].datasetIndex].label || '';
                }
            }
        },
        maintainAspectRatio: false,
        title: {
            display: true,
            text: '2D Scatter Plot',
            fontColor: '#9a9a9a'
        },
        legend: {
            display: checked,
            labels: { fontColor: '#9a9a9a' },
            position: 'right'
        },
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: MarkerNames[idx.S0],
                    fontColor: '#9a9a9a',
                    fontSize: 16,
                    lineHeight: 2
                },
                gridLines: gridLines,
                type: 'linear',
                ticks: {
                    fontColor: '#9a9a9a',
                }
            }],
            yAxes: [{
                type: 'linear',
                scaleLabel: {
                    display: true,
                    labelString: MarkerNames[idx.S1],
                    fontColor: '#9a9a9a',
                    fontSize: 16,
                    lineHeight: 2.5
                },
                gridLines: gridLines,
                ticks: {
                    fontColor: '#9a9a9a',
                }
            }],
        },
    };

    let CanvasChart = {
        data: canvas => { return { datasets: DataSet } },
        options: live ? liveOptions : ChartOptions
    };

    const handleChangeX = (ev, value) => {
        if (ev.target.value !== "" || ev.target.value !== null) {
            setIdx({ S0: MarkerNames.indexOf(value), S1: idx.S1 })
        }
    }

    const handleChangeY = (ev, value) => {
        if (ev.target.value !== "" || ev.target.value !== null) {
            setIdx({ S0: idx.S0, S1: MarkerNames.indexOf(value) })
        }
    }


    return <>
        {
            (live) ? null :
                <div className="row justify-content-center">

                    <div className="mr-5">
                        <AutoComplete options={MarkerNames} handleChange={handleChangeX} title={"X-Axis..."} />
                    </div>

                    <div className="mr-5">
                        <AutoComplete options={MarkerNames} handleChange={handleChangeY} title={"Y-Axis..."} />
                    </div>

                    {
                        ProblemType === "Regression" ? null :
                            <div className="mr-5">
                                <FormControlLabel label={<Typography variant="body2" color="textSecondary">Use Target</Typography>}
                                    style={{ border: '1px solid #9a9a9a', borderRadius: 5, width: "100%", maxHeight: 38, background: '#1e1e2f' ,paddingRight : 2 }}
                                    control={<Checkbox checked={checked} onChange={handleCheck} />}
                                />
                            </div>
                    }

                    <div className="mr-5">  {ExportAs(MarkerNames[idx.S0] + " vs " + MarkerNames[idx.S1], ChartData, ref)} </div>

                </div>
        }

        <Line ref={ref} data={CanvasChart.data} options={live ? liveOptions : CanvasChart.options} /> </>
}

export default React.memo(TwoDScatterChart);
