import React, { useEffect } from 'react';
import { Scatter } from 'react-chartjs-2';
import _ from 'underscore';



const PcaChart = ({ ProblemType, PCAResult1, PCAResult2, PCAScore1, PCAScore2, RowNames, Target, setState, live, ExportAs }) => {

	const ref = React.useRef(null);

	const getScutterData = () => {
		let ScutterData = [];
		for (let i = 0, len = PCAResult1.length; i < len; i++)
			ProblemType === "Regression" ?
			ScutterData.push(_.object(['x', 'y', 'r'], [PCAResult1[i], PCAResult2[i], RowNames[i]] ))
				:
			ScutterData.push(_.object(['x', 'y', 'r', 'z'], [PCAResult1[i], PCAResult2[i], RowNames[i], Target[i]]))
		return ScutterData
	};

	const groups = Object.entries(_.groupBy(getScutterData(), 'z'));

	useEffect(() => setState(prevState => ({ ...prevState, OutClassNbr: groups.length })), [groups.length, setState]);

	const get_datasets = () => {
		var dataSet = [];
		for (let i = 0; i < groups.length; i++) {
			let color = (`hsl(${((i + 1) * 100)}, 100%, 50%)`)
			dataSet.push({
				label: groups[i][0],
				backgroundColor: color,
				pointBackgroundColor: color,
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: color,
				pointHoverBorderColor: color,
				pointHoverBorderWidth: 4,
				pointRadius: 5,
				pointHitRadius: 1,
				pointStyle: 'triangle',
				data: groups[i][1]
			})
		}
		return dataSet
	};

	const live_options = {
		hover: { mode: null },
		title: { display: false },
		scales: {
			xAxes: [{ scaleLabel: { display: false }, type: "linear", minRotation: 45 }],
			yAxes: [{ scaleLabel: { display: false } }]
		},
		legend: { display: false },
		tooltips: { enabled: false },
		animation: { duration: 0, },
		responsiveAnimationDuration: 0,
		line: { tension: 0 }
	};

	const chartOptions = (color) => {
		return {
			title: { display: true, text: 'Principal Component Analysis', fontColor: color },
			maintainAspectRatio: false,
			legend: {
				display: ProblemType !== "Regression", labels: { fontColor: color }
			},
			scales: {
				xAxes: [{
					ticks: { fontColor: color, beginAtZero: true, },
					scaleLabel: { display: true, labelString: `Principal Component 1   (${PCAScore1}%)`, fontColor: color, fontSize: 16 }
				}],
				yAxes: [{
					ticks: { fontColor: color, beginAtZero: true },
					scaleLabel: { display: true, labelString: `Principal Component 2    (${PCAScore2}%)`, fontColor: color, fontSize: 16, lineHeight: 2.5 },
				}]
			},
			tooltips: {
				enabled: true,
				callbacks: {
					label: (tooltipItem, data) => {
						return ' ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].r +
							' ( ' + tooltipItem.xLabel + ' , ' + tooltipItem.yLabel + ' ) ' || '';
					},
					title: (tooltipItem, data) => {
						return data.datasets[tooltipItem[0].datasetIndex].label || '';
					}
				}
			},
		}
	};

	let Chart_Data = {
		data: () => {
			return { datasets: get_datasets() };
		},
		options: live ? live_options : chartOptions("#9a9a9a")
	};

	return <>
		{ live ? null : <div className={"float-right"}> {ExportAs("PcaChart Results", getScutterData(), ref)} </div>}
		<Scatter
			id={"pca"}
			ref={ref}
			data={Chart_Data.data}
			options={Chart_Data.options}
		/>
	</>
}
export default React.memo(PcaChart);


