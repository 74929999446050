import React, { useState } from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { withContext } from '../../../../../../context';
import { Row, Col , Card , CardHeader , CardTitle , CardBody } from "reactstrap";
import { SelectForm } from '../../../../../../Components';
import { FormControl , RadioGroup, FormControlLabel } from '@material-ui/core';
import { InfoSquare } from 'react-bootstrap-icons';
import {CheckBox } from "../../../../../../Components";

const useStyles = makeStyles(theme => ({
      root: {
            width: '100%',
            maxWidth: 360,
            //  backgroundColor: theme.palette.background.paper,
      },
      nested: {
            paddingLeft: theme.spacing(4),
      },
      label: {
            fontSize: 16,
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
      },
}));

const Preprocessing = ({ state , setState }) => {

      const classes = useStyles();
      const [checked, setChecked] = useState("quantitative");
      const handleCheck = (e) => {
            if (e.target.value === "interval") setState( prevState => ({ ...prevState , Normalizer: "scaling" }));
            setChecked(e.target.value);
      }

      const NormalizerOptions = checked === "quantitative" ?
            [
                  {
                        label: "Scaling",
                        value: "scaling"
                  },
                  {
                        label: "Standarize",
                        value: "standarize",
                        isDisabled: state.MarkerNamesInt.length !== 0
                  },
                  {
                        label: "Quantile",
                        value: "quantile",
                        isDisabled: state.MarkerNamesInt.length !== 0
                  },
            ]
                  :[
                  {
                        label: "Scaling",
                        value: "scaling"
                  },
            ];

      return <>
            <Row><Col><ListItemText primary='Select your parameters' /> </Col></Row>
            
            <Row className={"justify-content-center"}>
                  <Col className="text-center text-md-left">
                        <FormControl component="fieldset">
                              <RadioGroup row value={checked} onChange={handleCheck}>
                                    <FormControlLabel classes={{ label: classes.label }} value="quantitative" control={<CheckBox />} label="Quantitative Data" />
                                    <FormControlLabel classes={{ label: classes.label }} value="interval" control={<CheckBox />} label="Interval Data" />
                              </RadioGroup>
                        </FormControl>
                  </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
                  <Col lg="4">
                        <Card style={{ background: "#27293d", border: "1px solid #4728f5 ", paddingBottom: 4 }}>
                        <CardHeader>
                              <CardTitle tag="h6">
                                    <InfoSquare color="royalblue" size={20} style={{ marginRight: 10 }} />
                                    Normalization Technique
                              </CardTitle>
                        </CardHeader>
                        <CardBody>
                              <SelectForm setState = {setState} stateName={state} stateParam = {"Normalizer"} options={NormalizerOptions}/>
                              </CardBody>
                        </Card>           
                  </Col>
            </Row>
      </>
}
export default withContext(Preprocessing)