import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';
import axios from "axios";
import {getToken, getUser, removeUserSession} from "../../../../Utils/common";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Password = props => {
  const { className, url,history, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    password: '',
    confirm: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const UpdatePassword = () => {
    if (values.password === values.confirm) {
      new Promise(resolve => {
        axios.post(url + '/updateUser/' + getUser()._id, values, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
          }
        }).then(response => {
          handleClick()

          removeUserSession();

          history.push("sign-in");

        }).catch(error => {
          history.push("sign-in");
        });
      })
    } else {

      setOpen1(true);

    }
  }
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setOpen1(false);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            info updated with success
          </Alert>
        </Snackbar>
        <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            passwords does not match
          </Alert>
        </Snackbar>
        <CardHeader
          subheader="Update password"
          title="Password"
        />
        <Divider />
        <CardContent style={{ minHeight: 240 }}>
          <TextField
            fullWidth
            label="Password"
            name="password"
            required
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Confirm password"
            name="confirm"
            required
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button

            variant="outlined"
            onClick={UpdatePassword}
          >
            Update
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
