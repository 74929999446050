import React, { useState } from 'react';
import _ from 'underscore';
import { getOptions } from '../../../Controller';
import { Card , CardBody , CardFooter , Row, Col  } from "reactstrap";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Button } from '@material-ui/core';
import {SelectForm , InputForm } from "../../../../../../../Components";

const StateMap = ({ Target, setState }) => {

    let classNames = _.uniq(Target);
    let options = getOptions(classNames, []);
    const [open, setOpen] = useState(false)
    const [map , setMap ] = useState({ className : "", mapName: "" });

    const handleValidation = () => {
        let mappedArray = Target.map((item) => { return item === map.className ? map.mapName : item; });
        setState( prevState => ({ ...prevState , Target : mappedArray }));
        setOpen(true);
    };
    const Alert = (props) =>  {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    };

    return <>

    <Card  className="w-75 p-3" style={{ background: '#27293d' , border: "1px solid #4728f5 " , margin : 20 }} >
        <CardBody>
        <Row  className="w-75 p-3">

            <Col xs="6">
                <p>Class Name</p>
                <SelectForm setState = {setMap} stateName={map} stateParam = {"className"} options={options}/>
            </Col>

            <Col xs="6">
                <p>Mapped Name</p>
                <InputForm setState={setMap} stateName={map} stateParam={"mapName"} title={"Type your mapped name"} type={"text"}/>
            </Col>
        </Row>
        
         </CardBody>
        <CardFooter>
                    <Button variant = 'contained' color = 'primary' onClick={handleValidation}> Validate </Button> 
        </CardFooter>
       
   
    </Card>
        <Snackbar open={open} autoHideDuration={2000}  onClose={() => setOpen(false)}>
            <Alert  onClose={() => setOpen(false)} severity="success">
                Class name added successfully !
        </Alert>
        </Snackbar>
    </>

}
export default (StateMap) 