import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import ReactLoading from 'react-loading';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Progress = () => {

  const classes = useStyles();

  return <>
    <Backdrop
      className={classes.backdrop}
      open={true}>
      <ReactLoading
        type={'spinningBubbles'}
        color={'blue'} />
    </Backdrop>
  </>
}

export default Progress;