import React from 'react';
import _ from 'underscore'
import { Bar} from 'react-chartjs-2';
import Select from 'react-select';
import { withStyles } from '@material-ui/styles';
import { getOptions, customStyles } from '../../../Controller';
import {FormControlLabel , Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { Row, Col } from "reactstrap";
import Fade from '@material-ui/core/Fade';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { saveCsv } from '../../../../../../../Components/Exports/Exports'

const StyledMenu = withStyles({
	paper: {
	  borderRadius: 0
	}, 
  }) (props => (<Menu elevation={0}
	  getContentAnchorEl={null}
	  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
	  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
	  {...props} />));


const Chart = ({TextData, MarkerNamesTxt,Target,live,live_options , saveImage })=> {

	const [idx, setIdx] = React.useState(0);
	const chart_ref = React.useRef(null);

	const [checked, setChecked] = React.useState(false);
	const handleChange = () => setChecked(!checked);
	let options = getOptions(MarkerNamesTxt, []);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	 const handleClick = (event) => setAnchorEl(event.currentTarget);
	 const handleClose = () => setAnchorEl(null) 

	
		const getData = (t) =>{
			let BarData = []
			let data   = _.zip.apply(_,TextData);
			let labels =  _.uniq( data[t])
			let classes = _.uniq( Target)
			if(checked){
			for(let i = 0 ; i < classes.length ; i++){
			  let row = []
			  for(let j = 0 ; j < Target.length ; j++)
				if( Target[j] === classes[i]) row.push(data[t][j])
			BarData.push(row)
		   }
		}

		let dataset = {BarData :checked ? BarData : data[t] , labels : labels , classes : classes }
		return dataset
		}
	
		const getBarData  = () => {
			let dataset = [] ;
			let data =  getData(idx);
			let labels = data.labels
			let classes = data.classes 
			let arr = data.BarData
			if(checked)	{
				for(let i = 0 ; i < arr.length ; i++)
				{	
					let color = (`hsl(${i*100 }, 75%, 50%)`) 
					let sumArr  = []
					let aCount = new Map([...new Set(arr[i])].map(x => [x, arr[i].filter(y => y === x).length]));
					for(let j = 0 ; j < labels.length ; j++) 
					sumArr.push ( aCount.get(labels[j])? aCount.get(labels[j]) / TextData.length : 0 );
					dataset.push({  
						label : classes[i] ,
						backgroundColor:  color,
						hoverBackgroundColor :  color,
						borderColor :  color,
						barThickness:20 ,
						data: sumArr 
				})
				}
			}
			
			else {
				let color = 'blue' 
				let sumArr  = []
				let aCount = new Map([...new Set(arr)].map(x => [x, arr.filter(y => y === x).length]));
				for(let j = 0 ; j < labels.length ; j++) 
				sumArr.push ( aCount.get(labels[j])? aCount.get(labels[j]) / TextData.length : 0 );
				dataset.push({ 
				backgroundColor:  color ,
				hoverBackgroundColor :  color ,
				borderColor :    color  ,
				barThickness:20 ,
				data: sumArr 
				})
			}
		
			const BarTxtData =  { labels : labels , datasets: dataset }
			return BarTxtData;
		}

		const getExcelData  = () => {
			let dataset = [] ;
			let data =  getData(idx);
			let labels = data.labels
			let classes = data.classes 
			let arr = data.BarData
			if(checked)	{
				for(let i = 0 ; i < arr.length ; i++)
				{	
					let sumArr  = []
					let aCount = new Map([...new Set(arr[i])].map(x => [x, arr[i].filter(y => y === x).length]));
					for(let j = 0 ; j < labels.length ; j++) 
					sumArr.push ( aCount.get(labels[j])? aCount.get(labels[j]) / TextData.length : 0 );
					dataset.push( _.object(['class', ...labels] , [classes[i] , ...sumArr]) )
				}
			}
			
			else {
				let sumArr  = []
				let aCount = new Map([...new Set(arr)].map(x => [x, arr.filter(y => y === x).length]));
				for(let j = 0 ; j < labels.length ; j++) 
				sumArr.push ( aCount.get(labels[j])? aCount.get(labels[j]) / TextData.length : 0 );
				dataset.push(  _.object(labels , sumArr)  );
			}

			return dataset;
		}

		const bar_options = {
			title: { display: true, text: MarkerNamesTxt[idx]  ,fontSize : 14 , fontColor : '#9a9a9a'} , 
			legend: { display: checked ,  labels:{fontColor : '#9a9a9a' } , position : 'right'} ,
			maintainAspectRatio: false ,
			scales: { 
					xAxes: [{ stacked: true , scaleLabel : { display : true , labelString: 'Modality' ,
						  fontColor : '#9a9a9a' , fontSize : 14 , lineHeight: 2.5} ,ticks : {fontColor : '#9a9a9a'}}],
					yAxes: [{ stacked: true , scaleLabel : { display : true , labelString: 'Frequancy' , fontColor : '#9a9a9a' ,
						  fontSize : 14 , lineHeight: 2.5} , ticks : {fontColor : '#9a9a9a'} }]
			},
			tooltips: { callbacks: {title :  () =>{ return } 	} }
        }		

        return <>
		{  live ? null :
			<Row className={"justify-content-center"}>
				<Col lg="3">
					<Select styles={customStyles}
						options={options}
						onChange={(value) => { if (value)  setIdx( MarkerNamesTxt.indexOf(value.value) )  }}
						theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: '#1e1e2f' } })} />
				</Col>
				<Col lg="3">
						<FormControlLabel  label={ <Typography variant="body2" color="textSecondary">Use Target</Typography>}
						style ={{ border : '1px solid #9a9a9a'  , borderRadius : 5 , width : "100%" , maxHeight : 38 , background :'#1e1e2f' }}
							control={<Checkbox checked={checked} onChange={handleChange}/>}
						/>
				</Col>
				<Col lg="4">
					<IconButton  onClick={handleClick}> <MoreVertIcon fontSize = 'small'/>  </IconButton>
					<StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose} TransitionComponent={Fade} >
						<MenuItem onClick={ () => { saveCsv(  "Text Histogram : " +  MarkerNamesTxt[idx] , getExcelData()) ; handleClose() }} >Export as CSV </MenuItem>
						<MenuItem onClick={ () => { saveImage(chart_ref ,  "Text Histogram : " +  MarkerNamesTxt[idx] ) ; handleClose() }}>Export as PNG</MenuItem>
					</StyledMenu>
				</Col>
			</Row>
		}
			<Bar ref = {chart_ref} data ={getBarData()} options = { live ? live_options("category") : bar_options}/>

    </>
    }
    export default React.memo(Chart) ;