import React from 'react' ;
import { Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    radio: {
          '&:hover': {
                backgroundColor: 'transparent',
          },
    },
    icon: {
          borderRadius: '50%',
          width: 13,
          height: 13,
          boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
          backgroundColor: '#f5f8fa',
          backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    },
    checkedIcon: {
          backgroundColor: '#137cbd',
          backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
          '&:before': {
                display: 'block',
                width: 13,
                height: 13,
                backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
                content: '""',
          },
    },
    
}));

const Checkbox = (props) => {
    const classes = useStyles();
    return (
          <Radio
                className={classes.radio}
                disableRipple
                color="default"
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                icon={<span className={classes.icon} />}
                {...props}
          />
    );
}

export default Checkbox ;