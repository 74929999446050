import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GridList, GridListTile, GridListTileBar } from '@material-ui/core';
import { Dialog, Button, ListItemText, IconButton, ListItem } from '@material-ui/core';
import PcaChart from '../Visualization/VisualizationGrpahs/PcaChart';
import { withContext } from '../../../../../../context';
import CloseIcon from '@material-ui/icons/Close';
import MatrixView from '../Components/Matrix_view';
import { saveImage, saveCsv, ExportAs } from '../../../../../../Components/Exports/Exports';
import { live_options } from "../../Controller";
import { InputForm, Progress, BarChart, Alert, CustomLineChart } from '../../../../../../Components';
import { Row, Col } from "reactstrap";
import _ from 'underscore';

import { build , analyse2} from '../../Controller';


const useStyles = makeStyles(theme => ({
  Dialog: { minHeight: '90vh', minWidth: '95vw', background: '#1e1e2f' },
  VarNbr: { background: 'linear-gradient(#1e1e2f,#1e1e24)' },
  gridListele: { border: '2px solid  #212121', marginBottom: 20, marginTop: 10 },
  button: { borderRadius: 0, marginLeft: 5, marginTop: 10, float: "right" },
  addbutton: { marginLeft: theme.spacing(1), marginTop: theme.spacing(1), width: 100 },
}));

const mergeWith = (arr1, arr2) => arr1.map((item, index) => [index + 1, item, arr2[index]]);

const FeatureSelectionResults = ({ history, state, setState, handleBack , resetState, fetchData , setBuildLoading }) => {

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({ O1: false, O2: false, O3: false, O4: false });
  const [alert, setAlert] = React.useState({ alert: false, severity: "", message: "" });

  const handleOpen = (e) => setOpen({ ...open, [e.currentTarget.dataset.value]: true });
  const handle4Open = () => { setLoading(false); setOpen({ O4: true, O3: false, O2: false, O1: false, }); }
  const handleClose = () => setOpen({ O1: false, O2: false, O3: false, O4: false });

  let ranked_markers_limited = state.RankedMarkers.slice(0, 400);
  let ranked_weights_limited = state.RankedWeights.slice(0, 400);
  let ranked_matrix = [['Rank', 'Feature', 'Weight'], ...mergeWith(ranked_markers_limited, ranked_weights_limited)];
  let labels = _.without(state.MarkerNames, ..._.without(state.MarkerNames, ...ranked_markers_limited));

  
  return <>
    <Row style={{ marginRight: 0 }}>

      <Col style={{ MarginBottom: 5 }} lg={5}>
        <MatrixView
          matrix={ranked_matrix}
          height={"62vh"}
          name="Ranked Markers"
        />
      </Col>

      <Col lg={7}>
        <GridList spacing={20} cellHeight={160} cols={2}>

          <GridListTile style={{ marginRight: 10, width: "45%" }} data-value="O1" className={classes.gridListele} key={1} onClick={(e) => handleOpen(e)}>
            <BarChart
              name="ranked"
              live={true}
              labels={labels}
              data={ranked_markers_limited.map((v, i) => state.RankedWeights[ranked_markers_limited.indexOf(labels[i])])}
              type={state.N <= 100 ? "category" : "logarithmic"}
            />
            <GridListTileBar title="Feature Importance " style={{ textAlign: "center" }} />
          </GridListTile>

          <GridListTile style={{ width: "45%" }} data-value="O2" className={classes.gridListele} key={2} onClick={(e) => handleOpen(e)}>
            <CustomLineChart state={state} setState={setState} live={true} />
            <GridListTileBar title="Optimal Features" style={{ textAlign: "center" }} />
          </GridListTile>

          {_.intersection(state.MarkerNamesNum, (_.first(state.RankedMarkers, [state.NbrOfVariables]))).length > 1 ?
            <GridListTile style={{ width: "45%" }} data-value="O3" className={classes.gridListele} key={3} onClick={(e) => handleOpen(e)}>
              <PcaChart PCAResult1={state.PCAResult1}
                PCAResult2={state.PCAResult2}
                PCAScore1={state.Score1}
                PCAScore2={state.Score2}
                RowNames={state.RowNames}
                Target={state.Target}
                ProblemType={state.ProblemType}
                MarkerNamesNum={state.MarkerNamesNum}
                setState={setState}
                saveCsv={saveCsv}
                saveImage={saveImage}
                live={true}
                live_options={live_options}
              />

              <GridListTileBar title="Principal Component Analysis" style={{ textAlign: "center" }} />
            </GridListTile>
            : null
          }

        </GridList>
      </Col>

    </Row>

    <Row>
      <Col>
        <Button className={classes.addbutton} onClick={handleBack} variant="contained" color="primary"> Back </Button>
        <Button className={classes.addbutton} variant="contained" color="primary"
          onClick={async e => { e.persist(); build(history, state, resetState, fetchData, setAlert, setBuildLoading) }}>  Add </Button>
      </Col>
    </Row>

    <Dialog classes={{ paper: open.O3 ? classes.VarNbr : classes.Dialog }} open={open.O1 || open.O2 || open.O3 || open.O4} onClose={handleClose}>
      {
        open.O3 ? null :
          <ListItem dense onClick={handleClose}>
            <ListItemText primary='' />
            <IconButton>
              <CloseIcon />
            </IconButton>
          </ListItem>
      }
      {
        open.O1 ? <>
          <Row className={"justify-content-sm-center"} style={{ maxHeight: '95vh', maxWidth: '95vw' }}>
            <Col xs="10">
              <div style={{ height: '70vh' }}>
                <BarChart
                  name="ranked"
                  labels={labels}
                  data={ranked_markers_limited.map((v, i) => state.RankedWeights[ranked_markers_limited.indexOf(labels[i])])}
                />
              </div>
            </Col>
          </Row>
        </>
          : open.O2 ? <>
            <CustomLineChart state={state} setState={setState} live={false} />
          </>
            : open.O3 ? <>

              {loading ? <Progress /> : null}

              <div style={{ padding: 20, margin: 10 }}>
                <p style={{ marginBottom: 10 }} component={'span'}> Number of features is set to : </p>
                <InputForm setState={setState} stateName={state} stateParam={"NbrOfVariables"} title={`Must be number between 2 and ${state.N}`} type={"number"} min={2} max={state.N} step={1} />
                <Button
                  disabled={state.MarkerNamesNum.length === 0 || _.intersection(state.MarkerNamesNum, (_.first(state.RankedMarkers, [state.NbrOfVariables]))).length < 2}
                  variant='contained' color='primary' className={classes.button}
                  onClick={ () => analyse2 (history, state, setState, handle4Open, setAlert, setLoading) }>
                  Set
                </Button>
              </div>

            </>
              : open.O4 ? <>
                <Row className={"justify-content-center"} style={{ maxHeight: '95vh', maxWidth: '95vw' }}>
                  <Col xs="10">
                    <div style={{ height: '70vh' }}>
                      <PcaChart
                        PCAResult1={state.PCAResult1}
                        PCAResult2={state.PCAResult2}
                        PCAScore1={state.Score1}
                        PCAScore2={state.Score2}
                        RowNames={state.RowNames}
                        Target={state.Target}
                        ProblemType={state.ProblemType}
                        MarkerNamesNum={state.MarkerNamesNum}
                        setState={setState}
                        ExportAs={ExportAs}
                        live={false} />
                    </div>
                  </Col>
                </Row>
              </>
                : null
      }
      <Alert alert={alert} setAlert={setAlert} />


    </Dialog>

  </>
}

export default withContext(FeatureSelectionResults);
