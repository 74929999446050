import React from 'react';
import MaterialTable from 'material-table';
import axios from "axios";
import { getToken } from "../../Utils/common";

export default function MaterialTableDemo({ path }) {
    const token = getToken();
    const [error, setError] = React.useState(null);
    const [state, setState] = React.useState({
        columns: [],
        data: []
    });

    async function getModels() {
        return await axios({
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            method: "get",
            url: path + '/all_models',
        }).then(res => res.data);
    }

    getModels().then(res => {
        if (state.data.length === 0) {

            setState(prevState => {
                const data = res;
                const columns = [];
                    columns.push({ title: 'Id', field: '_id' , cellStyle:{  maxWidth: "60px", whiteSpace: "nowrap",overflow: "hidden", textOverflow: "ellipsis" }});
                    columns.push({ title: 'Name', field: 'Name'});
                    columns.push({ title: 'Date', field: 'updatedDate' });
                    columns.push({ title: 'Api Key', field: 'key' , cellStyle:{  maxWidth: "60px", whiteSpace: "nowrap",overflow: "hidden", textOverflow: "ellipsis" }});

                return { ...prevState,data , columns }
            })
        }
    })

    return (
        <MaterialTable style = {{ background: "#27293d" }}
                       title = { <p style = {{ color : "red" , fontSize : 16}}>  {error}</p> }
                       columns = {state.columns}
                       data = {state.data}
                       editable = {{
                           onRowUpdate: (newData, oldData) =>
                               new Promise(resolve => {
                                   axios.post(path + '/updateUser/' + oldData._id, newData, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } }).then(() => {
                                       getModels().then(res => {
                                           setTimeout(() => {
                                               resolve();
                                               setState(prevState => {
                                                   const data = res
                                                   return { ...prevState, data };
                                               });
                                           }, 600);
                                       })
                                   }).catch(error => {
                                       if (error.response.status === 401)
                                           setError(" user already exists");
                                   });
                               }),
                           onRowDelete: oldData =>
                               new Promise(resolve => {
                                   axios.post(path + '/deleteUser/' + oldData._id, '', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } }).then(() => {
                                       getModels().then(res => {
                                           setTimeout(() => {
                                               resolve();
                                               setState(prevState => {
                                                   const data = res;
                                                   return { ...prevState , data };
                                               });
                                           }, 600);
                                       });
                                   }).catch(error => {
                                       if (error.response.status === 401) setError(" error deleting ");
                                   });
                               }),
                       }}
                       options = {{
                           exportButton: true ,
                           title : { background:'blue' },
                           headerStyle: {
                               background:'#272935',
                               width:50,
                               maxWidth: 50
                           },
                       }}
                       localization = {{ body: { editRow: { deleteText: 'Are you sure you want to delete this model' }}}}
        />
    );
}
