import React from 'react';
import MaterialTable from 'material-table';
import axios from "axios";
import { getToken } from "../../Utils/common";
import TextField from "@material-ui/core/TextField";


export default function MaterialTableDemo({ path }) {
    const [error, setError] = React.useState(null);
    const [state, setState] = React.useState({
        columns: [],
        data: []
    });

    async function getusers() {
        return await axios({
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() },
            method: "get",
            url: path + '/users',
        }).then(res => res.data);
    }

    async function getroles() {
        return await axios({
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() },
            method: "get",
            url: path + '/rolesApi',
        }).then(res => res.data);
    }

    getroles().then(res => {
        if (state.columns.length === 0) {
            setState(prevState => {
                const columns = [];
                columns.push({ title: 'Name', field: 'name'});
                columns.push({ title: 'Username', field: 'username' });
                columns.push({ title: 'Email', field: 'email', type: 'string' });
                columns.push( {
                    title: 'Password', field: 'value',
                    render: rowData => <p>{rowData.password.split('').map(() => '*')}</p>,
                    editComponent: props => (
                        <TextField
                            type="password"
                            value={props.value}
                            onChange={e => props.onChange(e.target.value)}
                        />)
                });
                columns.push({ title: 'Type', field: 'type', lookup: res });

                return {
                    ...prevState, columns
                }
            })
        }
    })

    getusers().then(res => {
        if (state.data.length === 0) {
            setState(prevState => {
                const data = res;
                return { ...prevState, data }
            })
        }
    });

    return (
        <MaterialTable style = {{background: "#27293d"}}
            title={   <p style = {{ color : "red" , fontSize : 16}}>  {error}  </p>}
            columns={state.columns}
            data={state.data}
            editable={{
                onRowAdd: newData =>
                        new Promise(resolve => {
                            if(!newData.name || !newData.username || !newData.email  || !newData.value  || !newData.type) {
                                setError("All fields must be filled");
                                resolve();
                            }

                            else

                        axios.post(path + '/addUser', newData, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() } }).then(() => {
                            getusers().then(res => {
                                setTimeout(() => {
                                    resolve();
                                    setState(prevState => {
                                        const data = res;
                                        return { ...prevState, data };
                                    });
                                }, 600);
                             

                            });
                        }).catch(error => {
                            if (error.response.status === 401) setError("user already exists");
                            setTimeout(() => {
                                resolve();
                                setState(prevState => {
                                    const data = [...prevState.data];
                                    return { ...prevState, data };
                                });

                            }, 600);
                        });
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise(resolve => {
                        axios.post(path + '/updateUser/' + oldData._id, newData, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() } }).then(() => {
                            getusers().then(res => {
                                setTimeout(() => {
                                    resolve();
                                    setState(prevState => {
                                        const data = res
                                        return { ...prevState, data };
                                    });

                                }, 600);
                            })
                        }).catch(error => {
                            if (error.response.status === 401)
                                setError(" user already exists");
                        });
                    }),
                onRowDelete: oldData =>
                    new Promise(resolve => {
                        axios.post(path + '/deleteUser/' + oldData._id, '', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() } }).then(() => {
                            getusers().then(res => {
                                setTimeout(() => {
                                    resolve();
                                    setState(prevState => {
                                        const data = res;
                                        return { ...prevState, data };
                                    });
                                }, 600);
                            });
                        }).catch(error => {
                            if (error.response.status === 401) setError(" error deleting ");
                        });
                    }),
            }}
            options={{
                exportButton: true ,
                headerStyle : {background:'#272935'} ,
                title : { background:'blue' }
            }}
            localization={{ body: { editRow: { deleteText: 'Are you sure you want to delete this user' } } }}
        />
    );
}
