import React from 'react';
import { Chart, Bar } from 'react-chartjs-2';
import DownsamplePlugin from 'chartjs-plugin-downsample';
import { ExportAs } from "../Exports/Exports";


const liveOptions =  {
      downsample: {
        enabled: true,
        threshold: (400) ,
        auto: false, 
        onInit: true, 
        preferOriginalData: true,
        restoreOriginalData: false,
    },
      hover: {mode: null},
      title: { display: false },
      scales: {
        xAxes: [{ scaleLabel: { display: false } ,  minRotation: 45, }], 
        yAxes: [{ scaleLabel: { display: false }  }] 
      },
      legend: { display: false },
      tooltips: {enabled: false},
      animation: { duration: 0},
      responsiveAnimationDuration: 0 ,
      line: { tension: 0 }
    };


const mergeWith = (arr1, arr2) => {
    let r = [];
    for (let n = 0; n < arr1.length; n++)
      r.push([arr1[n], arr2[n]]);
    return r;
  }
  

const BarChart = ({ labels, data, live, name  }) => {

    React.useEffect(() => Chart.plugins.register(DownsamplePlugin), [data]);

    const ref = React.useRef(null);

    const Options = {
        title: {
            display: true,
            text: name === 'ranked' ? 'Features Importance' : 'Residuals Plot',
            fontColor: '#9a9a9a'
        },

        maintainAspectRatio: false,

        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: name === 'ranked' ? 'Features' : 'Observations',
                    fontColor: '#9a9a9a'
                },
                ticks: {
                    fontColor: '#9a9a9a'
                }
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: name === 'ranked' ? 'weights' : 'Residuals',
                    fontColor: '#9a9a9a',
                    lineHeight: 2.5
                },
                ticks: {
                    fontColor: '#9a9a9a'
                }
            }]
        },
        legend: { display: false },
    };

    let BarData = React.useMemo(() => {
        return {
            data: () => {
                return {
                    labels: labels,
                    datasets: [{
                        pointRadius: 3,
                        barThickness: 5,
                        backgroundColor: 'rgba(255,0,0)',
                        data: data
                    }]
                };
            },
            options: live ? liveOptions : Options
        }
    }, [labels, Options , live , data]);


    return <>

        {
            live ? null :
            <div className={"float-right"}>
                    {ExportAs(name, mergeWith(labels , data ), ref)}
            </div>
        }

        <Bar ref={ref} data={BarData.data} options={BarData.options} />

    </>
}

export default BarChart;