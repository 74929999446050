import React from 'react';
import { withContext } from '../../../../../../context';
import { ListItemText, Slider } from '@material-ui/core';
import { SelectForm, InputForm } from "../../../../../../Components";
import { Row, Col, Collapse, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import { InfoSquare } from 'react-bootstrap-icons';


const TrainingSettings = ({ state, setState }) => {

  const marks = [{ value: 100, label: '100%' }, { value: 0, label: '0%' }];

  const handleHoldOut = (e, value) => setState(prevState => ({ ...prevState, TrainRate: value }));

  const ValidMethodeOptions = [
    {
      label: "Leave One Out",
      value: "LeaveOneOut"
    },
    {
      label: "Hold Out",
      value: "HoldOut"
    },
  ];

  const ModelOptions = state.ProblemType === "Regression" ?
    [
      {
        label: "Support Vector Machine",
        value: "SVM",
      }
    ]
    :
    [
      {
        label: "LAMDA",
        value: "LAMDA"
      },
      {
        label: "K-Nearest Neighbors",
        value: "kNN",
        isDisabled: state.MarkerNamesNum.length === 0
      },
      {
        label: "Support Vector Machine",
        value: "SVM",
        isDisabled: state.OutClassNbr > 2 || state.MarkerNamesNum.length === 0
      },
    ];

  const MembershipOptions = [
    {
      label: "Gaussian",
      value: "gauss"
    },
    {
      label: "Binomial",
      value: "f_lamda"
    },
    {
      label: "Gaussian Diagonal",
      value: "gauss_diag"
    },
  ];

  const KernelFunctionOptions = state.ProblemType === "Regression" ?
    [
      {
        label: "Linear",
        value: "linear"
      },
      {
        label: "Gaussian",
        value: "gaussian"
      },
      {
        label: "Polynomial",
        value: "polynomial"
      },

    ] :
    [
      {
        label: "Linear",
        value: "linear"
      },
      {
        label: "Quadratic",
        value: "quadratic"
      },
      {
        label: "Polynomial",
        value: "polynomial"
      },
      {
      label: "RBF",
      value: "RBF"
    },
      {
      label: "LMP",
      value: "LMP"
    },
  ];

  const SolverRegOptions = [
    {
      label: "ISDA",
      value: "ISDA"
    },
    {
      label: "L1QP",
      value: "L1QP"
    },
    {
      label: "SMO",
      value: "SMO"
    },
  ];

  return <>
    <Row> <Col> <ListItemText primary='Select your parameters' /> </Col></Row>

    <Row style={{ marginTop: 10 }}>

      <Col lg="4">
        <Card style={{ background: "#27293d", border: "1px solid #4728f5 ", paddingBottom: 4 }}>
          <CardHeader>
            <CardTitle tag="h6">
              <InfoSquare color="royalblue" size={20} style={{ marginRight: 10 }} />
              Validation Method
            </CardTitle>
          </CardHeader>
          <CardBody>

            <SelectForm setState = {setState} stateName={state} stateParam = {"ValidMethod"} options={ValidMethodeOptions}/>

            <Collapse isOpen={state.ValidMethod === "HoldOut"}>
              <br />
              <Slider min = {0} value={state.TrainRate} valueLabelDisplay="auto"
                onChange={handleHoldOut} onInput={handleHoldOut} marks={marks} track='inverted'
              />
              {state.TrainRate ===  0 ? <div className="alert alert-danger" role="alert"> Train rate should be greater than 0 </div> : null }
              {state.TrainRate ===  100 ? <div className="alert alert-danger" role="alert"> Train rate should be less than 100 </div> : null }
            </Collapse>

          </CardBody>
        </Card>
      </Col>

      <Col lg="4">
        <Card style={{ background: "#27293d", border: "1px solid #4728f5 ", paddingBottom: 4 }}>
          <CardHeader>
            <CardTitle tag="h6">
              <InfoSquare color="royalblue" size={20} style={{ marginRight: 10 }} />
              Model
            </CardTitle>
          </CardHeader>
          <CardBody>

            <SelectForm setState = {setState} stateName={state} stateParam = {"TrainingMethod"} options={ModelOptions}/>

            <Collapse isOpen={state.TrainingMethod === 'LAMDA' || state.TrainingMethod === 'kNN' || state.TrainingMethod === 'SVM'}>
              {
                state.ProblemType !== "Regression" ? <>
                  {
                    state.TrainingMethod === 'LAMDA' ?
                      <>
                        <br />
                        <label htmlFor="MembershipModel"> Membership Function </label>
                        <SelectForm setState = {setState} stateName={state} stateParam = {"MembershipModel"} options={MembershipOptions}/>
                        <br />
                       <label htmlFor="MembershipIndex"> Membership Index </label>
                        <InputForm setState={setState} stateName={state} stateParam={"MembershipIndex"} title={"Must be number between 0 and 1"} type={"number"} min = {0} max = {1} step = {0.1}  />
                      </>

                      : state.TrainingMethod === 'kNN' ?
                        <>
                          <br />
                          <label htmlFor="KNumber"> Number of Neighbors </label>
                          <InputForm setState={setState} stateName={state} stateParam={"KNumber"} title={"Must be positive integer"} type={"number"} min = {1} max = {10} step = {1}  />
                        </>

                        : state.TrainingMethod === 'SVM' ?
                          <>
                            <br />

                            <label htmlFor="KernelFunction">Kernel Function </label>
                            <SelectForm setState = {setState} stateName={state} stateParam = {"KernelFunction"} options={KernelFunctionOptions}/>

                            {
                              state.KernelFunction === " " ?
                                <>
                                  <br />
                                  <label htmlFor="KernelParam"> Poly Order </label>
                                  <InputForm setState={setState} stateName={state} stateParam={"KernelParam"} title={"Must be positive integer"} type={"number"} min = {1} max = {10} step = {1}  />
                                </>
                                : null
                            }
                            {
                              state.KernelFunction === "RBF" ?
                                <>
                                  <br />
                                  <label htmlFor="KernelParam"> RBF Sigma </label>
                                  <InputForm setState={setState} stateName={state} stateParam={"KernelParam"} title={"RBF Sigma"} type={"text"} />
                                </>
                                : null
                            }
                            {
                              state.KernelFunction === "LMP" ?
                                <>
                                  <br />
                                  <label htmlFor="KernelParam"> LMP Param </label>
                                  <InputForm setState={setState} stateName={state} stateParam={"KernelParam"} title={"LMP Param"} type={"text"} />
                                </>
                                : null
                            }

                            <br />
                            <label htmlFor="BoxConstraint"> Box Constraint </label>
                            <InputForm setState={setState} stateName={state} stateParam={"BoxConstraint"} title={"Box Constraint"} type={"text"} />
                          </>
                          : null
                  }

                </> : <>

                  {
                    state.TrainingMethod === 'SVM' ?
                      <>
                        <br />
                        <label htmlFor="KernelFunction">Kernel Function </label>
                        <SelectForm setState = {setState} stateName={state} stateParam = {"KernelFunction"} options={KernelFunctionOptions}/>

                        <br />
                        <label htmlFor="SolverReg">Solver</label>
                        <SelectForm setState = {setState} stateName={state} stateParam = {"SolverReg"} options={SolverRegOptions}/>

                        <br />
                        <label htmlFor="KernelScale"> Kernel Scale </label>
                        <InputForm setState={setState} stateName={state} stateParam={"KernelScale"} title={"Must be auto or number between 0 and 1"} type={"number"} min = {0} max = {1} step = {0.1}  />

                      </> : null
                  }
                </>
              }

            </Collapse>

          </CardBody>
        </Card>

      </Col>

    </Row>

  </>
}
export default withContext(TrainingSettings);