import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import LaptopWindowsIcon from '@material-ui/icons/LaptopWindows';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';
import axios from "axios";
import {getToken, getSetting ,setSetting} from "../../../../Utils/common";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Notifications = props => {
  const { className, url ,...rest } = props;

  const classes = useStyles();
    let windows =false ;
    let linux =false ;
    let apipath ='' ;

  if (getSetting())
  {
    windows=getSetting().windows ;
    linux=getSetting().linux ;
    apipath=getSetting().apiPath
  }
  const [checked, setChecked] = React.useState({
    windows:windows,
    linux:linux

  });
  const [values, setValues] = React.useState({
    url: apipath
  });
  const handleToggle = (prop) => (event) => {
    setChecked({ ...checked, [prop]: event.target.checked });
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setOpen1(false);
  };

  const handleSendData=()=> {


      axios.post(url + '/updateSetting', {
        apiPath:values.url,
        windows:checked.windows ,
        linux:checked.linux
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getToken()
        }
      }).then(() => {
        handleClick()

   setSetting({
            windows :checked.windows,
            linux: checked.linux,
            apiPath : values.url})


      })



  }
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            info updated with success
          </Alert>
        </Snackbar>
        <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            there is in error
          </Alert>
        </Snackbar>
        <CardHeader
          subheader="Manage API Settings"
          title="Settings"
        />
        <Divider />
        <CardContent>



          <List subheader={<ListSubheader>API Settings</ListSubheader>} className={classes.root}>
            <ListItem>
              <ListItemIcon>
                <SystemUpdateAltIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-wifi" primary="Linux" />
              <ListItemSecondaryAction>
                <Switch
                    edge="end"
                    onChange={handleToggle('linux')}
                    checked={checked.linux}
                    inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <LaptopWindowsIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-bluetooth" primary="Windows" />
              <ListItemSecondaryAction>
                <Switch
                    edge="end"
                    onChange={handleToggle('windows')}
                    checked={checked.windows}
                    inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <FormControl fullWidth className={classes.margin} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">URL API</InputLabel>
            <OutlinedInput
                id="outlined-adornment-amount"
                value={values.url}
                onChange={handleChange('url')}
                startAdornment={<InputAdornment position="start">http://</InputAdornment>}
                labelWidth={60}
            />
          </FormControl>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
          onClick={handleSendData}
            variant="outlined"
          >
            Save
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;
