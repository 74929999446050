import React from 'react';
import PropTypes from 'prop-types';

const Topbar = () => {
  return <></>
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
