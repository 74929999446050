
import React from 'react' ;
import Select from 'react-select';


export const customStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: '#1e1e2f',
    color: 'white',
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    borderColor: state.isFocused ? "blue" : "white",
    boxShadow: state.isFocused ? null : null,
  }),

  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),

  option: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? 'grey' : 'white'
  }),

  menu: base => ({
    ...base,
    background: '#1e1e2f',
  }),

  menuList: base => ({
    ...base,
    background: '#212121',
  })
};

const SelectForm = ({setState, stateName, stateParam, options}) => {

    const handleChange = (value) => { setState({ ...stateName, [stateParam]: value.value }) }
  
    return (
      <Select styles={customStyles} options={options}
        value={options.find(option => option.value === stateName[stateParam])}
        onChange={(value) => { handleChange(value) }}
        isOptionDisabled={option => option.isDisabled}
        theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: '#1e1e2f' } })}
      />
    )
  };

  export default SelectForm;