
import React from 'react';
import { Input } from "reactstrap";
import { Tooltip } from '@material-ui/core';

export const InputForm = ({setState, stateName, stateParam, title, type, min, max, step}) => {

  const handleChange = event => {
    if ((event.target.value < min) || (event.target.value > max)) {}
    else setState({ ...stateName, [event.target.name]: event.target.value });
  };

  return (
    <Tooltip arrow title={<span style={{ fontSize: 13 }}> {title} </span>} >
      <Input
        id="input"
        name={stateParam}
        placeholder="type here ..."
        type={type}
        value={stateName[stateParam]}
        min={min}
        max={max}
        step={step}
        onChange={handleChange}
        style={{ backgroundColor: '#1e1e2f', color: "white" }}
      />
    </Tooltip>
  )
};

export default InputForm;