import React from "react";
import { Collapse } from "react-collapse";
import "./DropdownItem.css";
import StarIcon from '@material-ui/icons/Star';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {IconButton} from '@material-ui/core';

class DropdownItem extends React.Component {
  state = {
    isDropdownOpen: false,
  };

  onDropdownClicked = () => {
    this.setState(prevState => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
  };

  render() {
    return (
      <div
        className={
          this.props.theme === "dark"
            ? "accordionContainerDark"
            : "accordionContainerLight"
        }
        id={this.props.id}
      >
        <div className={this.props.theme === "dark" ? "headerBarDark" : "headerBarLight"}>

          <div>
          <IconButton color = {"primary"}>  <StarIcon/> </IconButton>{this.props.title}
          </div>

          <div>
            <IconButton size = {"small"}
              className={ this.props.theme === "dark" ? "openAccordionToggleDark" : "openAccordionToggleLight"}
              onClick={this.onDropdownClicked}
            >
              { this.state.isDropdownOpen ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
            </IconButton>
          </div>
        </div>
        <Collapse isOpened={this.state.isDropdownOpen}>
          <div className={"displayText"}>{this.props.children}</div>
        </Collapse>
      </div>
    );
  }
}

export default DropdownItem;

