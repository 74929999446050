import React from 'react'

import { Dialog, IconButton, AppBar, Typography, Toolbar } from '@material-ui/core';
import MultiStep from './MultiStep';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { withContext } from '../../../../context';
import {Progress} from "../../../../Components";

const useStyles = makeStyles(theme => ({
  fullScreen: { background : 'linear-gradient(#1e1e2f,#1e1e24)' },
}));

const CreateDialog = ({ open, id , handleDeleteClick, setOpen , loading ,path}) => {

  const classes = useStyles();  

  return <>
   { loading ? <Progress open={true} /> :
      <Dialog  classes={{ paperFullScreen: classes.fullScreen }} open={open}  fullScreen>
        <AppBar position="static" >
          <Toolbar variant='dense'>
            <Typography style={{ flex: 1 }} variant="h6" noWrap >
              Adding a Model...
                  </Typography>
          
              <IconButton onClick={(e) => { id === '000000000000000000000000' ? setOpen(false) : handleDeleteClick(id, e) }} >
                <CloseIcon fontSize='small' /> </IconButton>
              
          </Toolbar>
        </AppBar>

        <MultiStep />

      </Dialog>
}
</>


}
export default withContext(CreateDialog)

