import React from 'react';
import { ListItemText } from '@material-ui/core';
import { withContext } from '../../../../../../context';
import { Row, Col } from "reactstrap";
import { SelectForm, InputForm } from "../../../../../../Components";

const FeatureSelection = ({ state, setState }) => {

    const FitMethodFSOptions = [
        {
            label: "exact",
            value: "exact"
        },
        {
            label: "non",
            value: "non"
        },
        {
            label: "average",
            value: "average"
        },
    ];
    const SolverFSOptions = [
        {
            label: "lbfgs",
            value: "lbfgs"
        },
        {
            label: "sgd",
            value: "sgd"
        },
        {
            label: "minibatch-lbfgs",
            value: "minibatch-lbfgs"
        },
    ];
    const LossFunctionFSOptions = [
        {
            label: "mad",
            value: "mad"
        },
        {
            label: "mse",
            value: "mse"
        },
        {
            label: "epsiloninsensitive",
            value: "epsiloninsensitive"
        },
    ];

    return  <>

        <Row><Col><ListItemText primary='Select your parameters'/> </Col></Row>

        <Row style={{ margin: 10 }}>
            <Col lg="4">
                <label htmlFor="Lambda">Lambda</label>
                <InputForm setState={setState} stateName={state} stateParam={"Lambda"} title={"Must be number between 0 and 1"} type={"number"} min = {0} max = {1} step = {0.1}  />
            </Col>

            <Col lg="4">
                <label htmlFor="FitMethodFS">Fit Method</label>
                <SelectForm setState = {setState} stateName={state} stateParam = {"FitMethodFS"} options={FitMethodFSOptions}/>
            </Col>
        </Row>

        <Row style={{ margin: 10 }}>
            <Col lg="4">
                <label htmlFor="SolverFS">Solver</label>
                <SelectForm setState = {setState} stateName={state} stateParam = {"SolverFS"} options={SolverFSOptions}/>
            </Col>


            <Col lg="4">
                <label htmlFor="LossFunctionFS">Loss Function</label>
                <SelectForm setState = {setState} stateName={state} stateParam = {"LossFunctionFS"} options={LossFunctionFSOptions}/>
            </Col>
        </Row>

        <Row style={{ margin: 10 }}>
            <Col lg="4">
                <label htmlFor="Threshold">Threshold</label>
                <InputForm setState={setState} stateName={state} stateParam={"Threshold"} title={"Must be number between 0 and 1"} type={"number"} min = {0} max = {1} step = {0.1}  />
            </Col>

            <Col lg="4">
                <label htmlFor="n">Number of markers</label>
                <InputForm setState={setState} stateName={state} stateParam={"N"} title={`Must be number between 0 and ${state.MarkerNames.length}`} type={"number"} min = {0} max = {state.MarkerNames.length} step = {1}/>
            </Col>
        </Row>
    </>

}
export default withContext(FeatureSelection)