import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import axios from "axios";
import { getToken } from "../../Utils/common";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import UpdateIcon from '@material-ui/icons/Update';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import { Card, CardActions, CardContent, CardHeader, Checkbox, DialogTitle, FormControlLabel, Grid } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.beemetrix.com/">
                Beemetrix
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function RoleList({ url }) {
    var path = url;
    const [error, setError] = React.useState(null);
    const [newrow, setadd] = React.useState({
        id: '',
        add: true
    });

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [values, setValues] = React.useState({
        nameRole: '',
        descriptionRole: '',
        readModel: false,
        readUser: false,
        updateModel: false,
        updateUser: false,
        deleteModel: false,
        deleteUser: false,
        allModel: false,
        allUser: false,
        addModel: false,
        addUser: false,
        updateAllModel: false,
        deleteALLModel: false,
        sharemodel: false
    })

    const handleClickOpen = () => {
        setValues({
            nameRole: '',
            descriptionRole: '',
            readModel: false,
            readUser: false,
            updateModel: false,
            updateUser: false,
            deleteModel: false,
            deleteUser: false,
            allModel: false,
            allUser: false,
            addModel: false,
            addUser: false,
            sharemodel: false
        });

        setadd(prevState => { const add = true; return { ...prevState, add } });

        setOpen(true);
    };

    const handleupdateOpen = () => {
        setadd(prevState => {
            const add = false;
            return { ...prevState, add }
        });
        setOpen(true);
    };

    const handleNameRole = (e) => {

        setValues(prevState => {
            const nameRole = e.target.value;
            return { ...prevState, nameRole }
        });
    };

    const handleDescriptionRole = (e) => {

        setValues(prevState => {
            const descriptionRole = e.target.value;
            return { ...prevState, descriptionRole }
        });
    };

    const handleAddModel = (e) => {
        console.log(e.target.checked);
        setValues(prevState => {
            const addModel = e.target.checked;
            return { ...prevState, addModel }
        });
    };

    const handleAddUser = (e) => {
        setValues(prevState => {
            const addUser = e.target.checked;
            return { ...prevState, addUser }
        });
    };

    const handledeleteUser = (e) => {
        setValues(prevState => {
            const deleteUser = e.target.checked;
            return { ...prevState, deleteUser }
        });
    };

    const handledeleteModel = (e) => {
        setValues(prevState => {
            const deleteModel = e.target.checked;
            return { ...prevState, deleteModel }
        });
    };

    const handleshareModel = (e) => {
        setValues(prevState => {
            const sharemodel = e.target.checked;
            return { ...prevState, sharemodel }
        });
    };

    const handledeleteModels = (e) => {
        setValues(prevState => {
            const deleteAllModel = e.target.checked;
            return { ...prevState, deleteAllModel }
        });
    };

    const handlereadModel = (e) => {
        setValues(prevState => {
            const readModel = e.target.checked;
            return { ...prevState, readModel }
        });
    };

    const handlereadUser = (e) => {
        setValues(prevState => {
            const readUser = e.target.checked;
            return { ...prevState, readUser }
        });
    };

    const handleAllModels = (e) => {
        setValues(prevState => {
            const allModel = e.target.checked;
            return { ...prevState, allModel }
        });
    };

    const handleUpdateUser = (e) => {
        setValues(prevState => {
            const updateUser = e.target.checked;
            return { ...prevState, updateUser }
        });
    };

    const handleUpdateModel = (e) => {
        setValues(prevState => {
            const updateModel = e.target.checked;
            return { ...prevState, updateModel }
        });
    };

    const handleUpdateModels = (e) => {
        setValues(prevState => {
            const updateAllModel = e.target.checked;
            return { ...prevState, updateAllModel }
        });
    };

    const handleSendData = () => {
        if (newrow.add) {
            sendData(values);
        }
        else {
            updateData(values, newrow.id);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [state, setState] = React.useState({
        columns: [
            {
                title: 'Name', field: 'name', editComponent: props => (
                    <input
                        type="text"
                        value={props.value}
                        onChange={e => props.onChange(e.target.value)}
                    />
                )
            },
            { title: 'Description', field: 'description' },
        ],
        data: [],
    });

    async function getroles() {
        return await axios({
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() },
            method: "get",
            url: path + '/roles',
        }).then(res => res.data);
    }

    function sendData(data) {
        new Promise(resolve => {
            axios.post(path + '/addRole/', data, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() } }).then(() => {
                getroles().then(res => {
                    setTimeout(() => {
                        resolve();
                        setState(prevState => {
                            const data = res;

                            return { ...prevState, data };
                        });
                        handleClose()
                    }, 600);
                });
            }).catch(error => {
                if (error.response.status === 401) setError(" error ");
            });
        })
    }

    function updateData(data, id) {
        new Promise(resolve => {
            axios.post(path + '/updateRole/' + id, data, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() } }).then(() => {
                getroles().then(res => {
                    setTimeout(() => {
                        resolve();
                        setState(prevState => {
                            const data = res;

                            return { ...prevState, data };
                        });
                        handleClose()
                    }, 600);
                });
            }).catch(error => {
                if (error.response.status === 401) setError(" error ");
            });
        })
    }

    getroles().then(res => {
        if (state.data.length === 0) {
            setState(prevState => {
                const data = res;
                return { ...prevState, data }
            })
        }
    });

    return (
        <div>
            <CssBaseline />
            <main>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid item xs={12}>
                        <MaterialTable style = {{background: "#27293d"}}
                            title={error}
                            columns={state.columns}
                            data={state.data}
                            editable={{
                                onRowDelete: oldData =>
                                    new Promise(resolve => {
                                        axios.post(path + '/deleteRole/' + oldData._id, '', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() } }).then(() => {
                                            getroles().then(res => {
                                                setTimeout(() => {
                                                    resolve();
                                                    setState(prevState => {
                                                        const data = res;
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            });
                                        }).catch(error => {
                                            if (error.response.status === 401) setError(" error deleting ");
                                        });
                                    }),
                            }}
                            options={{
                                headerStyle : {background:'#272935'},
                                exportButton: true,
                                selection: true
                            }}

                            onSelectionChange={(rows) => {
                                console.log(rows);
                                if (rows.length === 1) {
                                    setadd(prevState => {
                                        const id = rows[0]._id;
                                        return { ...prevState, id }
                                    });
                                    setValues(rows[0].permission);
                                    setValues(prevState => {
                                        const nameRole = rows[0].name;
                                        return { ...prevState, nameRole }
                                    });
                                    setValues(prevState => {
                                        const descriptionRole = rows[0].description;
                                        return { ...prevState, descriptionRole }
                                    });
                                    setadd(prevState => {
                                        const add = false;
                                        return { ...prevState, add }
                                    });
                                } else if (rows.length === 0) {

                                    setadd(prevState => {
                                        const add = true;
                                        return { ...prevState, add }
                                    });
                                }
                            }}
                            components={{
                                Toolbar: props => (
                                    <div>
                                        <MTableToolbar {...props} />
                                        <div style={{ padding: '0px 10px' }}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                className={classes.button}
                                                startIcon={<AddIcon />}
                                                onClick={handleClickOpen}
                                                disabled={!newrow.add}
                                            >
                                                Add
                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                className={classes.button}
                                                startIcon={<UpdateIcon />}
                                                onClick={handleupdateOpen}
                                            >
                                                Update
                            </Button>
                                        </div>
                                    </div>
                                ),
                            }}
                        />
                        <div>
                            <Dialog style = {{background : "#27293d"}} open={open} onClose={handleClose} TransitionComponent={Transition}>
                            
                            <DialogTitle style = {{background : "#27293d"}} disableTypography > Role</DialogTitle>
                              
                                <DialogContent style = {{background : "#27293d"}} >
                                    <Card style = {{background : "#27293d"}}  >
                                        <form >
                                            <Grid container item xs={12} >
                                                <TextField
                                                    label="Name"
                                                    type="text"
                                                    fullWidth
                                                    value={values.nameRole}
                                                    onChange={handleNameRole}
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid container item xs={12} >
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="Description"
                                                    multiline
                                                    rows={4}

                                                    value={values.descriptionRole}
                                                    onChange={handleDescriptionRole}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <CardHeader
                                                subheader="Manage the permission"
                                                title="Permission"
                                            />
                                            <Divider />
                                            <CardContent>
                                                <Grid
                                                    container
                                                    spacing={6}
                                                    wrap="wrap"
                                                >
                                                    <Grid className={classes.item} item md={4} sm={6} xs={12} >
                                                        <Typography>Your Model </Typography>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={values.readModel}
                                                                    onChange={handlereadModel}
                                                                />

                                                            }
                                                            label="Read"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color="primary"

                                                                    checked={values.addModel}
                                                                />
                                                            }
                                                            label="Add"
                                                            onChange={handleAddModel}
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox color="primary" checked={values.updateModel} />}
                                                            label="Update" onChange={handleUpdateModel}
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={values.deleteModel}

                                                                />
                                                            }
                                                            label="Delete"
                                                            onChange={handledeleteModel}
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={values.sharemodel}

                                                                />
                                                            }
                                                            label="Share"
                                                            onChange={handleshareModel}
                                                        />

                                                    </Grid>
                                                    <Grid
                                                        className={classes.item}
                                                        item
                                                        md={4}
                                                        sm={6}
                                                        xs={12}
                                                    >
                                                        <Typography>Shared Models</Typography>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color="primary"


                                                                    checked={values.allModel}
                                                                    onChange={handleAllModels}
                                                                />

                                                            }
                                                            label="Read"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color="primary"

                                                                    checked={values.updateAllModel}
                                                                />
                                                            }
                                                            label="Update"
                                                            onChange={handleUpdateModels}
                                                        />

                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={values.deleteALLModel}

                                                                />
                                                            }
                                                            label="Delete"
                                                            onChange={handledeleteModels}
                                                        />

                                                    </Grid>
                                                    <Grid
                                                        className={classes.item}
                                                        item
                                                        md={4}
                                                        sm={6}
                                                        xs={12}
                                                    >
                                                        <Typography>Users</Typography>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={values.readUser} />
                                                            }

                                                            label="Read"
                                                            onChange={handlereadUser}
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox color="primary"
                                                                checked={values.updateUser}
                                                            />}
                                                            label="update"
                                                            onChange={handleUpdateUser}
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color="primary"

                                                                    checked={values.deleteUser}
                                                                />
                                                            }
                                                            label="delete"
                                                            onChange={handledeleteUser}
                                                        />

                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color="primary"

                                                                    checked={values.addUser}
                                                                />
                                                            }
                                                            label="add"
                                                            onChange={handleAddUser}
                                                        />

                                                    </Grid>

                                                </Grid>
                                            </CardContent>
                                            <Divider />
                                            <CardActions>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    onClick={handleSendData}
                                                >
                                                    Save
                        </Button>
                                            </CardActions>
                                        </form>
                                    </Card>
                                </DialogContent>
                            </Dialog>
                        </div>
                    </Grid>


                    <Box pt={4}>
                    </Box>
                </Container>
            </main>
        </div>
    );
}
