import React, { useState } from 'react';
import { Collapse } from "reactstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
const Normalization = ({ Model, state, setState, open, handelClose }) => {

    const
        [edit, setEdit] = useState(false),
        handleEdit = () => setEdit(true),
        NormalizerOptions =
            [
                {
                    label: "Scaling",
                    value: "scaling"
                },
                {
                    label: "Standarize",
                    value: "standarize",
                    isDisabled: state.markerNamesInt.length !== 0
                },
                {
                    label: "Quantile",
                    value: "quantile",
                    isDisabled: state.markerNamesInt.length !== 0
                },
            ];

    const onListClick = (e) => setState(prevState => ({ ...prevState, normalizer: NormalizerOptions.find(option => option.value === e.currentTarget.dataset.value).value }));

    const handelCancel = () => {
        setState(prevState => ({ ...prevState, normalizer: NormalizerOptions.find(option => option.value === Model.normalizer).value }));
        handelClose();
    };

    return (
        <>
            <Dialog open={open} onClose={handelClose} aria-labelledby="form-dialog-title">


                <DialogTitle style={{ background: '#27293d' }} id="form-dialog-title">Normalization Technique</DialogTitle>
                <DialogContent className="container" dividers style={{ background: '#27293d' }}>
                    <div className="row">
                        <div className="col-6">
                            <p style={{ fontSize: 18 }}> {NormalizerOptions.find(option => option.value === Model.normalizer).label}</p>
                        </div>
                        <div className="col-6">
                            <IconButton size={"small"} className='float-right' onClick={handleEdit}>
                                <EditIcon />
                            </IconButton>
                        </div>
                    </div>
                </DialogContent>
                <Collapse style={{ background: '#27293d' }} isOpen={edit}>
                    <DialogTitle disableTypography> Select new normalization technique </DialogTitle>
                    <List dense>
                        {
                            NormalizerOptions.filter(ele => ele.value !== Model.normalizer)
                                .map(ele => <ListItem selected={ele.value === state.normalizer} data-value={ele.value} dense onClick={onListClick} key={ele.value} button>
                                    <DialogTitle value={ele.value} disableTypography>{ele.label}  </DialogTitle> </ListItem>)
                        }
                    </List>
                </Collapse>
                <Divider />
                <DialogActions style={{ background: '#27293d' }}>
                    <Button onClick={handelCancel} color="primary"> Cancel </Button>
                    <Button onClick={handelClose} color="primary"> Save </Button>
                </DialogActions>

            </Dialog>
        </>

    );
}

export default Normalization;