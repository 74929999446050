import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withContext } from '../../../../../../../context'


const useStyles = makeStyles(() => ({
    nested: { margin: '10px 10px 10px 10px' , maxWidth : 400 }
}));

const Summary = ({ state }) => {
    const classes = useStyles();
    const options2 = [
        {
            label: "FUZZY BINOMIAL",
            value: "FUZZY_BINOMIAL"
        },
        {
            label: "FUZZY CENTERED BINOMIAL",
            value: "FUZZY_CENTERED_BINOMIAL"
        },
        {
            label: "NORMAL GAUSSIAN",
            value: "NORMAL_GAUSSIAN"
        },
    ];

    return <>
        <List component="div" disablePadding>
            <ListItem variant='dense' className={classes.nested} >
                <ListItemText disableTypography primary='Exigency Index ' />
                {state.ExegencyLevel}
            </ListItem>


            <ListItem dense className={classes.nested} >
                <ListItemText disableTypography primary=' Connective ' />
                {state.Connective}
            </ListItem>

            <ListItem dense className={classes.nested} >
                <ListItemText disableTypography primary='Membership Function ' />
                { options2.find(obj => obj.value === state.LamdaFunction).label }
            </ListItem>

            <ListItem dense className={classes.nested} >
                <ListItemText disableTypography primary='Number of Clusters '/> 
                {state.ClassCount}
                                    </ListItem>


            <ListItem dense className={classes.nested}>
                <ListItemText disableTypography primary='Number of Features '/> {state.MarkerNames.length}
                                    </ListItem>


            <ListItem dense className={classes.nested}>
                <ListItemText disableTypography primary='Number of Individuals '/> {state.Data.length - 1 }
                                    </ListItem>

             <ListItem dense className={classes.nested}>
                <ListItemText disableTypography primary='GAD-NIC '/> {state.GadNic}
                                    </ListItem>                        


        </List>
    </>
}
export default withContext(Summary);




