import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { SearchInput } from '../../../../Components';

const useStyles = makeStyles(theme => ({
  row: {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    [theme.breakpoints.down('sm')]: { display: 'inline-block' },
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1,
  },
  addButton: {
    background :  "#27293d",
    width: 120,
    [theme.breakpoints.down('sm')]: { marginTop: 5, width: '48%' },
  },
  searchInput: {
    background :  "#27293d",
    marginRight: theme.spacing(1),
  }
}));

const ModelToolbar = props => {
  const { className, history, setSearch , setDraft, draft,setOpen , path,...rest } = props;

  const classes = useStyles();

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearch(value)
  };

  const handleDraft = () => setDraft(!draft)

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>

        <SearchInput
          className={classes.searchInput}
          placeholder="Search model"
          onChange={handleSearchChange}
        />
        <span className={classes.spacer} />
        <Button style = {{marginRight : 25}} color="primary" variant="contained" className={classes.addButton} onClick={handleDraft}> {draft ? 'Models' : 'Drafts'} </Button>
        <Button color="primary" variant="contained" className={classes.addButton}
          onClick={() => { setOpen(true) ; } // history.push('/add-model')
        }>
          Add model
        </Button>
      </div>

    </div>
  );
};

ModelToolbar.propTypes = {
  className: PropTypes.string
};

export default ModelToolbar;
