import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { AccountProfile, AccountDetails } from './components';
import CssBaseline from "@material-ui/core/CssBaseline";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4) ,
  }
}));

const Account = ({ url}) => {
  const classes = useStyles();

  return (

    <div className={classes.root}>
      <CssBaseline />
      <Grid container spacing={4} >
        <Grid item lg={4} md={6} xl={4} xs={12} >
          <AccountProfile url={url}  style = {{background: "#27293d"}} />
        </Grid>
        <Grid item lg={8} md={6} xl={8} xs={12} >
          <AccountDetails url={url}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default Account;
