import React, { useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';


const AlertExample = ({ alert, setAlert }) => {

    const onClose = () => setAlert({ alert: false, message: "" , severity : alert.severity});

    return (
        <Snackbar
            open={alert.alert}
            // autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <MuiAlert elevation={6} variant="filled" onClose={onClose} severity={alert.severity}>
                {alert.message}
            </MuiAlert>
      </Snackbar>
    );
}

export default AlertExample;
