import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { getToken, getUser, setUserSession, getRole } from '../../../../Utils/common';
import axios from "axios";
import { makeStyles } from '@material-ui/styles';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountDetails = props => {
  const { className, url, ...rest } = props;
  let path = url;
  const classes = useStyles();


  const [name, setName] = useState(getUser().name)
  const [email, setEmail] = useState(getUser().email)
  const [username, setUsername] = useState(getUser().username)
  const [phone, setPhone] = useState(getUser().tel)

  const handleupdate = () => {
    new Promise(() => {
      axios.post(path + '/updateUser/' + getUser()._id, { name: name, username: username, email: email, tel: phone }, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() } }).then(() => {
        const user = getUser();
        user.name = name;
        user.username = username
        user.email = email
        user.tel = phone
        setUserSession(getToken(), user, getRole())
        handleClick()
      }).catch(error => {
        if (error.response.status === 401) alert("ok");
      });
    })

  }
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          info updated with success
        </Alert>
      </Snackbar>
      <form autoComplete="off" noValidate style = {{background: "#27293d"}} >
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <>
          <CardContent>
            <Grid container spacing={3} >
              <Grid item md={6} xs={12}>
                <TextField
                  label="Name"
                  type="text"
                  fullWidth
                  value={name}
                  onChange={event => setName(event.target.value)}
                  id="outlined-basic"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12} >
                <TextField
                  fullWidth
                  label="Username"
                  name="lastName"
                  value={username}
                  onChange={event => setUsername(event.target.value)}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12} >
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  onChange={event => setEmail(event.target.value)}
                  required
                  value={email}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phone"
                  onChange={event => setPhone(event.target.value)}
                  value={phone}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
        </>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleupdate}
          >
            Save details
          </Button>
        </CardActions>
      </form>


    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
