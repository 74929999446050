/**
    import React  from "react";
    import _ from 'underscore';
    import MaterialTable from "material-table";
    const DataTable = ({matrix}) => {
    const columns = (matrix[0])
    .map((item) => ({
        title: item,
        field: item,
    }));
    const rows = (matrix.slice(1).map( (row)  =>  _.object(matrix[0], row) ));
    return  <>
    <MaterialTable
        data={rows}
        columns={columns}
        options={{ draggable: false, selection: false, sorting:false  }}
        actions={[]}
    />
    </>
}
export default DataTable


import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { AutoSizer, Column, Table } from 'react-virtualized';
import _ from 'underscore';

const styles = (theme) => ({
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    table: {
        // temporary right-to-left patch, waiting for
        // https://github.com/bvaughn/react-virtualized/issues/454
        '& .ReactVirtualized__Table__headerRow': {
            flip: false,
            paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
        },
    },
    tableRow: {
        cursor: 'pointer',
    },
    tableRowHover: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    tableCell: {
        flex: 1,
    },
    noClick: {
        cursor: 'initial',
    },
});

class MuiVirtualizedTable extends React.PureComponent {
    static defaultProps = {
        headerHeight: 48,
        rowHeight: 48,
    };

    getRowClassName = ({ index }) => {
        const { classes, onRowClick } = this.props;

        return clsx(classes.tableRow, classes.flexContainer, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        });
    };

    cellRenderer = ({ cellData, columnIndex }) => {
        const { columns, classes, rowHeight, onRowClick } = this.props;
        return (
            <TableCell
                component="div"
                className={clsx(classes.tableCell, classes.flexContainer, {
                    [classes.noClick]: onRowClick == null,
                })}
                variant="body"
                style={{ height: rowHeight }}
                align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
            >
                {cellData}
            </TableCell>
        );
    };

    headerRenderer = ({ label, columnIndex }) => {
        const { headerHeight, columns, classes } = this.props;

        return (
            <TableCell
                component="div"
                className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
                variant="head"
                style={{ height: headerHeight }}
                align={columns[columnIndex].numeric || false ? 'right' : 'left'}
            >
                <span>{label}</span>
            </TableCell>
        );
    };

    render() {
        const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
        return (
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        height={height}
                        width={width}
                        rowHeight={rowHeight}
                        gridStyle={{
                            direction: 'inherit',
                        }}
                        headerHeight={headerHeight}
                        className={classes.table}
                        {...tableProps}
                        rowClassName={this.getRowClassName}
                    >
                        {columns.map(({ dataKey, ...other }, index) => {
                            return (
                                <Column
                                    key={dataKey}
                                    headerRenderer={(headerProps) =>
                                        this.headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                        })
                                    }
                                    className={classes.flexContainer}
                                    cellRenderer={this.cellRenderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            );
                        })}
                    </Table>
                )}
            </AutoSizer>
        );
    }
}

MuiVirtualizedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataKey: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            numeric: PropTypes.bool,
            width: PropTypes.number.isRequired,
        }),
    ).isRequired,
    headerHeight: PropTypes.number,
    onRowClick: PropTypes.func,
    rowHeight: PropTypes.number,
};

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

// ---

export default function ReactVirtualizedTable({matrix}) {

    const columns = (matrix[0])
        .map((item) => ({
            width : 200 ,
            label: item,
            dataKey: item,
        }));

    const rows = (matrix.slice(1).map( (row)  =>  _.object(matrix[0], row) ));

    return (
        <Paper style={{ height: 400, width: '100%' }}>
            <VirtualizedTable
                rowCount={rows.length}
                rowGetter={({ index }) => rows[index]}
                columns={columns}
            />
        </Paper>
    );
}

import React  from "react";
import MUIDataTable from "mui-datatables";

export default function Table({matrix}) {

    const columns = matrix[0];

    const data = matrix.slice(1);

    const options = {
      sortable : false ,
        searchable : true ,
    };
return <MUIDataTable
        title={"Employee List"}
        data={data}
        columns={columns}
        options={options}
    />
}
**/

import React  from "react";
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';
import _ from 'underscore';

 function DataTable({matrix}) {

    const tableRef = React.createRef();
    const rows = (matrix.slice(1).map( (row)  =>  _.object(matrix[0], row) ));

    const columns = (matrix[0]).map((item) =>  {
        return  <Column width={100} resizable>
                    <HeaderCell>{item}</HeaderCell>
                        <Cell dataKey={item} />
                </Column>
    });

     const dataList = [
         { id: 1, name: 'a', email: 'a@email.com', avartar: '...' },
         { id: 2, name: 'b', email: 'b@email.com', avartar: '...' },
         { id: 3, name: 'c', email: 'c@email.com', avartar: '...' }
     ];

     console.log(columns);
     console.log(rows);
     console.log(dataList);

return <Table data={rows}
              virtualized
              height={400}
              ref={tableRef}
              onRowClick={data => { console.log(data);}}
        >
         {columns}


        </Table>
}
export default DataTable;