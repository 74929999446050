import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import _ from 'underscore'
import { Row, Col } from "reactstrap";
import MatrixView from "../../Dashboard/components/AddModel/Steps/Components/Matrix_view"
import { BarChart } from "../../../Components";


const mergeWith = (arr1, arr2) =>  arr1.map( (item , index ) => [ index + 1 , item , arr2[index] ]);

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: 0,
    padding: 10,
    height: '67vh',
    background: "#27293d",
    marginTop: 10
  },
}));

const WeightTable = ({ Model }) => {

  const classes = useStyles();

  let markerNames = Model.markerNames;
  let selectedHeader = Model.selectedHeader;
  let rankedWeights = Model.RankedWeights;
  let rankedMarkers = Model.RankedMarkers;

  let ranked_markers_limited = rankedMarkers.slice(0, selectedHeader.length);
  let ranked_weights_limited = rankedWeights.slice(0, selectedHeader.length);
  let ranked_matrix = [['Rank', 'Feature', 'Weight'], ...mergeWith(ranked_markers_limited , ranked_weights_limited)];
  let labels = _.without(markerNames, ..._.without(markerNames, ...ranked_markers_limited));

  console.log(markerNames)
  console.log(selectedHeader)
  console.log(markerNames)
  console.log(rankedWeights)
  console.log(rankedMarkers)

  return <Row style={{ margin : "auto" }}>
    <Col lg={4}>
      <Paper className={classes.paper}>
        <MatrixView
          matrix={ranked_matrix}
          height={"58vh"}
          type="ranked"
          name="Ranked Markers Selected"
        />
      </Paper>
    </Col>

    <Col lg={8}>
      <Paper className={classes.paper}>
        <div style={{ height: '90%' }}>
          <BarChart name="ranked" labels={labels} data={ranked_markers_limited.map((v, i) => rankedWeights[ranked_markers_limited.indexOf(labels[i])])} />
        </div>
      </Paper>

    </Col>
  </Row>
}
export default WeightTable;




