import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Topbar } from './components';
import CssBaseline from '@material-ui/core/CssBaseline';


const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 64,
    height: '100%'
  },
  content: {
    height: '100%'
  }
}));

const Add = props => {
  const { children, history, id , setDeleted_id, setOpenDelete } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Topbar history={history}
        setDeleted_id={setDeleted_id}
        setOpenDelete={setOpenDelete}
        id={id}
      />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Add.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Add;
