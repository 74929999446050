import React from 'react';
import _ from "underscore";
import helpers from "../../../../api";


export const
  customStyles = {

    control: (base, state) => ({
      ...base,
      backgroundColor: '#1e1e2f',
      color: 'white',
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      borderColor: state.isFocused ? "blue" : "white",
      boxShadow: state.isFocused ? null : null,
    }),

    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),

    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? 'grey' : 'white'
    }),

    menu: base => ({
      ...base,
      background: '#1e1e2f',
    }),

    menuList: base => ({
      ...base,
      background: '#212121',
    })
  },

  getOptions = (MarkerNamesNum, input) => {
    let options = [];
    let array_options = _.without(MarkerNamesNum, ...input);
    for (let i = 0; i < array_options.length; i++)
      options.push(_.object(['label', 'value'], [array_options[i], array_options[i]]))
    return options
  },

  CheckNamesTypes = (data, index) => {

    let numIndex = [], intIndex = [], txtIndex = [];

    for (let j = 1; j < data[0].length; j++) {
      let hasNum = false, hasInt = false, hasTxt = false;
      if (j === index) continue;
      for (let i = 1; i < data.length; i++) {
        if ((/^\[(\d*\.?\d*);(\d*\.?\d+)\]|\[(\d*\.?\d*),(\d*\.?\d+)\]$/).test(data[i][j])) hasInt = true;
        else if (!isNaN(data[i][j])) hasNum = true;
        else hasTxt = true;
      }
      if (hasTxt) txtIndex.push(j);
      else if (hasInt) intIndex.push(j);
      else numIndex.push(j);
    }

    return { txtIndex: txtIndex, intIndex: intIndex, numIndex: numIndex }
  },

  getMatrixData = (data, index, filtered) => {

    let indexes = CheckNamesTypes(data, index);
    let txtIndex = indexes.txtIndex, intIndex = indexes.intIndex, numIndex = indexes.numIndex;

    let Matrix = [], MatrixInt = [], MatrixTxt = [];
    let nbrMarkerNames = [], intMarkerNames = [], txtMarkerNames = [];

    for (let i = 1, len = data.length; i < len; i++) {
      let row = [], rowInt = [], rowTxt = [];
      for (let j = 1; j < data[0].length; j++) {
        if (j === index || filtered.includes(j)) continue;

        if (intIndex.includes(j)) {
          if (i === 1) intMarkerNames.push(data[0][j])
          let temp = data[i][j].match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g).map(Number)
          rowInt.push(...temp)
        }

        else if (numIndex.includes(j)) {
          row.push(parseFloat(data[i][j]));
          if (i === 1) nbrMarkerNames.push(data[0][j])
        }

        else {
          rowTxt.push(data[i][j]);
          if (i === 1) txtMarkerNames.push(data[0][j])
        }
      }
      if (rowInt.length !== 0) MatrixInt.push(rowInt)
      if (rowTxt.length !== 0) MatrixTxt.push(rowTxt)
      if (row.length !== 0) Matrix.push(row)
    }

    return {
      NumData: Matrix, TextData: MatrixTxt, IntData: MatrixInt, MarkerNamesNum: nbrMarkerNames,
      MarkerNamesInt: intMarkerNames, MarkerNamesTxt: txtMarkerNames
    }
  },

  getMarkerNames = (data, index, filtered) => {
    let MarkerNames = [];
    for (let i = 1; i < data[0].length; i++) {
      if (i === index || filtered.includes(i)) continue;
      MarkerNames.push(data[0][i])
    }
    return MarkerNames
  },

  getRowNames = (data) => {
    let RawNames = [];
    for (let i = 1; i < data.length; i++)  RawNames.push(data[i][0])
    return RawNames
  },

  addHeader = (data) => {
    let header = [];
    for (let i = 0, len = data[0].length; i < len; i++) header.push(`V${i}`)
    return header
  },

  addRawNames = (data, setState) => {
    for (let i = 0, len = data.length; i < len; i++) if (i === 0) data[i].unshift('-');
    else data[i].unshift(`Row ${i}`); setState(prevState => ({ ...prevState, TableHeader: true, TableLabels: true }));
  },

  HandleData = (setState, data, TableHeader, TableLabels) => {
    if (!TableHeader) data.unshift(addHeader(data));
    if (!TableLabels) addRawNames(data, setState);
  },

  live_options = (type) => {
    return {
      downsample: {
        enabled: true,
        threshold: (400),
        auto: false,
        onInit: true,
        preferOriginalData: true,
        restoreOriginalData: false,
      },
      hover: { mode: null },
      title: { display: false },
      scales: {
        xAxes: [{
          scaleLabel: { display: false }, type: type, minRotation: 45,
          ticks: {
            callback: function (value, index, values) {
              return type === "logarithmic" ? Number(value.toString()) : value;
            }
          },
        }],
        yAxes: [{ scaleLabel: { display: false } }]
      },
      legend: { display: false },
      tooltips: { enabled: false },
      animation: { duration: 0 },
      responsiveAnimationDuration: 0,
      line: { tension: 0 }
    }
  },

  exoptions = (filename) => {
    return {
      filename: filename,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    }
  },

  getSelecteddata = (state) => {
    let selVar = [];
    for (let i = 1; i < state.NormalizedNumData.length; i++) {
      var row = [];
      for (let j = 0; j < state.NbrOfVariables; j++)
        if (state.MarkerNamesNum.includes(state.RankedMarkers[j]))
          row.push(state.NormalizedNumData[i][state.MarkerNamesNum.indexOf(state.RankedMarkers[j])])
      selVar.push(row)
    }
    return selVar
  },

  addName = (history, state, setState, handleNext, setAlert) => {
    const body = { name: state.Name, id: state.Id };
    helpers.addName(body).then(
      (response) => {
        setState(prevState => ({ ...prevState, Id: response.data.id }));
        handleNext();
      }
    ).catch((err) => {
      err.response.status === 409 ?
        setAlert({ alert: true, message: "Name Alraedy Exist , Please Enter a New Name", severity: "error" }) :
        err.response.status === 401 ?
        helpers.tokenExpirationAlert(history) :
          setAlert({ alert: true, message: 'Error occured , please try again', severity: "error" });
    });
  },

  addProblemType = (history, state, handleNext, setAlert, setProg) => {
    const body = { problemType: state.ProblemType, id: state.Id };
    helpers.addProblemType(body).then((response) => handleNext())
      .catch((err) => {
        console.log(err);
        err.response.status === 401 ?
        helpers.tokenExpirationAlert(history) :
          setAlert({ alert: true, message: 'Error occured , please try again', severity: "error" });
      });
    setProg(false);
  },

  handelNewData = (history, state, setState, isExist, setAlert, setLoading, setDisableHeader, handleNext, file) => {

    if (isExist) {
      setState(prevState => ({ ...prevState, RowNames: getRowNames(state.Data) }));
      handleNext();
    }
    else {

      setLoading(true);

      HandleData(setState, state.Data, state.TableHeader, state.TableLabels);
      setState(prevState => ({ ...prevState, RowNames: getRowNames(state.Data) }));
      setDisableHeader(true);

      let body = { id: state.Id, initialData: state.Data, fileName: file };
      helpers.uploadFile(body).then((response) => {
        handleNext();
        setDisableHeader(true);
        setLoading(false);
      }
      ).catch((err) => {
        err.response.status === 401 ?
        helpers.tokenExpirationAlert(history) :
          setAlert({ alert: true, message: 'Error occured , please try again', severity: "error" });
        setLoading(false);
      });
    }
  },

  addData = (history, state, setState, handleNext, setAlert, setLoading) => {
    setLoading(true);
    const
      MatrixData = getMatrixData(state.Data, state.Index, state.Filtered ? state.Filtered : []),
      MarkerNames = getMarkerNames(state.Data, state.Index, state.Filtered ? state.Filtered : []),
      RowNames = getRowNames(state.Data);

    setState(prevState => ({
      ...prevState,
      MarkerNames: MarkerNames,
      NumData: MatrixData.NumData,
      TextData: MatrixData.TextData,
      IntData: MatrixData.IntData,
      MarkerNamesNum: MatrixData.MarkerNamesNum,
      MarkerNamesTxt: MatrixData.MarkerNamesTxt,
      MarkerNamesInt: MatrixData.MarkerNamesInt,
      RowNames: RowNames
    }));

    const body = {
      id: state.Id,
      MarkerNames: MarkerNames,
      NumData: MatrixData.NumData,
      TextData: MatrixData.TextData,
      IntData: MatrixData.IntData,
      MarkerNamesNum: MatrixData.MarkerNamesNum,
      MarkerNamesTxt: MatrixData.MarkerNamesTxt,
      MarkerNamesInt: MatrixData.MarkerNamesInt,
      Index: state.Index,
      Target: state.Target,
      RowNames: RowNames
    };

    helpers.addData(body).then((response) => {
      handleNext();
      setLoading(false);
    })
      .catch((err) => {
        console.log(err);
        err.response.status === 401 ?
        helpers.tokenExpirationAlert(history) :
          setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
        setLoading(false);
      });
  },

  normalize = (history, state, setState, handleNext, setAlert, setLoading) => {

    setLoading(true);
    const body = { normalizer: state.Normalizer };

    helpers.normalize(body, state.Id).then((response) => {

      if (state.ProblemType === "Clustering")
        setState(prevState => ({
          ...prevState,
          NormalizedNumData: [state.MarkerNamesNum, ...response.data.normalizedData]
        }));
      else
        setState(prevState => ({
          ...prevState,
          PCAResult1: response.data.PCANormalized0,
          PCAResult2: response.data.PCANormalized1,
          Score1: response.data.normalizedScore0,
          Score2: response.data.normalizedScore1,
          PCAResultOrg1: response.data.PCAOriginal0,
          PCAResultOrg2: response.data.PCAOriginal1,
          ScoreOrg1: response.data.originalScore0,
          ScoreOrg2: response.data.originalScore1,
          NormalizedNumData: [state.MarkerNamesNum, ...response.data.normalizedData]
        }));

      handleNext();
      setLoading(false);
    })
      .catch((err) => {
        console.log(err);
        err.response.status === 401 ?
        helpers.tokenExpirationAlert(history) :
          setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
        setLoading(false);
      });
  },

  validate = (history, state, setState, handleNext, setAlert, setLoading) => {

    setLoading(true);

    state.ProblemType === "Regression" ?
    setState(prevState => ({ ...prevState, Lambda: 1 / state.MarkerNames.length, N: state.MarkerNames.length }))
    :
    setState(prevState => ({ ...prevState, N: state.MarkerNames.length }));

    const body = { validMethod: state.ValidMethod, trainRate: state.TrainRate, target: state.Target };

    helpers.validate(body , state.Id).then((response) => {
      handleNext();
      setLoading(false);
    })
      .catch((err) => {
        console.log(err);
        err.response.status === 401 ?
        helpers.tokenExpirationAlert(history) :
          setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
          setLoading(false);
      });
  },

  features = (history, state, setState, handleNext, setAlert, setLoading) => {

    setLoading(true);
    const body = {
      trainingMethod: state.TrainingMethod,
      membershipModel: state.MembershipModel,
      membershipIndex: state.MembershipIndex,
      membershipFS: state.MembershipFS,
      kNumber: state.KNumber,
      kernelFcn: state.KernelFunction,
      validMethod: state.ValidMethod,
      threshold: state.Threshold,
      n: state.N,
      lambda: state.Lambda,
      fitMethodFS: state.FitMethodFS,
      solverFS: state.SolverFS,
      lossFncFS: state.LossFunctionFS,
      solverReg: state.SolverReg,
      kernelScale: state.KernelScale
    };

    helpers.features(body, state.Id).then((response) => {

      if( response.data.FlagErr == 1 ){
        setAlert({ alert: true, message: 'Error Occured , Please try again', severity: "error" });
      }
      else{
         setState(prevState => ({
        ...prevState,
        NbrOfVariables: response.data.RankedMarkers.length,
        RankedMarkers: response.data.RankedMarkers,
        RankedWeights: response.data.RankedWeights,
        F: response.data.F,
        Err: response.data.Err,
        Auc: response.data.Auc,
        FlagErr: response.data.FlagErr
      }));

        handleNext();
      }
      setLoading(false);
    })
      .catch((err) => {
        console.log(err);
        err.response.status === 401 ?
        helpers.tokenExpirationAlert(history) :
          setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
          setLoading(false);
      });
  },

  build = (history, state, resetState, fetchData, setAlert, setLoading) => {

    setLoading(true);

    const
     SelectedVariables = _.intersection(state.MarkerNames, _.first(state.RankedMarkers, state.NbrOfVariables)),
     body = { selectedHeader: SelectedVariables, numOptimFeat: state.NbrOfVariables };

    helpers.build(body , state.Id).then((response) => {
      resetState(); 
      fetchData();
      setLoading(false);
    })
      .catch((err) => {
        console.log(err);
        err.response.status === 401 ?
        helpers.tokenExpirationAlert(history) :
          setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
          setLoading(false);
      });
  },

  analyse2 = (history, state, setState, handle4Open, setAlert, setLoading) => {

    setLoading(true);

     const body = { selectedData: getSelecteddata(state) };

    helpers.analyse2(body, state.Id).then((response) => {

      setState(prevState => ({
        ...prevState,
        PCAResult1: response.data.PCASelected0,
        PCAResult2: response.data.PCASelected1,
        Score1: response.data.selectedScore0,
        Score2: response.data.selectedScore1,
      }));

      handle4Open();

      setLoading(false);
    })
      .catch((err) => {
        console.log(err);
        err.response.status === 401 ?
        helpers.tokenExpirationAlert(history) :
          setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
          setLoading(false);
      });
  },

  cluster = (history, state, setState, handleNext, setAlert, setLoading) => {

    setLoading(true);

    const body = { id: state.Id, connective: state.Connective, lamda: state.LamdaFunction, alpha: state.ExegencyLevel };

    helpers.cluster(body, state.Id).then((response1) => {
      
      helpers.clusterAnalyse (state.Id).then((response2) => {
        setState(prevState => ({
          ...prevState,
          Target: response1.data.target,
          OutclassNbr: response1.data.classCount,
          GadNic: response1.data.gadNic,
          ClassCount: response1.data.classCount,
          MembershipMatrix: [response1.data.membershipMatrix[0].map((item, index) => index === 0 ? "Individual" : "Class " + index), ...response1.data.membershipMatrix],
          PCAResult1: response2.data.PCANormalized0,
          PCAResult2: response2.data.PCANormalized1,
          Score1: response2.data.normalizedScore0,
          Score2: response2.data.normalizedScore1,
          PCAResultOrg1: response2.data.PCAOriginal0,
          PCAResultOrg2: response2.data.PCAOriginal1,
          ScoreOrg1: response2.data.originalScore0,
          ScoreOrg2: response2.data.originalScore1,
        }));

        handleNext();

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        err.response.status === 401 ?
        helpers.tokenExpirationAlert(history) :
          setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
          setLoading(false);
      });
    })
      .catch((err) => {
        console.log(err);
        err.response.status === 401 ?
        helpers.tokenExpirationAlert(history) :
          setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
          setLoading(false);
      });
  };

  




  // handleApi = async (path, name, body, setAlert, setProg) => {
  //   setAlert({ alert: false, message: "" });
  //   setProg(true);
  //   try {
  //     const res = await fetch(`${path}/${name}`, {
  //       method: 'POST', mode: 'cors',
  //       body: JSON.stringify(body),
  //       headers: { 'Content-Type': 'application/json', 'xauthtoken': token, 'Authorization': 'Bearer ' + token },
  //     });
  //     return res;
  //   } catch (error) { if (error) console.log(error); }
  // },

  // postName = async (state, setState, handleNext, setAlert, setProg) => {
  //   const body = { name: state.Name, id: state.Id };
  //   const res = await handleApi(state.path, "addName", body, setAlert, setProg);
  //   const json = await res.json();
  //   if (res.status === 200) { setState(prevState => ({ ...prevState, Id: json.id })); handleNext(); }
  //   else { setAlert({ alert: true, message: json.message || 'Error occured , please try again' }); setProg(false); return; }
  // },


  // postProblemType = async (state, handleNext, setAlert, setProg) => {
  //   const body = { problemType: state.ProblemType, id: state.Id };
  //   const res = await handleApi(state.path, "problemType", body, setAlert, setProg);
  //   const json = await res.json();
  //   if (res.status === 200) handleNext();
  //   else { setAlert({ alert: true, message: json.message || 'Error occured , please try again' }); setProg(false); return; }
  // },


  // postData = async (state, setState, handleNext, setAlert, setProg) => {
  //   if (!state.DataIsSelected || state.Index !== state.Indexsaved) {
  //     try {
  //       let
  //         MatrixData = await getMatrixData(state.Data, state.Index, state.Filtered ? state.Filtered : []),
  //         MarkerNames = await getMarkerNames(state.Data, state.Index, state.Filtered ? state.Filtered : []),
  //         RowNames = await getRowNames(state.Data);

  //       setState(prevState => ({
  //         ...prevState,
  //         MarkerNames: MarkerNames,
  //         NumData: MatrixData.NumData,
  //         TextData: MatrixData.TextData,
  //         IntData: MatrixData.IntData,
  //         MarkerNamesNum: MatrixData.MarkerNamesNum,
  //         MarkerNamesTxt: MatrixData.MarkerNamesTxt,
  //         MarkerNamesInt: MatrixData.MarkerNamesInt,
  //         RowNames: RowNames
  //       }));

  //       let body = {
  //         id: state.Id,
  //         MarkerNames: MarkerNames,
  //         NumData: MatrixData.NumData,
  //         TextData: MatrixData.TextData,
  //         IntData: MatrixData.IntData,
  //         MarkerNamesNum: MatrixData.MarkerNamesNum,
  //         MarkerNamesTxt: MatrixData.MarkerNamesTxt,
  //         MarkerNamesInt: MatrixData.MarkerNamesInt,
  //         Index: state.Index,
  //         Target: state.Target,
  //         RowNames: RowNames
  //       };


  //       setProg(true);
  //       const res = await handleApi(state.path, "postData", body, setAlert, setProg);
  //       const json = await res.json();
  //       if (res.status === 200) handleNext();
  //       else setAlert({ alert: true, message: json.message || 'Error occured , please try again' });
  //     }
  //     catch (err) {
  //       console.error(err);
  //       setAlert({ alert: true, message: 'data could not be posted  : ' + err });
  //       setProg(false);
  //       return;
  //     }
  //   }
  //   else {
  //     handleNext();
  //   }
  // },

  


  // compressBody : (body) => {
  //   return new Promise(function (resolve, reject) {
  //     zlib.deflate(body, (err, buffer) => {
  //       if (err) {
  //         console.log("Error Zipping");
  //         reject(err);
  //       }
  //       console.log("Zipped");
  //       resolve(buffer);
  //     });
  //   });
  // }
  
  // const NormalizeFnc = async () => {
  //   try {
  //     const body = { normalizer: state.Normalizer };
  //     setAlert({ alert: false, message: "", severity: "" });
  //     setProg(true);
  //     const res = await handleApi(`normalization/${state.Id}`, body);
  //     const json = await res.json();

  //     if (res.status === 200) {
  //       if (state.ProblemType === "Clustering")
  //         setState(prevState => ({
  //           ...prevState,
  //           NormalizedNumData: [state.MarkerNamesNum, ...json.normalizedData]
  //         }));
  //       else
  //         setState(prevState => ({
  //           ...prevState,
  //           PCAResult1: json.PCANormalized0,
  //           PCAResult2: json.PCANormalized1,
  //           Score1: json.normalizedScore0,
  //           Score2: json.normalizedScore1,
  //           PCAResultOrg1: json.PCAOriginal0,
  //           PCAResultOrg2: json.PCAOriginal1,
  //           ScoreOrg1: json.originalScore0,
  //           ScoreOrg2: json.originalScore1,
  //           NormalizedNumData: [state.MarkerNamesNum, ...json.normalizedData]
  //         }));


  //       handleNext();
  //     }
  //     else { setAlert({ alert: true, message: json.message || 'Error occured , please try again', severity: "error" }); setProg(false); }

  //   } catch (err) { console.log(err); setAlert({ alert: true, message: 'Normalization function error', severity: "error" }); setProg(false); return; }
  // };


  // const ValidationFnc = async () => {
  //   try {
  //     state.ProblemType === "Regression" ?
  //       setState(prevState => ({ ...prevState, Lambda: 1 / state.MarkerNames.length, N: state.MarkerNames.length }))
  //       :
  //       setState(prevState => ({ ...prevState, N: state.MarkerNames.length }));

  //     const body = { validMethod: state.ValidMethod, trainRate: state.TrainRate, target: state.Target };
  //     setAlert({ alert: false, message: "", severity: "" });
  //     setProg(true);
  //     const res = await handleApi(`validation/${state.Id}`, body);
  //     const json = await res.json();

  //     if (res.status === 200) handleNext();

  //     else { setAlert({ alert: true, message: json.message || 'Error occured , please try again', severity: "error" }); setProg(false); }

  //   } catch (err) { console.log(err); setAlert({ alert: true, message: 'Validation function error', severity: "error" }); setProg(false); return; }
  // };

  
  // const FeatSelFnc = async () => {
  //   try {
  //     const body = {
  //       trainingMethod: state.TrainingMethod,
  //       membershipModel: state.MembershipModel,
  //       membershipIndex: state.MembershipIndex,
  //       membershipFS: state.MembershipFS,
  //       kNumber: state.KNumber,
  //       kernelFcn: state.KernelFunction,
  //       validMethod: state.ValidMethod,
  //       threshold: state.Threshold,
  //       n: state.N,
  //       lambda: state.Lambda,
  //       fitMethodFS: state.FitMethodFS,
  //       solverFS: state.SolverFS,
  //       lossFncFS: state.LossFunctionFS,
  //       solverReg: state.SolverReg,
  //       kernelScale: state.KernelScale
  //     };
  //     setAlert({ alert: false, message: "", severity: "" });
  //     setProg(true);
  //     const res = await handleApi(`features/${state.Id}`, body);
  //     const json = await res.json();
  //     if (res.status === 200) {
  //       await setState(prevState => ({
  //         ...prevState,
  //         NbrOfVariables: json.RankedMarkers.length,
  //         RankedMarkers: json.RankedMarkers,
  //         RankedWeights: json.RankedWeights,
  //         F: json.F,
  //         Err: json.Err,
  //         Auc: json.Auc,
  //         FlagErr: json.FlagErr
  //       }));
  //       handleNext();
  //     }

  //     else { setAlert({ alert: true, message: json.message || 'Error occured , please try again', severity: "error" }); setProg(false); }

  //   } catch (err) { console.log(err); setAlert({ alert: true, message: 'Feature Selection function error', severity: "error" }); setProg(false); return; }
  // };


  // const BuildModelFnc = async () => {
  //   try {
  //     let SelectedVariables = _.intersection(state.MarkerNames, _.first(state.RankedMarkers, state.NbrOfVariables))

  //     const body = { selectedHeader: SelectedVariables, numOptimFeat: state.NbrOfVariables };

  //     setAlert({ alert: false, message: "" });
  //     setProg(true);

  //     const res = await handleApi(`build/${state.Id}`, body);

  //     const json = await res.json();

  //     if (res.status === 200) { await setProg(false); await resetState(); await fetchData(); }
  //     else { setAlert({ alert: true, message: json.message || 'Error occured , please try again', severity: "error" }); setProg(false); }
  //   } catch (err) { console.log(err); setAlert({ alert: true, message: 'Build function error', severity: "error" }); setProg(false); return; }
  // };

  // const handelNewData = async () => {

  //   if (isExist) {
  //     setState(prevState => ({ ...prevState, RowNames: getRowNames(state.Data) }));
  //     handleNext();
  //   }
  //   else {
  //     HandleData(setState, state.Data, state.TableHeader, state.TableLabels);
  //     setState(prevState => ({ ...prevState, RowNames: getRowNames(state.Data) }));
  //     setDisableHeader(true);

  //     await setLoading(true);

  //     let body = { id: state.Id, initialData: state.Data, fileName: file };
  //     helpers.uploadFile(body).then(
  //       (response) => { handleNext(); setDisableHeader(true);setLoading(false); }
  //     ).catch((err) => {
  //       err.response.status === 401 ?
  //         setAlert({ alert: true, message: "Token Expired , Please login again", severity: "error" }) :
  //         setAlert({ alert: true, message: 'Error occured , please try again', severity: "error" });
  //         setLoading(false);
  //     });
  //   }
  // };


  // const getSelecteddata = () => {
  //   let selVar = [];
  //   for (let i = 1; i < state.NormalizedNumData.length; i++) {
  //     var row = [];
  //     for (let j = 0; j < state.NbrOfVariables; j++)
  //       if (state.MarkerNamesNum.includes(state.RankedMarkers[j]))
  //         row.push(state.NormalizedNumData[i][state.MarkerNamesNum.indexOf(state.RankedMarkers[j])])
  //     selVar.push(row)
  //   }
  //   return selVar
  // };

  // const handlePca2 = async () => {
  //   try {
  //     setLoading(true);
  //     const body = { selectedData: getSelecteddata() };
  //     const res = await handleApi(`analyse2/${state.Id}`, body);
  //     const json = await res.json();

  //     if (res.status === 200) {

  //       await setState(prevState => ({
  //         ...prevState,
  //         PCAResult1: json.PCASelected0,
  //         PCAResult2: json.PCASelected1,
  //         Score1: json.selectedScore0,
  //         Score2: json.selectedScore1,
  //       }));
  //       handle4Open();
  //     }

  //     else { setLoading(false); setAlert({ alert: true, message: json.message || 'Error occured , please try again' }); }

  //   } catch (err) { setLoading(false); setAlert({ alert: true, severity: "error", message: "post Selected Var fnc error1" + err }) }
  // };