import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './Routes';
import 'bootstrap/dist/css/bootstrap.css';
import {MuiThemeProvider , createMuiTheme } from '@material-ui/core/styles';

const browserHistory = createBrowserHistory();
const theme = createMuiTheme({ palette:{ type: 'dark'  }});


 const App = () => {

    return (
        <MuiThemeProvider theme = {theme}>

          <Router history={browserHistory}>

            <Routes history={browserHistory}/>

          </Router>

       </MuiThemeProvider>
    );
}

export default App ;
