import React, { useState } from 'react';
import Flowchart from 'react-simple-flowchart';
import Normalization from "./process/Normalization";
import Visualization from "./process/Visualization";
import Validation from "./process/Validation";
import FeatureSelection from "./process/FeatureSelection";
import Build from "./process/Build";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Alert, Progress } from "../../../Components";
import axios from 'axios';

import helpers from '../../../api';


const useStyles = makeStyles(theme => ({
    paper: {
        height: '66vh',
        width: "100%",
        marginTop: "10px",
        background: "#27293d",
    },
    flow: {
        width: "100%",
        background: "#27293d",
        textAlign: "center"
    },
}));

const Flowdemo = ({ history, Model, path, token, fetchData }) => {

    const
        classes = useStyles(),
        [alert, setAlert] = useState({ alert: false, severity: "", message: "" }),
        [open, setOpen] = useState({ O1: false, O2: false, O3: false, O4: false, O5: false }),
        handelClose = () => setOpen({ O1: false, O2: false, O3: false, O4: false, O5: false }),
        [loading, setLoading] = useState(false),
        [disabled, setDisabled] = useState(false),
        [state, setState] = useState({
            problemType: Model.problemType,
            normalizer: Model.normalizer,
            validMethod: Model.validMethod,
            trainingMethod: Model.trainingMethod,
            trainRate: Model.trainRate,
            membershipModel: Model.membershipModel,
            membershipIndex: Model.membershipIndex,
            membershipFS: Model.membershipFS,
            kNumber: Model.kNumber,
            kernelFcn: Model.kernelFcn,
            kernelParam: Model.kernelParam,
            threshold: Model.threshold,
            N: Model.n,
            NbrOfVariables: Model.numOptimFeat,

            connective: Model.connective,
            lamdaFunction: Model.lamda,
            exegencyLevel: Model.alpha,

            lambda: Model.lambda,
            fitMethodFS: Model.fitMethodFS,
            solverFS: Model.solverFS,
            lossFncFS: Model.lossFncFS,
            solverReg: Model.solverReg,
            kernelScale: Model.kernelScale,
            tableHeader: Model.TableHeader,
            tableLabels: Model.TableLabels,
            outClassNbr: 0,
            markerNames: Model.markerNames,
            markerNamesNum: Model.markerNamesNum,
            markerNamesInt: Model.markerNamesInt,
            markerNamesTxt: Model.markerNamesTxt,
            rowNames: Model.rowNames,
            numData: Model.numData,
            intData: Model.intData,
            textData: Model.textData,
            normalizedNumData: Model.normalizedData,
            normalizedIntData: Model.normalizedIntData,
            PCAResult1: Model.PCA0,
            PCAResult2: Model.PCA1,
            PCAScore1: Model.PCAScore0,
            PCAScore2: Model.PCAScore1,
            target: Model.target,
            rankedMarkers: Model.RankedMarkers,
            rankedWeights: Model.RankedWeights,
            F: Model.F,
            Err: Model.Err,
            Auc: Model.Auc,
            flagErr: Model.flagErr,
        });

    let
        step = state.normalizer !== Model.normalizer ? 1 :
            (state.validMethod !== Model.validMethod || state.trainRate !== Model.trainRate) ? 2 :
                (state.trainingMethod !== Model.trainingMethod
                    || state.membershipModel !== Model.membershipModel
                    || state.membershipIndex !== Model.membershipIndex
                    || state.membershipFS !== Model.membershipFS
                    || state.lambda !== Model.lambda
                    || state.fitMethodFS !== Model.fitMethodFS
                    || state.solverFS !== Model.solverFS
                    || state.lossFncFS !== Model.lossFncFS
                    || state.solverReg !== Model.solverReg
                    || state.kernelScale !== Model.kernelScale
                    || state.kNumber !== Model.kNumber
                    || state.kernelFcn !== Model.kernelFcn
                    || state.threshold !== Model.threshold
                    || state.N !== Model.n
                ) ? 3 : 0;

    const body = {
        step: step,
        problemType: state.problemType,
        normalizer: state.normalizer,
        validMethod: state.validMethod,
        trainingMethod: state.trainingMethod,
        trainRate: state.trainRate,
        membershipModel: state.membershipModel,
        membershipIndex: state.membershipIndex,
        membershipFS: state.membershipFS,
        kNumber: state.kNumber,
        kernelFcn: state.kernelFcn,
        threshold: state.threshold,
        n: state.N,
        lambda: state.lambda,
        fitMethodFS: state.fitMethodFS,
        solverFS: state.solverFS,
        lossFncFS: state.lossFncFS,
        solverReg: state.solverReg,
        kernelScale: state.kernelScale,
    },

        handelChange = (type) => {
            if (type === "Normalization") setOpen({ ...open, O1: true });
            if (type === "Visualization") setOpen({ ...open, O2: true });
            if (type === "Validation") setOpen({ ...open, O3: true });
            if (type === "Feature Selection") setOpen({ ...open, O4: true });
            if (type === "Build Model") setOpen({ ...open, O5: true });
        },

        handleUpdate = async () => {

            setLoading(true);
            
            helpers.rebuild(body, Model.id).then((response) => {
                handelChange("Build Model");
                setDisabled(true);
                setState(prevState => ({
                    ...prevState,
                    F: response.data.F,
                    Err: response.data.Err,
                    Auc: response.data.Auc,
                    flagErr: response.data.FlagErr,
                    rankedMarkers: response.data.RankedMarkers,
                    rankedWeights: response.data.RankedWeights
                }))
                setLoading(false);
            })
                .catch((err) => {
                    console.log(err);
                    err.response.status === 401 ?
                        helpers.tokenExpirationAlert(history) :
                        setAlert({ alert: true, severity: "error", message: err || 'Error occurred , please try again' });
                    setLoading(false);
                });

            // try {

            //     const res = await fetch(`${path}/rebuild/${Model.id}`, {
            //         method: 'POST', mode: 'cors',
            //         body: JSON.stringify(body),
            //         headers: {
            //             "Content-Type": "application/json",
            //             "x-auth-token": token,
            //             'Authorization': 'Bearer ' + token
            //         },
            //     });

            //     const json = await res.json();

            //     if (res.status === 200) { 
            //         await handelChange("Build Model"); 
            //         await setDisabled(true) ; 
            //         await setState( prevState  => ({
            //             ...prevState ,
            //             F: json.F,
            //             Err: json.Err,
            //             Auc: json.Auc,
            //             flagErr: json.FlagErr,
            //             rankedMarkers: json.RankedMarkers,
            //             rankedWeights: json.RankedWeights
            //         }))
            //     }

            //     else { setAlert({ alert: true, severity: "error", message: json.message || 'Error occurred , please try again' }); }

            //     setLoading(false);

            // } catch (error) {
            //     setAlert({ alert: true, severity: "error", message: 'Error occurred , please try again' });
            //     setLoading(false); console.log(error);
            // }
        },

        code =
            `st=>start: Upload Data
             e=>end: Build Model
             para=>parallel: Normalization|department1
             op2=>operation: Visualization|department1
             op3=>operation: Validation|department1
             op4=>operation: Feature Selection|department1
             st(bottom)->para(path1,bottom)->op2
                          para(path2,right)->op3
                          op2(right)->op3    
                          op3(right)->op4
                          op4(bottom)->e`,

        opt = {
            'line-width': 3,
            'font-size': 20,
            'font-color': 'black',
            'line-color': 'black',
            'element-color': 'black',
            'arrow-end': 'block',
            'line-length': 80,
            fill: 'white',
            scale: 1,
            symbols: {
                start: {
                    'font-color': 'black',
                    'element-color': 'black',
                },
                end: {
                    'font-color': 'black',
                    'element-color': 'black',
                },
            },
            flowstate: {
                department1: { fill: 'skyblue', cursor: "pointer" },
            },
        };

    return (<>
        <div className={classes.paper}>
            <div className={classes.flow}>
                <Flowchart style={{ border: "2px solid white" }} chartCode={code} options={opt} onClick={elementText => handelChange(elementText)} />

                <Button onClick={handleUpdate} style={{ float: 'right', margin: 20 }} disabled={step === 0} variant={"contained"} color={"primary"}> Update </Button>

                {open.O1 ? <Normalization Model={Model} state={state} setState={setState} open={open.O1} handelClose={handelClose} /> : null}

                {open.O2 ? <Visualization Model={Model} state={state} setState={setState} open={open.O2} handelClose={handelClose} /> : null}

                {open.O3 ? <Validation Model={Model} state={state} setState={setState} open={open.O3} handelClose={handelClose} /> : null}

                {open.O4 ? <FeatureSelection Model={Model} state={state} setState={setState} open={open.O4} handelClose={handelClose} /> : null}

                {open.O5 ? <Build Model={Model} state={state} setState={setState} open={open.O5} handelClose={handelClose} fetchData={fetchData}
                    path={path} token={token} step={step} disabled={disabled} setDisabled={setDisabled} /> : null}
            </div>

            {loading ? <Progress /> : null}

            <Alert alert={alert} setAlert={setAlert} />
        </div>
    </>);
}

export default Flowdemo;