import React, { useState } from 'react';
import axios from 'axios';
import { getUser, setNotifications, setUserSession, setSetting } from '../../Utils/common';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.beemetrix.com">
        Beemetrix
          </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};


const SignIn = (props) => {
  const [loading, setLoading] = useState(false);
  const username = useFormInput("");
  const password = useFormInput("");
  const [error, setError] = useState(null);
  const classes = useStyles();

  if (getUser() != null) props.history.push('/');

  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    if(!username.value || !password.value)
      setError("Username or password cannot be empty");

    else{
      setLoading(true);
      axios.post(props.url+'/login', { username: username.value, password: password.value }).then(response => {
        setUserSession(response.data.token , response.data.user, response.data.role , response.data.tokenexpiration );
        setNotifications(response.data.notifications);
        if (response.data.setting.length !== 0)
          setSetting(response.data.setting[0]);
        else
          setSetting(null)
        if (getUser().admin) window.location.reload();
        else window.location.reload();
    }).catch(error => {
      setLoading(false);
      if (error.response.status === 401) setError("Username or password  is incorrect . Please try again .");
      if (error.response.status === 500) setError("Internal server error , Please try again.");
    });
    }
  };
/**
  document.addEventListener('keydown', function(event){
    if (event.key === 'Enter') handleLogin();
  });
**/

const handleKeyDown = (event) => {
  if (event.key === 'Enter')
    handleLogin();
}

  return (
    <Container component="main" maxWidth="xs">

      <CssBaseline/>

      <div className={classes.paper}>

        <img height = {100} width = {250} src={"/images/signup/MinerWorxSignIn.png"} alt="logo"/>

        <div className={classes.form}>

          <div>
            <TextField
              type="text"
              {...username}
              autoComplete="username" variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoFocus
              onKeyDown={handleKeyDown}
            />
          </div>

          <div>
            <TextField
              type="password"
              {...password}
              autoComplete="new-password"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              autoFocus
              onKeyDown={handleKeyDown}
            />
          </div>

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />

          <div> {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br /> </div>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            value={loading ? 'Loading...' : 'Login'}
            onClick={handleLogin}
            disabled={loading}
          >
            Sign In
            </Button>

        </div>

      </div>

      <Box mt={8}> <Copyright /> </Box>

    </Container>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  const handleChange = e => setValue(e.target.value);
  return { value, onChange: handleChange }
}

export default SignIn;