import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, CardActions, Typography, Grid, Divider, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { getUser, getRole } from '../../../../Utils/common';
import ShareIcon from '@material-ui/icons/Share';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles(theme => ({  
  statsItem: {
    display: 'flex',
    alignItems: 'center'
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1)
  },
}));

function PercentageColor(value) {
  const hue = Math.round(value);
  return ["hsl(", hue, ", 100%, 50%)"].join("");
};

function RangeColor(value) {
  const scale = (value - 0) * (100 - 0) / (value - 0) + 0;
  const hue = Math.round(scale);
  return ["hsl(", hue, ", 100%, 50%)"].join("");
};

const ModelCard = props => {

  const { className, model, history, handleDeleteClick, handleShareClick, handleContinue, path, handleFavorite, ...rest } = props,
    classes = useStyles(),
    role = getRole(),
    admin = getUser().admin;


  return (
    <Card style={{ background: "#27293d" }}
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent style={{ minHeight: 130 }} onClick={() => model.updatedDate ?

        history.push(`/show/${model._id}`) : handleContinue(model._id)}>

        {model.problemType === "Regression" ?

          <div style={{ float: "right" }}>
            <i className="fal fa-percentage"></i>
            <Typography style={{ fontSize: 16, /** color: RangeColor(6)**/ }} display="inline" variant="body2"  >{model.MSE ? model.MSE : ""}</Typography>
          </div>
          :
          <div style={{ float: "right" }}>
            <i className="fal fa-percentage"></i>
            <Typography style={{ fontSize: 16, /**color: PercentageColor((100 - (model.Acc * 100)))**/ }} display="inline" variant="body2" >{(100 - (model.ACC * 100)).toFixed(2) + '%'}</Typography>
          </div>
        }

        <Typography gutterBottom> {model.name} </Typography>

        <Typography gutterBottom variant="body2" >{model.problemType}</Typography>

      </CardContent>

      <Divider />

      <CardActions style={{ padding: 10 }}>
        <Grid container justify="space-between" >
          <Grid className={classes.statsItem} item >
            {model.updatedDate ? <>
              <AccessTimeIcon style={{ color: "#4728f5" }} className={classes.statsIcon} />
              <Typography display="inline" variant="body2" >
                {model.updatedDate}
              </Typography> </> :
              <Typography display="inline" variant="body2" >progress...</Typography>
            }
          </Grid>

          <Grid className={classes.statsItem} item>

            {admin || role.permission.deleteModel || role.permission.deleteALLModel ?

              admin || getUser()._id === model.userId || role.permission.deleteALLModel || ((model.models.length > 0) && model.models[0].permissions.supp) ?

                <>
                  <IconButton style={{ float: 'right', color: "#4728f5" }} fontSize='small' onClick={(e) => handleFavorite(model._id)}>
                    {model.favorite ? <StarIcon /> : <StarBorderIcon />}
                  </IconButton>
                  {model.updatedDate ?
                    <IconButton style={{ float: 'right', color: "#4728f5" }} fontSize='small' onClick={(e) => handleShareClick(model._id)}>
                      <ShareIcon />
                    </IconButton> : null
                  }
                  <IconButton style={{ float: 'right', color: "#4728f5" }} fontSize='small' onClick={(e) => handleDeleteClick(model._id)} >
                    <DeleteIcon />
                  </IconButton>
                </>
                : null
              : null
            }
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

ModelCard.propTypes = {
  className: PropTypes.string,
  model: PropTypes.object.isRequired
};

export default ModelCard;
