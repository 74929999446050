import React from 'react';
import { Collapse } from 'reactstrap';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
  paper: {
    minHeight: '62vh',
    background: "#27293d",
  },
}));



const InitSetUp = ({ Model }) => {


  // useEffect(() => {
  //   const options = { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } }
  //   axios.get(`${path}/showmodel/${id}/step1`, options )
  //     .then((response) => {
  //     });
  // }, []);

  const classes = useStyles();
  const [openList, setopenList] = React.useState({ M: false, D: false });

  const handleClick = (name) => setopenList(prevState => ({ ...prevState, [name]: !openList[name] }));

  return <div className="row m-0" >
    <div className="col-6">
      <div className="row ml-2 mt-2">
        <p>Initial Setup</p>
      </div>

      <div className={classes.paper}>
        <div className="row">
          <div className="container">
            <div className="row p-2">
              <div className="col-6">
                <label className="col-lg-6 col-form-label form-control-label">Model Name</label>
                <div className="col-lg-6 float-right">
                  <label className="col-lg-12 col-form-label form-control-label">{Model.name}</label>
                </div>
              </div>
              <div className="col-6">
                <label className="col-lg-6 col-form-label form-control-label">Problem Type</label>
                <div className="col-lg-6 float-right">
                  <label className="col-lg-12 col-form-label form-control-label">{Model.problemType}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="row">

          <div className="col-6">
            <ListItem className="mt-2" button onClick={() => handleClick("M")}>
              <ListItemText primary='Model Parameters' />
              {openList.M ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse isOpen={openList.M}>
              <div className="container">
                <div className="col-lg-12 push-lg-4 personal-info">
                  <form>
                    <div className="form-group row">
                      <label className="col-lg-6 col-form-label form-control-label">Algorithm</label>
                      <div className="col-lg-6 float-right">
                        <label className="col-lg-12 col-form-label form-control-label">{Model.trainingMethod === "LAMDA" ? 'LAMDA' : Model.trainingMethod === "kNN" ? 'k-Nearest Neighbors' : 'Support Vector Machine'}</label>
                      </div>
                    </div>
                    {
                      Model.problemType === "Clustering" ? <>

                        <div className="form-group row">
                          <label className="col-lg-6 col-form-label form-control-label">Connective Type</label>
                          <div className="col-lg-6 float-right">
                            <label className="col-lg-12 col-form-label form-control-label">{Model.connective}</label>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-lg-6 col-form-label form-control-label">Clustering Membership Function</label>
                          <div className="col-lg-6float-right">
                            <label className="col-lg-12 col-form-label form-control-label">{Model.lamda.replaceAll("_", " ")}</label>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-lg-6 col-form-label form-control-label">Clustering Exegency Index</label>
                          <div className="col-lg-6 float-right">
                            <label className="col-lg-12 col-form-label form-control-label">{Model.alpha}</label>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-lg-6 col-form-label form-control-label"> Clustering Gad Nic</label>
                          <div className="col-lg-6 float-right">
                            <label className="col-lg-12 col-form-label form-control-label">{Model.gadNic}</label>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-lg-6 col-form-label form-control-label"> Number of Clusters</label>
                          <div className="col-lg-6 float-right">
                            <label className="col-lg-12 col-form-label form-control-label">{Model.classCount}</label>
                          </div>
                        </div>

                      </> : null
                    }
                    {
                      Model.TrainingMethod === "LAMDA" ? <>
                        <div className="form-group row">
                          <label className="col-lg-6 col-form-label form-control-label">Membership Function</label>
                          <div className="col-lg-6 float-right">
                            <label className="col-lg-12 col-form-label form-control-label">{Model.membershipModel === "gauss" ? 'Gaussian' : Model.membershipModel === "f_lamda" ? 'Binomial' : 'Gaussian Diagonal'}</label>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-lg-6 col-form-label form-control-label">Exigency Index</label>
                          <div className="col-lg-6 float-right">
                            <label className="col-lg-12 col-form-label form-control-label">{Model.membershipIndex}</label>
                          </div>
                        </div>
                      </> : null
                    }
                    {
                      Model.trainingMethod === "kNN" ?
                        <div className="form-group row">
                          <label className="col-lg-6 col-form-label form-control-label">K-Number</label>
                          <div className="col-lg-6 float-right">
                            <label className="col-lg-12 col-form-label form-control-label">{Model.kNumber}</label>
                          </div>
                        </div>
                        : null
                    }
                    {
                      Model.trainingMethod === "SVM" ?
                        <div className="form-group row">
                          <label className="col-lg-6 col-form-label form-control-label">Kernel Function</label>
                          <div className="col-lg-6 float-right">
                            <label className="col-lg-12 col-form-label form-control-label">{Model.kernelFnc}</label>
                          </div>
                        </div>
                        : null
                    }
                  </form>
                </div>
              </div>
            </Collapse>
          </div>

          <div className="col-6">
            <ListItem className="mt-2 mr-2" button onClick={() => handleClick("D")} >
              <ListItemText primary='Data Characteristics' />
              { openList.D ? <ExpandLess/> : <ExpandMore/> } 
            </ListItem>
            <Collapse isOpen={openList.D}>
              <div className="container">
                <div className="col-lg-12 push-lg-4 personal-info">
                  <form>
                    <div className="form-group row">
                      <label className="col-lg-10 col-form-label form-control-label">Number of Columns</label>
                      <div className="col-lg-2 float-right">
                        <label className="col-lg-12 col-form-label form-control-label">{Model.nbrOfCols}</label>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-10 col-form-label form-control-label">Target Columns</label>
                      <div className="col-lg-2 float-right">
                        <label className="col-lg-12 col-form-label form-control-label">{1}</label>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-10 col-form-label form-control-label">Dropped Columns</label>
                      <div className="col-lg-2 float-right">
                        <label className="col-lg-12 col-form-label form-control-label">{0}</label>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-10 col-form-label form-control-label">Number of Selected Features</label>
                      <div className="col-lg-2 float-right">
                        <label className="col-lg-12 col-form-label form-control-label">{Model.nbrOfSelectedFeat}</label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

            </Collapse>
          </div>

        </div>
      </div>
    </div>

    <div className="col-6">
      <div className="row ml-2 mt-2">
        <p> Training Session </p>
      </div>
      <div className={classes.paper}>
        <Toolbar variant={'dense'} >
          <ListItem dense ><ListItemText primary='Trained' secondary={Model.updatedDate} /> </ListItem>
          <ListItem dense ><ListItemText primary='Train Sets' secondary={1} /></ListItem>
          <ListItem dense ><ListItemText primary='Train Duration' secondary={Model.trainDuration} /></ListItem>
          <ListItem dense ><ListItemText primary={Model.problemType === "Regression" ? 'MSE' : 'Accuracy'} secondary={Model.problemType === "Regression" ? Model.MSE : (100 - (Model.ACC * 100)).toFixed(2) + '%'} /></ListItem>
        </Toolbar>
        <Divider />
      </div>
    </div>
  </div>
}
export default InitSetUp;




