
import React from 'react';
import { IconButton } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import _ from 'underscore';

import Select from "react-select";


const styles = {
    control: (base, state) => ({
        ...base,
        backgroundColor: '#1e1e2f',
        color: 'white',
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        borderColor: state.isFocused ? "blue" : "white",
        boxShadow: state.isFocused ? null : null,
    }),

    singleValue: (provided) => ({
        ...provided,
        color: 'white',
    }),

    option: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? 'grey' : 'white'
    }),

    menu: base => ({
        ...base,
        background: '#1e1e2f',
    }),

    menuList: base => ({
        ...base,
        background: '#212121',
    })
};


export default function Pagination({ page, setPage, nbrOfPage }) {

    const options = _.range(1, nbrOfPage + 1).map(option => { return { label: option, value: option } });

    const HandlePage = (value) => {
        if (value.value !== "" && value.value !== null && !isNaN(value.value))
            setPage(value.value);
    }
    const handleNextPage = () => { if (page < nbrOfPage) setPage(prevPage => prevPage + 1); }
    const handlePreviuosPage = () => { setPage(prevPage => prevPage - 1); }
    const handleFirstPage = () => { setPage(1); }
    const handleLastPage = () => { setPage(nbrOfPage); }


    return <div className="row mt-3 justify-content-center">

        <div className="col-1">
            <IconButton size='small' disabled={page === 1}
                onClick={handleFirstPage} ><FirstPageIcon />
            </IconButton>
        </div>

        <div className="col-1">
            <IconButton size='small' disabled={page === 1}
                onClick={handlePreviuosPage} ><NavigateBeforeIcon />
            </IconButton>
        </div>

        <div className="col-2">
            <Select
                styles={styles}
                options={options}
                value={options.find(option => option.value === page)}
                onChange={(value) => { HandlePage(value) }}
                theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: '#1e1e2f' } })}
            />
        </div>

        <div className="col-1">
            <IconButton size='small' disabled={page === nbrOfPage}
                onClick={handleNextPage}><NavigateNextIcon />
            </IconButton>
        </div>

        <div className="col-1">
            <IconButton size='small' disabled={page === nbrOfPage}
                onClick={handleLastPage} ><LastPageIcon />
            </IconButton>
        </div>

    </div>
}