import React from 'react';
import _ from 'underscore'
import { Chart, Line } from 'react-chartjs-2';
import { customStyles, getOptions as SelectOptions } from "../../views/Dashboard/components/AddModel/Controller";
import { FormControlLabel, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Select from 'react-select';
import DownsamplePlugin from 'chartjs-plugin-downsample';

import { ExportAs } from "../Exports/Exports";

const ObjectData = (matrix, idx1, idx2, target, checked, name) => {

    let LineData = [];

    for (let i = 1, len = matrix.length; i < len; ++i)
        if (name === "Features" || name === "Membership") {
            LineData.push({ x: i - 1, y: matrix[i][idx1] });
        }
        else if (name === "Response") {
            LineData.push({ x: matrix[i][idx1], y: Number(target[i - 1]) });
        }
        else {
            LineData.push({ 'x': matrix[i][idx1], 'y': matrix[i][idx2] });
        }

    return LineData;
};

let downsample = {
    enabled: true,
    threshold: 1000,
    auto: false,
    onInit: true,
    preferOriginalData: true,
    restoreOriginalData: false,
};

let gridLines = {
    drawBorder: false,
    color: "rgba(29,140,248,0.1)"
};

const LineChart = ({ name, matrix , Target, live, GadNic , RowNames}) => {

    const 
        tooltips = {
            enabled: true,
            bodySpacing: 4,
            xPadding: 12,
            intersect: 0,
            callbacks: {
                label: (tooltipItem, data) => { return  ( RowNames[tooltipItem.index ]  + (tooltipItem.index))  + '  : ' +  tooltipItem.yLabel || ''; },
                title: (tooltipItem, data) => { return data.datasets[tooltipItem[0].datasetIndex].label || ''; }
            },
        },
        liveOptions = {
            downsample: {
                enabled: true,
                threshold: (400),
                auto: false,
                onInit: true,
                preferOriginalData: true,
                restoreOriginalData: false,
            },
            hover: { mode: null },
            title: { display: false },
            scales: {
                xAxes: [{ scaleLabel: { display: false }, type: "linear", minRotation: 45, ticks: { beginAtZero: true } }],
                yAxes: [{ scaleLabel: { display: false } }]
            },
            legend: { display: false },
            tooltips: { enabled: false },
            animation: { duration: 0 },
            responsiveAnimationDuration: 0,
            line: { tension: 0 }
        },
        MarkerNames = matrix[0],
        ref = React.useRef(null),
        [checked, setChecked] = React.useState(false),
        [input, setInput] = React.useState(_.first(MarkerNames, 5)),
        [idx, setIdx] = React.useState({ S0: 0, S1: 1 });

    Chart.plugins.register(DownsamplePlugin);

    let 
        options = name === "2dScutter" ? SelectOptions(MarkerNames, []) : SelectOptions(MarkerNames, input),
        DataSet = [],
        ChartOptions = {},
        ChartData = [];

    if (name === "Sensitivity") {

        ChartData = ObjectData(matrix, idx.S0, idx.S1, Target, checked, name);

        DataSet.push({
            backgroundColor: '#3498db',
            borderColor: 'blue',
            pointBackgroundColor: 'blue',
            pointHoverBackgroundColor: 'blue',
            pointRadius: 1,
            pointHoverBorderColor: '#9a9a9a',
            data: ChartData
        });

        ChartOptions = {
            maintainAspectRatio: false,

            tooltips: tooltips,

            title: {
                display: true,
                text: 'AUC',
                fontColor: '#9a9a9a'
            },
            legend: {
                display: false
            },
            scales:
            {
                xAxes: [{
                    scaleLabel: { display: true, labelString: 'False positive rate', fontColor: '#9a9a9a' },
                    ticks: { fontColor: '#9a9a9a', beginAtZero: true },
                    type: 'linear',
                    gridLines: gridLines,
                }],
                yAxes: [{
                    scaleLabel: { display: true, labelString: 'True positive rate', fontColor: '#9a9a9a', lineHeight: 2.5 },
                    ticks: { fontColor: '#9a9a9a', beginAtZero: true, },
                    type: 'linear',
                    gridLines: gridLines,
                }]

            },
        }
    };

    if (name === "Prediction") {

        ChartData = ObjectData(matrix, idx.S0, idx.S1, Target, checked, name);

        // DataSet.push({
        //     backgroundColor: '#3498db',
        //     borderColor: 'red',
        //     pointBackgroundColor: 'red',
        //     pointHoverBackgroundColor: 'red',
        //     pointRadius: 1,
        //     pointHoverBorderColor: '#9a9a9a',
        //     data: Array.from(Array(matrix.length)).map((v, i) => { return { x: i, y: i } })
        // });

        DataSet.push({
            backgroundColor: '#3498db',
            pointBackgroundColor: '#3498db',
            pointHoverBackgroundColor: '#9a9a9a',
            pointHoverBorderColor: 'black',
            showLine: false,
            borderColor: '#3498db',
            pointRadius: 2,
            data: ChartData
        });

        ChartOptions = {
            maintainAspectRatio: false,

            tooltips: tooltips,

            title: {
                display: true,
                text: 'Predictions',
                fontColor: '#9a9a9a'
            },

            legend: {
                display: false
            },

            scales:
            {
                xAxes: [{
                    scaleLabel: { display: true, labelString: 'True Response', fontColor: '#9a9a9a' },
                    ticks: { fontColor: '#9a9a9a', beginAtZero: true },
                    type: 'linear',
                    gridLines: gridLines,
                }],
                yAxes: [{
                    scaleLabel: { display: true, labelString: 'Predicted Response', fontColor: '#9a9a9a', lineHeight: 2.5 },
                    ticks: { fontColor: '#9a9a9a', beginAtZero: true, },
                    type: 'linear',
                    gridLines: gridLines,
                }]

            },
        }
    };

    if (name === "Features" || name === "Membership" || name === "Response") {

        let color = [];

        if (name === "Membership") {
            DataSet.push({
                label: "Gad Nic",
                fill: false,
                backgroundColor: "blue",
                borderColor: "blue",
                borderWidth: 1,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: "blue",
                pointHoverBackgroundColor: "blue",
                pointHoverRadius: 2,
                pointHoverBorderWidth: 5,
                pointRadius: 1,
                data: Array.from(Array(matrix.length)).map((v, i) => { return { x: i, y: GadNic } })
            })
        };

        for (let i = 0, len = input.length; i < len; ++i) {
            let data = ObjectData(matrix, MarkerNames.indexOf(input[i]), 0, Target, false, name);
            color = (`hsl(${(MarkerNames.indexOf(input[i]) * 50)}, 75%, 50%)`);
            DataSet.push({
                label: input[i],
                fill: false,
                backgroundColor: color,
                borderColor: color,
                borderWidth: 1,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: color,
                pointHoverBackgroundColor: "white",
                pointHoverRadius: 2,
                pointHoverBorderWidth: 5,
                pointRadius: 2,
                showLine: name === "Response" ? false : true,
                data: data
            });
            ChartData[i] = data;
        };

        ChartOptions = {
            downsample: downsample,

            maintainAspectRatio: false,

            tooltips: tooltips,

            title: {
                display: true,
                text: name === "Membership" ? 'Membership Profile' : name === "Features" ? 'Features Profile' : "Response Plot",
                fontColor: '#9a9a9a'
            },

            legend: {
                display: true, position: 'right', labels: { fontColor: '#9a9a9a' },
                onClick: (e, legendItem) => { setInput(input => _.without(input, legendItem.text)) }
            },

            scales: {
                xAxes: [{
                    type: "linear",
                    scaleLabel: {
                        display: true,
                        labelString: name === "Response" ? "Variables" : "Individuals Number",
                        fontColor: '#9a9a9a',
                        fontSize: 16,
                        lineHeight: 2.5
                    },
                    ticks: {
                        padding: 20,
                        fontColor: "#9a9a9a",
                        autoSkip: true,
                        beginAtZero: true,
                        minRotation: 45,
                    },
                    gridLines: gridLines,
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: name === "Membership" ? 'Membership Degree' : name === "Features" ? 'Features' : "Response",
                        fontColor: '#9a9a9a',
                        fontSize: 16,
                        ineHeight: 2.5,
                        beginAtZero: true,
                    },
                    ticks: {
                        fontColor: '#9a9a9a',
                        padding: 20
                    },
                    gridLines: gridLines,
                }]
            },

        };
    };

    let CanvasChart = {
        data: canvas => { return { datasets: DataSet } },
        options: live ? liveOptions : ChartOptions
    };

    return <>
        {
            (live) ? null :
                    (name === "Features" || name === "Membership" || name === "Response" || name === "Prediction" || name === "Sensitivity") ?
                        <div className={(name === "Prediction" || name === "Sensitivity") ? 
                                        "row justify-content-end" : "row justify-content-end mr-5"}>
                            {
                                name === "Prediction" || name === "Sensitivity" ? null :
                                    <div className="col-3">
                                        <Select styles={customStyles}
                                            options={options}
                                            onChange={(value) => { if (value) setInput(input => [...input, value.value]) }}
                                            theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: '#1e1e2f' } })}
                                        />
                                    </div>
                            }

                            <div className={"col-1"}>
                                {ExportAs(name, matrix, ref)}
                            </div>

                        </div>
                        : null
        }

        <Line ref={ref} data={CanvasChart.data} options={live ? liveOptions : CanvasChart.options} /> </>
}

export default React.memo(LineChart);
