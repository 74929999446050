import React, { useState } from 'react';
import { Dialog, DialogContent, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReactTable from "react-table";
import "react-table/react-table.css";
import { HorizontalBar } from 'react-chartjs-2';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import _ from 'underscore';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { saveCsv } from "../../../Components/Exports/Exports";

const Table = ({ Model }) => {

  const
    [idx, setIdx] = useState(0),
    [open, setOpen] = useState(false),
    handleOpen = () => setOpen(true),
    handleClose = () => setOpen(false),
    handleIndex = (index) => setIdx(index);

  const {
    MADxp,
    outClass,
    initData,
    selectedHeader,
    IdxTest,
    outComeTest , problemType } = Model, 
    rowNames = [],
    labels = selectedHeader,
    chartData = { labels: labels, datasets: [{ backgroundColor: 'rgba(255,0,0)', data: MADxp[idx] }] }

  const options = {
    title: { display: true, text: 'Explainability', fontColor: 'white' },
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      xAxes: [{ barThickness: 5, scaleLabel: { display: true, labelString: 'Importance', fontColor: 'white' }, ticks: { fontColor: 'white' } }],
      yAxes: [{ barThickness: 5, scaleLabel: { display: true, labelString: 'Features', fontColor: 'white', lineHeight: 2.5 }, ticks: { fontColor: 'white' } }]
    },
  };


  const getDataSet = () => {

    let DataTest = [];
    let k = 0 ;

    for (let i = 1, len = initData.length; i < len; i++) {

      if (IdxTest[i - 1]) {
        let row = [];

        for (let j = 0, len = initData[0].length; j < len; j++) {
          if (j === 0) rowNames.push(initData[i][j]);
          else if ((selectedHeader.includes(initData[0][j]))) 
          row.push(initData[i][j]);
          else continue;
        };

        // DataTest.push(_.object(labels, row));
        DataTest.push({ predicted: outClass[k], outComeTest: outComeTest[k], Name: rowNames[k], ..._.object(labels, row)});

        k++ ;

      }
      else continue ;
    }
    return DataTest;
  };


  const HoverOptions = {
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      xAxes: [{ barThickness: 5, ticks: { fontColor: 'white' } }],
      yAxes: [{ barThickness: 5, ticks: { fontColor: 'white' } }]
    },
  };

  const getCols = () => {
    let columns = [
      {
        Header: props => <ListItem dense > <ListItemText primary='Names' /> </ListItem>,
        accessor: 'Name',
        style: { textAlign: 'left' },
        Cell: props => <ListItem dense > <ListItemText primary={props.value} /> </ListItem>
      },
      {
        Header: props => <ListItem dense > <ListItemText primary='Predicted' /> </ListItem>,
        accessor: 'predicted',
        style: { textAlign: 'left' },
        Cell: Model.problemType === "Regression" ?
          props => <ListItem dense> <ListItemText primary={props.value} /> </ListItem>
          :
          props => <ListItem button dense onClick={handleOpen}>
            <Tooltip style={{ borderRaduis: 0 }} title={<div style={{ height: '25vh', width: '15vh' }}>
              <HorizontalBar data={chartData} options={HoverOptions}/> </div>}>
              <ListItemText style={{ textDecoration: 'underline ' }} primary={props.value}/>
            </Tooltip>
          </ListItem>
      },
      {
        Header: props => <ListItem dense > <ListItemText primary='Target' /> </ListItem>,
        accessor: 'outComeTest',
        style: { textAlign: 'left' },
        Cell: props => <ListItem dense > <ListItemText primary={props.value} /> </ListItem>
      },
    ]

    for (let i = 0, len = labels.length; i < len; i++) {
      columns.push({
        Header: props => <ListItem dense><ListItemText primary={labels[i]} /></ListItem>,
        accessor: labels[i],
        style: { textAlign: 'left' },
        Cell: props => <ListItem dense > <ListItemText primary={props.value} /> </ListItem>
      })
    }

    return columns;
  };

  const getData = () => {
    let DataTest = getDataSet();
    let Data = [];
    for (let i = 0, len = outClass.length; i < len; i++)
      Data.push({ predicted: outClass[i], outComeTest: outComeTest[i], Name: rowNames[i], ...DataTest[i] })
    return Data;
  };

  return <>
    <ListItem dense>
      <ListItemText primary='' />
      <Tooltip title='export  to csv file' placement='top'>
        <IconButton onClick={() => { saveCsv("Build Table", getDataSet()) }}> <SystemUpdateAltIcon fontSize='small' /> </IconButton>
      </Tooltip>
    </ListItem>

    <ReactTable minRows={0}
      data={getDataSet()}
      columns={getCols()}
      defaultPageSize={outClass.length < 20 ? outClass.length : 20}
      style={{ margin: "auto", background: '#1e1e2f', height: "50vh", width: "67vw", border: '2px solid  #212121' }}
      resizable={false}
      className="-striped -highlight"
      showPagination={true}
      onRowClick={handleOpen}
      sortable={false}
      getTdProps={(state, rowInfo, column, instance) => {
        return { onMouseEnter: () => { if (column.id === 'predicted' && problemType !== "Regression" ) handleIndex(rowInfo.index) } };
      }}
    />

    <Dialog open={open} onClose={handleClose}>
      <DialogContent dividers style={{ background: '#1e1e2f' }}>

        <ListItem dense>
          <IconButton onClick={() => { saveCsv("MADxp Table", [labels, MADxp[idx]]) }}> <SystemUpdateAltIcon /> </IconButton>
          <ListItemText primary='' /> <IconButton onClick={handleClose} ><CloseIcon /></IconButton>
        </ListItem>
        <div style={{ height: '60vh' }}>
          <HorizontalBar
            style={{ minHeight: '60vh', maxHeight: '40vh', minWidth: '20vw', maxWidth: '20vw' }}
            data={chartData} options={options} /> </div>
      </DialogContent>
    </Dialog>
  </>
}
export default Table;