import React from "react";
import axios from 'axios';
import { getToken } from "./Utils/common";

const
    token = getToken(),
    path = process.env.REACT_APP_PATH,
    processPath = process.env.REACT_APP_PROCESS_PATH,
    clusterPath = process.env.REACT_APP_CLUSTER_PATH, //for later

    headers = { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token ,
        'xauthtoken': token
    },

    springHeaders = {
        'Content-Type': 'application/json',
        'x-auth-token': token
    },

    client = axios.create({
        baseURL: path,
        //timeout: 31000,
        headers: headers,
    }),

    processClient = axios.create({
        baseURL: processPath,
        //timeout: 31000,
        headers: springHeaders,
    }),

    clusterClient = axios.create({
        baseURL: clusterPath,
        //timeout: 31000,
        headers: springHeaders,
    }),

    tokenExpirationAlert = (history) => {
        localStorage.clear();
        alert("Token has been expired , Please sign in again");
        history.push('/sign-in');
    };


const helpers = {

    tokenExpirationAlert : (history) => tokenExpirationAlert(history),
    verifyToken: () =>  client.get("/verifyToken"),
    
    addName: (data) =>  client.post("/addName", data),
    addProblemType: (data) =>  client.post("/addProblemType", data),
    uploadFile: (data) =>  client.post("/uploadFile", data),
    fetchFiles: () =>  client.get("/files"),
    linkFileById: (modelId,fileId) =>  client.get(`/linkFile/${modelId}/${fileId}`),
    addData:  (data) =>  client.post("/addData", data),

    normalize: (data,modelId) =>  processClient.post(`/process/normalization/${modelId}`, data),
    validate: (data,modelId) =>  processClient.post(`/process/validation/${modelId}`, data),
    features: (data,modelId) =>  processClient.post(`/process/features/${modelId}`, data),
    build: (data,modelId) =>  processClient.post(`/process/build/${modelId}`, data),
    analyse2: (data,modelId) => processClient.post(`/process/analyse2/${modelId}`, data),
    deploy: (data,modelId) =>  processClient.post(`/process/deploy/${modelId}`, data),
    rebuild: (data,modelId) =>  processClient.post(`/process/rebuild/${modelId}`, data),

    cluster: (data,modelId) =>  clusterClient.post(`/process/cluster/${modelId}`, data),
    clusterAnalyse: (modelId) =>  processClient.post(`/process/cluster-analyse/${modelId}`,{}),

    models:  () =>  client.get(`/models`),
    apiModels:  () =>  client.get(`/apiModels`),

    sharableUsers:  (modelId) =>  client.get(`/sharableUsers/${modelId}`),

    usersSharing:  (data) =>  client.post(`/usersSharing` , data),
    emailSharing:  (data) =>  client.post(`/emailSharing` , data),

    showModel:  (modelId) =>  client.get(`/showmodel/${modelId}`),
    modelData:  (modelId) =>  client.get(`/model/${modelId}`),
    deleteModel : (modelId) =>  client.get(`/delete/${modelId}`),
    setFavorite : (modelId) =>  client.get(`/favorite/${modelId}`),
    swaggerModels : () =>  client.get(`/swaggerModels`),
    updateSwagger :  (data) =>  client.post("/updateswagger", data),
    addSwagger :  (data) =>  client.post("/addswagger", data),
    //apiDownload :  (modelId) =>  client.get(`/download/${modelId}`),
    viewNotifications :  () =>  client.get("/updateNotifications"),
    getNotifications :  (viewed) =>  client.get(`/notifications/${viewed}`),

}

export default helpers ;

