import React from 'react';

import { Line } from 'react-chartjs-2';
import {Col, Row} from "reactstrap";
import {ExportAs} from "../Exports/Exports";
import Alert from "../Alert/Alert";
import {live_options} from "../../views/Dashboard/components/AddModel/Controller";


const CustomLineChart = ({ state , setState , live }) => {

    const err_ref = React.useRef(null);
    const auc_ref = React.useRef(null);
    const [alert, setAlert] = React.useState({ alert: false , severity : "" , message: "" });

    console.log(state)

    const handleLineClick = async (element) => {
        setAlert({ alert: false , severity : "" , message: ""});
        if (element.length > 0) {

            if (state.N <= 100 ){
                await setState(prevState => ({ ...prevState, NbrOfVariables: element[0]._index + 1 }));
                setAlert({alert: true , severity : "success" , message : `Number of Features is set to ${element[0]._index + 1}` });
            }
            else {
                await setState(prevState => ({ ...prevState, NbrOfVariables: element[0]._chart.tooltip._data.datasets[0].data[element[0]._index].x }));
                setAlert({alert: true , severity : "success" , message : `Number of Features is set to ${element[0]._chart.tooltip._data.datasets[0].data[element[0]._index].x}` });
            }
        }
    };

    const getLineOptions = (type) => {
        let Line_Options = {
            title: { display: true, text: type === "auc" ? 'Area Under the Curve' : 'Classification Error', fontColor: '#9a9a9a' },
            legend: { display: false, labels: { fontColor: '#9a9a9a' } },
            maintainAspectRatio: false,
            scales:
                {
                    xAxes: [{
                        scaleLabel: { display: true, labelString: ' Number of Features', fontColor: '#9a9a9a' },
                        type: state.N <= 100 ? "category" : 'logarithmic',
                        ticks: {
                            padding: 20,
                            fontColor: "#9a9a9a",
                            autoSkip: false, stepSize: 1,
                            callback: function (value) {
                                return state.N <= 100 ? value : Number(value.toString());
                            }
                        },
                        barPercentage: 1.6,
                        gridLines: {
                            drawBorder: false,
                            color: "rgba(29,140,248,0.1)",
                        },
                    }],
                    yAxes: [{
                        scaleLabel: { display: true, labelString: type === "auc" ? 'AUC' : "Error ", fontColor: '#9a9a9a', lineHeight: 2.5 },
                        barPercentage: 1,
                        gridLines: {
                            drawBorder: false,
                            color: "rgba(29,140,248,0.0)",
                        },
                        ticks: {
                            padding: 20,
                            fontColor: "#9a9a9a"
                        }

                    }]
                },
            tooltips: {
                enabled: true,
                backgroundColor: "#f5f5f5",
                titleFontColor: "#333",
                bodyFontColor: "#666",
                bodySpacing: 4,
                xPadding: 12,
                mode: "nearest",
                intersect: 0,
                position: "nearest",
                callbacks: {
                    label: (tooltipItem) => {
                        let label = ' (' + tooltipItem.xLabel + ' , ' + tooltipItem.yLabel + ')';
                        return label;
                    },
                    title: function () { return; }
                }
            }
        }
        return Line_Options
    };

    let AUCData = {
        data: canvas => {
            let ctx = canvas.getContext("2d");
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
            gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
            gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
            gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
            return state.N <= 100 ? {
                labels: state.F,
                datasets: [
                    {
                        label: "AUC",
                        fill: true,
                        backgroundColor: gradientStroke,
                        borderColor: "#1f8ef1",
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: "#1f8ef1",
                        pointBorderColor: "rgba(255,255,255,0)",
                        pointHoverBackgroundColor: "#1f8ef1",
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: state.Auc
                    }
                ]
            } : {
                datasets: [
                    {
                        label: "AUC",
                        fill: true,
                        backgroundColor: gradientStroke,
                        borderColor: "#1f8ef1",
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: "#1f8ef1",
                        pointBorderColor: "rgba(255,255,255,0)",
                        pointHoverBackgroundColor: "#1f8ef1",
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: state.F.map((item, index) => { return { x: item, y: state.Auc[index] } })
                    }
                ]
            };
        },
        options: getLineOptions('auc')
    };

    let ErrorData = {
        data: canvas => {
            let ctx = canvas.getContext("2d");
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
            gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
            gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
            gradientStroke.addColorStop(0, "rgba(29,140,248,0)");

            return state.N <= 100 ? {
                labels: state.F,
                datasets: [
                    {
                        label: "ERR",
                        fill: true,
                        backgroundColor: gradientStroke,
                        borderColor: "#1f8ef1",
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: "#1f8ef1",
                        pointBorderColor: "rgba(255,255,255,0)",
                        pointHoverBackgroundColor: "#1f8ef1",
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: state.Err
                    }
                ]
            } : {
                labels: state.F,
                datasets: [
                    {
                        label: "ERR",
                        fill: true,
                        backgroundColor: gradientStroke,
                        borderColor: "#1f8ef1",
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: "#1f8ef1",
                        pointBorderColor: "rgba(255,255,255,0)",
                        pointHoverBackgroundColor: "#1f8ef1",
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: state.F.map((item, index) => { return { x: item, y: state.Err[index] } })
                    }
                ]
            }
        },
        options: getLineOptions('err')
    };

    return  <>
        {
            live ?
                <Line data={ErrorData.data} options={state.N <= 100 ? live_options("category") : live_options("logarithmic")} />
                :
                <Row className={"justify-content-sm-center"} style={{ maxHeight: '95vh', maxWidth: '95vw' }}>
                    <Col xs="10">
                        <div style={{ height: '35vh' }}>
                            <div className={"float-right"}>{ExportAs("Error", state.F.map((item, index) => { return { x: item, y: state.Err[index] } }), err_ref)}</div>
                            <Line getElementAtEvent={(element) => handleLineClick(element)} ref={err_ref} data={ErrorData.data} options={ErrorData.options} />
                        </div>
                        <br/>
                        <div style={{ height: '35vh' }}>
                            <div className="float-right">{ExportAs("AUC", state.F.map((item, index) => { return { x: item, y: state.Auc[index] } }), auc_ref)}</div>
                            <Line getElementAtEvent={(element) => handleLineClick(element)} ref={auc_ref} data={AUCData.data} options={AUCData.options} />
                        </div>
                    </Col>

                    <Alert alert = {alert} setAlert= {setAlert }  />
                    
                </Row>
        }
    </>

}


export default React.memo(CustomLineChart);
