import React, {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {
    Grid,
    Button,
    TextField,
    Link,
    Typography
} from '@material-ui/core';
import axios from "axios";

const schema = {
    name: {
        presence: {allowEmpty: false, message: 'is required'},
        length: {
            maximum: 32
        }
    },
    username: {
        presence: {allowEmpty: false, message: 'is required'},
        length: {
            maximum: 32
        }
    },
    email: {
        presence: {allowEmpty: false, message: 'is required'},
        email: true,
        length: {
            maximum: 64
        }
    },
    password: {
        presence: {allowEmpty: false, message: 'is required'},
        length: {
            maximum: 128
        }
    }

};

const useStyles = makeStyles(theme => ({
    root: {
        
        height: '100%'
    },
    grid: {
        height: '100%'
    },
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    quote: {
        backgroundColor: theme.palette.neutral,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/loginpage.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px'
    },
    quoteText: {
        color: theme.palette.white,
        fontWeight: 300
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white
    },
    bio: {
        color: theme.palette.white
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    logoImage: {
        marginLeft: theme.spacing(4)
    },
    contentBody: {
        margin : "auto",
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: { justifyContent: 'center'}
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(5),
        color : "white"
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    policy: {
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center'
    },
    policyCheckbox: {
        marginLeft: '-14px'
    },
    signUpButton: {
        margin: theme.spacing(2, 0)
    }
}));

const SignUp = props => {
  
    const {history, url} = props;

    const classes = useStyles();

    const [formState, setFormState] = useState({
        name: "",
        username: "",
        password: "",
        email: "",
        organization: ""
    });

    const handleChange = event => {
        setFormState({
            ...formState,
            [event.target.name]: event.target.value
        });
    };
    const [error, setError] = useState(null);

    const handleSignUp = event => {
        event.preventDefault();
        axios.post(url + '/User', formState).then(response => {

            if (response.data.token == undefined) {

                setError(response.data.message)
            } else {
                setError("Please check your email to activate the account !")
            }

        }).catch(error => {

        });
    };


    return (
        <div className={classes.root}>
            <Grid className={classes.grid} container>
                <Grid className={classes.quoteContainer} item lg={7} >
                    <div className={classes.quote}>
                        <div className={classes.quoteInner}>
                            <Typography className={classes.quoteText} variant="h1" > </Typography>
                            <div className={classes.person}>
                                <Typography className={classes.name} variant="body1"> </Typography>
                                <Typography className={classes.bio} variant="body2"> </Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid className={classes.content} item lg={5} xs={12} >
                    <div className={classes.content}>
                        <div className={classes.contentBody}>
                            <form className={classes.form} onSubmit={handleSignUp} >
                                <Typography className={classes.title} variant="h4" >
                                    	Sign Up
                                </Typography>

                                <Typography color="textSecondary">
                                    Use your email to create new account
                                </Typography>

                                <div> {error && <><small style={{color: 'red'}}>{error}</small><br/></>}<br/></div>

                                <TextField
                                    className={classes.textField}
                                    fullWidth
                                    label="First name"
                                    name="name"
                                    onChange={handleChange}
                                    type="text"
                                    value={formState.name || ''}
                                    variant="outlined"
                                    size="small"
                                />
                                <TextField
                                    className={classes.textField}
                                    fullWidth
                                    label=" Username"
                                    name="username"
                                    onChange={handleChange}
                                    type="text"
                                    value={formState.username || ''}
                                    variant="outlined"
                                    size="small"
                                />
                                <TextField
                                    className={classes.textField}
                                    fullWidth
                                    label="Email address"
                                    name="email"
                                    onChange={handleChange}
                                    type="text"
                                    value={formState.email || ''}
                                    variant="outlined"
                                    size="small"
                                />
                                <TextField
                                    className={classes.textField}
                                    fullWidth
                                    label="Organization"
                                    name="organization"
                                    onChange={handleChange}
                                    type="text"
                                    value={formState.organization || ''}
                                    variant="outlined"
                                    size="small"
                                />
                                <TextField
                                    className={classes.textField}
                                    fullWidth
                                    label="Password"
                                    name="password"
                                    onChange={handleChange}
                                    type="password"
                                    value={formState.password || ''}
                                    variant="outlined"
                                    size="small"
                                />
                                <div className={classes.policy}>

                                    <Typography className={classes.policyText} color="textSecondary" variant="body1" >
                                        I have read the{' '}
                                        <Link
                                            color="primary"
                                            component={RouterLink}
                                            to="#"
                                            underline="always"
                                            variant="h6"
                                        >
                                            Terms and Conditions
                                        </Link>
                                    </Typography>
                                </div>

                                <Button
                                    className={classes.signUpButton}
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    onclick={handleSignUp}
                                >
                                    Sign up now
                                </Button>
                                <Typography
                                    color="textSecondary"
                                    variant="body1"
                                >
                                    Have an account?{' '}
                                    <Link
                                        component={RouterLink}
                                        to="/signin"
                                        variant="h6"
                                    >
                                        Sign in
                                    </Link>
                                </Typography>
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};


export default SignUp;
