import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { Drawer, AppBar, CssBaseline, Divider, IconButton } from '@material-ui/core';
import { Paper, Hidden, Typography, Toolbar, Tooltip } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Prediction from './Steps/Prediction';
import { Progress } from '../../Components';
import { getToken, getUser, getRole } from "../../Utils/common";
import InboxIcon from '@material-ui/icons/MoveToInbox';
import SendIcon from '@material-ui/icons/Send';
import AddIcon from '@material-ui/icons/Add';
import ShareIcon from '@material-ui/icons/Share';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import InitSetUp from './Steps/Step1';
import TrainingResult from './Steps/Step2';
import WeightTable from './Steps/Step3';
import ArchDiagram from './Steps/Step4';
import SideBar from './SideBar';
import _ from 'underscore';
import clsx from 'clsx';
import axios from 'axios';

import helpers from '../../api';
import {Alert} from "../../Components";

const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    background: 'blue'
  },
  appBar: {
    width: '95%',
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.down('xs')]: { width: '100%', },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "#27293d"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth + 5}px)`,
    [theme.breakpoints.down('xs')]: { width: 0, },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 8
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    maxHeight: '90%',
    background: "#27293d"
  },
  drawerOpen: {
    width: drawerWidth,
    [theme.breakpoints.down('xs')]: { width: '100%' },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "#27293d"
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.down('xs')]: { width: 0, },
    background: "#27293d"
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: `${theme.spacing(4)}px  ${theme.spacing(1)}px  `,
    background: "#1e1e2f"
  },
  title: {
    flex: 1, marginLeft: 10
  },
  modelInfo: {
    justifyContent: 'flex-start', [theme.breakpoints.down('xs')]: { overflowX: 'visible', marginTop: 10 },
  },
  empty: {
    paddingTop: 150,
    display: "flex",
    margin: "auto",
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const Theme = createMuiTheme({ palette: { type: 'dark' } });

const ShowModel = ({history, match, url }) => {

  const
    path = url,
    classes = useStyles(),
    [Model, setModel] = useState({}),
    [error, setError] = useState(null),
    [step, setStep] = useState('Step1'),
    [type, setType] = useState('Training'),
    [Data, setData] = useState([]),
    [OutClassPr, setOutClassPr] = useState([]),
    [MadXpPr, setMadXpPr] = useState([]),
    [enablePr, setEnablePr] = useState(true),
    [alert, setAlert] = useState({ alert: false, message: "", severity: "info" });


  const
    [open, setOpen] = useState(false),
    handleDrawer = () => setOpen(!open),
    handleStep = (e) => setStep(e.currentTarget.dataset.value),
    handleType = (e) => setType(e.currentTarget.dataset.value);

  const
    [open_action, setOpen_action] = useState({ O1: false, O2: false, O3: false, O4: false }),
    handleOpen_action = (e) => setOpen_action(prevState => ({ ...prevState, [e.currentTarget.dataset.value]: true })),
    handlesend = (e) => window.open(`${process.env.REACT_APP_PROCESS_PATH}/swagger/swagger.html`);
    

  const
    token = getToken(),
    id = match.params.id;

  const fetchData = async (id) => {

    helpers.showModel(id).then((response) => { 
      setModel(response.data);
   })
     .catch((err) => {
       console.log(err);
       err.response.status === 401 ?
       helpers.tokenExpirationAlert(history) :
         setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
     });
  };

  useEffect(() => { 
    helpers.showModel(id).then((response) => { 
      setModel(response.data);
   })
     .catch((err) => {
       console.log(err);
       err.response.status === 401 ?
       helpers.tokenExpirationAlert(history) :
         setAlert({ alert: true, message: 'Error occured , Please try again', severity: "error" });
     });
   }, [history,id]);


  if (_.isEmpty(Model))
    return <MuiThemeProvider theme={Theme}>

      {error ?
        <div className={classes.empty} > <Typography> {error}</Typography> </div>
        :
        <Progress/>
      }

    </MuiThemeProvider>

  else
    return <MuiThemeProvider theme={Theme}>

      <div className={classes.root}>

        <CssBaseline/>

        <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open })} >

          <Toolbar variant='dense' style={{ background: "#27293d" }}>
            <Hidden smUp>
              <IconButton color="inherit" aria-label="open drawer"
                onClick={handleDrawer} edge="start" className={clsx(classes.menuButton, { [classes.hide]: open })}>
                <MenuIcon />
              </IconButton>
            </Hidden>

            <Link to={'/'} > <IconButton size='small'> <ArrowBackIcon /> </IconButton>  </Link>

            <p className={classes.title}> {Model.name} </p>

            <SideBar 
              path={path} 
              idmodel={Model._id} 
              Model={Model} 
              Data={Data} 
              setData={setData} 
              handlesend={handlesend}
              setOutClassPr={setOutClassPr} 
              setMadXpPr={setMadXpPr} 
              setType={setType} 
              setEnablePr={setEnablePr}
              open={open_action} 
              setOpen={setOpen_action} 
              handleOpen={handleOpen_action}
              setAlert={setAlert}
            />
          </Toolbar>

          <Paper square style={{ MarginBottom: 10, background: "#27293d" }}>
            <Toolbar variant='dense' >
              <ListItem dense selected={type === 'Training'} style={{ width: 200, minWidth: 100, marginRight: 10 }} button onClick={handleType} data-value="Training"  ><ListItemText primary="Training" /></ListItem>
              <Divider orientation='vertical' />
              <ListItem dense selected={type === 'Prediction'} style={{ width: 200, minWidth: 100, marginLeft: 10 }} button onClick={handleType} data-value="Prediction" disabled={enablePr}><ListItemText primary="Predictions" /></ListItem>
            </Toolbar>
          </Paper>
        </AppBar>

        <Drawer variant="permanent" className={clsx(classes.drawer,
          { [classes.drawerOpen]: open, [classes.drawerClose]: !open, })}

          classes={{ paper: clsx({ [classes.drawerOpen]: open, [classes.drawerClose]: !open }) }}>

          <div className={classes.toolbar}>
            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawer}
              edge="start" className={classes.menuButton}>
              {open ? <ChevronLeftIcon /> : <MenuIcon />} </IconButton>
          </div>

          <Divider />

          <List>
            <BootstrapTooltip title="Run" placement="right">
              <ListItem button onClick={handleOpen_action} data-value="O1">
                <ListItemIcon><AddIcon /></ListItemIcon>
                <ListItemText primary="Run" />
              </ListItem>
            </BootstrapTooltip>

            <BootstrapTooltip title="Generate API" placement="right">
              <ListItem button onClick={handleOpen_action} data-value="O2">
                <ListItemIcon><SendIcon /></ListItemIcon>
                <ListItemText primary="Generate API" />
              </ListItem>
            </BootstrapTooltip>


            {getUser().admin || getRole().permission.sharemodel ?
              <BootstrapTooltip title="Share" placement="right">
                <ListItem button onClick={handleOpen_action} data-value="O3">
                  <ListItemIcon><ShareIcon /></ListItemIcon>
                  <ListItemText primary="Share" />
                </ListItem>
              </BootstrapTooltip> : null}

            <BootstrapTooltip title="Show All API's" placement="right">
              <ListItem button onClick={handlesend}>
                <ListItemIcon><InboxIcon /></ListItemIcon>
                <ListItemText primary="Show All API's" />
              </ListItem>
            </BootstrapTooltip>
          </List>
          <Divider />
        </Drawer>

        <Divider />

        <main className={classes.content}>
          {type === 'Training' ?
            <>

              <>
                <Toolbar className={classes.modelInfo} variant='dense'>
                  <ListItem style={{ minWidth: 100 }} dense  >
                    <ListItemText primary='Training Time' secondary={Model.trainDuration} /> |
                  </ListItem>
                  <ListItem style={{ minWidth: 100 }} dense  >
                    <ListItemText primary='Algorithm' secondary={
                      Model.trainingMethod === "LAMDA" ? "LAMDA" : Model.trainingMethod === "kNN" ? 'k-Nearest Neighbors' : 'Support Vector Machine'
                    } /> |
                  </ListItem>
                  <ListItem style={{ minWidth: 100 }} dense  >

                    {Model.problemType !== 'Regression' ?

                      <ListItemText primary='Accuracy' secondary={(100 - (Model.ACC * 100)).toFixed(2) + '%'} />
                      :
                      <ListItemText primary='MSE' secondary={Model.MSE.toFixed(2)} />
                    }
                    |
                  </ListItem>
                  <ListItem style={{ minWidth: 100 }} dense  >
                    {Model.problemType !== 'Regression' ?
                      <ListItemText primary='AUC' secondary={(Model.AUC)} />
                      :
                      <ListItemText primary='MAE' secondary={(Model.MAE)} />

                    }
                  </ListItem>
                </Toolbar>

                <Divider />

                <Toolbar className={classes.modelInfo} variant='dense'>
                  <ListItem data-value="Step1" selected={step === 'Step1'} onClick={handleStep} dense button ><ListItemText primary='Initial Setup' /> </ListItem>
                  <ListItem data-value="Step2" selected={step === 'Step2'} onClick={handleStep} dense button ><ListItemText primary='Training Results' /></ListItem>
                  <ListItem data-value="Step3" selected={step === 'Step3'} onClick={handleStep} dense button ><ListItemText primary='Feature Importance' /></ListItem>
                  <ListItem data-value="Step4" selected={step === 'Step4'} onClick={handleStep} dense button ><ListItemText primary='Model Architecture Diagram ' /></ListItem>
                </Toolbar>

                <Divider />
              </>

              <>
                {step === 'Step1' ? <InitSetUp      id={id} path={path} token={token} Model={Model} /> : null}
                {step === 'Step2' ? <TrainingResult id={id} path={path} token={token} Model={Model} /> : null}
                {step === 'Step3' ? <WeightTable    id={id} path={path} token={token} Model={Model} /> : null}
                {step === 'Step4' ? <ArchDiagram    id={id} path={path} token={token} Model={Model} fetchData = {fetchData} /> : null}
              </>
            </>
            :
            <Prediction OutClassPr={OutClassPr} SelectedHeader={Model.selectedHeader} MadXpPr={MadXpPr} Data={Data} />
          }

        </main>

        <Alert alert={alert} setAlert={setAlert} />

      </div>

    </MuiThemeProvider>

}
export default (ShowModel);