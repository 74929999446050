import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { List } from "react-virtualized";
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref,
  width
) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 36;

  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={250}
          width={300}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={props => {
            return React.cloneElement(children[props.index], {
              style: props.style
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});


const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderColor: 'blue',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'blue',
      },
    },
  },
})(TextField);

export default
  function VirtualizedAutocomplete({ options, handleChange, title , width }) {
  return (
    <Autocomplete
      id="virtualize-demo"
      style={{ width: width ? width : 300 }}
      size={"small"}
      disableListWrap
      ListboxComponent={ListboxComponent}
      options={options}
      onChange={(ev, value) => {
        handleChange(ev, value);
      }}
      renderInput={params => (
        <CssTextField
          {...params}
          variant="outlined"
          label={title}
          fullWidth
        />
      )}
      PaperComponent={({ children }) => (
          <Paper style={{ background: "#1e1e2a" }}>{children}</Paper>
        )}
    />
  );
}

