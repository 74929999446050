import React from 'react';
import FileSaver from "file-saver";
import { ExportToCsv } from 'export-to-csv'; 
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'; 
import MoreVertIcon from '@material-ui/icons/MoreVert';

export const saveImage = async (ref, name) => {
    let current = ref.current;
    let Data = { ...current, props: { options: { title: { fontColor: "black" } } } }
    const canvas = await Data.chartInstance.canvas;
    await canvas.toBlob(function (blob) {
        FileSaver.saveAs(blob, name + ".jpg");
    });
};

export const saveCsv = async (name, data) => {
    const exoptions = {
        filename: name,
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
    };
    const csvExporter = new ExportToCsv(exoptions);
    csvExporter.generateCsv(data);
};

export const ExportAs = ( name , data , ref ) =>{

    return(
        <PopupState anchorOrigin={{ vertical: 'top', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'right' }} variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
            <React.Fragment>

            <IconButton {...bindTrigger(popupState)} > <MoreVertIcon fontSize='small'/></IconButton>
            
            <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => { saveCsv(name , data ); }} >Export as CSV </MenuItem>
                <MenuItem onClick={() => { saveImage(ref, name);  }}>Export as JPG</MenuItem>
            </Menu>
            </React.Fragment>
        )}
        </PopupState>
  )
};