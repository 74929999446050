import React, { useState } from 'react';
import { withContext } from '../../../../../../context';
import {Dialog, ListItemText, IconButton, ListItem, FormControl, RadioGroup, FormControlLabel, Divider} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import PcaChart from './VisualizationGrpahs/PcaChart';
import TextHistoChart from './VisualizationGrpahs/TextHistoChart';
import NumHistoChart from './VisualizationGrpahs/NumHistoChart';
import TwoDScatter from './VisualizationGrpahs/TwoDScatterChart';
import FeatureProfileChart from './VisualizationGrpahs/FeatureProfileChart';
import { live_options } from '../../Controller';
import { ExportAs } from "../../../../../../Components/Exports/Exports";

import {CheckBox, LineChart} from "../../../../../../Components";
import {Col, Row} from "reactstrap";

const useStyles = makeStyles(theme => ({
	dialogPaper: { minHeight: '95vh', maxHeight: '95vh', minWidth: '95vw', maxWidth: '95vw', backgroundColor: "#1e1e2f" },
	root: {
		padding: 10, maxHeight: '70vh', overflow: 'auto',
		[theme.breakpoints.down('sm')]: { maxHeight: '50vh', overflow: 'auto' }
	},
	gridListele: { border: '2px solid  #212121', margin: 15 },
	label: {
		fontSize: 12,
		fontFamily: 'sans-serif',
		fontStyle: 'normal',
	},
}));

const Visualization = ({ state , setState}) => {

	const classes = useStyles();
	const [open, setOpen] = useState({ O1: false, O2: false, O3: false, O4: false, O5: false });
	const handleOpen = (e) => setOpen({ ...open, [e.currentTarget.dataset.value]: true });
	const handleClose = () => setOpen({ O1: false, O2: false, O3: false, O4: false, O5: false });

	const [checked, setChecked] = useState("Absolute");
	const handleCheck = (e) => setChecked(e.target.value);

	return <>
		<ListItemText primary='Visualization plots' secondary="PcaChart , feature profile , class profile and 2D scatter plot"/>

		<Divider/>
		<Row className={"justify-content-center"}>
			<Col className="text-center text-md-left">
			<FormControl component="fieldset">
					<RadioGroup row value={checked} onChange={handleCheck}>
						<FormControlLabel classes={{ label: classes.label }} value="Absolute" control={<CheckBox />} label="Absolute Values" />
						<FormControlLabel classes={{ label: classes.label }} value="Normalized" control={<CheckBox />} label="Normalized Values" />
					</RadioGroup>
				</FormControl>
			</Col>
		</Row>
		<Divider/>

		<div className={classes.root}>
			<GridList cellHeight={160} className={classes.gridList} cols={3.5}>
				{state.MarkerNamesNum.length > 1 ?
					<GridListTile data-value="O1" className={classes.gridListele} key={1} onClick={(e) => handleOpen(e)}>
							<PcaChart
								PCAResult1={checked === "Absolute"? state.PCAResultOrg1 : state.PCAResult1}
								PCAResult2={checked === "Absolute"? state.PCAResultOrg2 : state.PCAResult2}
								PCAScore1 ={checked === "Absolute"? state.ScoreOrg1 : state.Score1}
								PCAScore2 ={checked === "Absolute"? state.ScoreOrg2 : state.Score2}
								RowNames={state.RowNames}
								Target = {state.Target}
								ProblemType = {state.ProblemType}
								MarkerNamesNum={state.MarkerNamesNum}
								setState = {setState} 
								live = {true}
							/>
					<GridListTileBar title="Principal Component Analysis" style={{ textAlign: "center" }}/> </GridListTile>
					: null}

				{state.MarkerNamesNum.length !== 0 ?
					<GridListTile data-value="O2" className={classes.gridListele} key={2} onClick={(e) => handleOpen(e)} >
						<FeatureProfileChart
							matrix={checked === "Absolute"? [state.MarkerNamesNum,...state.NumData]: state.NormalizedNumData}
							Target={state.Target}
							live = {true} 
							ProblemType = {state.ProblemType}
							RowNames={state.RowNames}
						/>
						<GridListTileBar title="Features Profile" style={{ textAlign: "center" }}/> </GridListTile>
					: null}

				{state.MarkerNamesNum.length !== 0 ?
					<GridListTile data-value="O3" className={classes.gridListele} key={3} onClick={(e) => handleOpen(e)} >
						{
							state.ProblemType === "Regression" ? 
							<LineChart
									name ="Response"
									matrix={checked === "Absolute"? [state.MarkerNamesNum,...state.NumData]: state.NormalizedNumData}
									Target={state.Target}
									live = {true} 
									ProblemType = {state.ProblemType}
									RowNames={state.RowNames}
								/>
							:
							<NumHistoChart
								matrix={checked === "Absolute"? state.NumData: state.NormalizedNumData.slice(1)}
								Target={state.Target}
								MarkerNamesNum={state.MarkerNamesNum} 
								live = {true}
								live_options = {live_options}
							/>
						}
						<GridListTileBar title= {state.ProblemType === "Regression"  ? "Response Plot" :"Class Profile"} style={{ textAlign: "center" }} /> </GridListTile>
					: null}

				{state.MarkerNamesNum.length > 1 ?
					<GridListTile data-value="O4" className={classes.gridListele} key={4} onClick={(e) => handleOpen(e)} >
						<TwoDScatter
							name = "2dScutter"
							matrix={checked === "Absolute"? [state.MarkerNamesNum,...state.NumData]: state.NormalizedNumData}
							Target={state.Target}
							live = {true} 
							ProblemType = {state.ProblemType}
							RowNames={state.RowNames}
						/>
						<GridListTileBar title="2D Scatter Plot" style={{ textAlign: "center" }} /> </GridListTile>
					: null}

				{state.MarkerNamesTxt.length !== 0 ?
					<GridListTile data-value="O5" className={classes.gridListele} key={5} onClick={(e) => handleOpen(e)} >
						<TextHistoChart  
							MarkerNamesTxt={state.MarkerNamesTxt} 
							Target={state.Target} 
							TextData={state.TextData} 
							live = {true}
							live_options = {live_options}
						/>
						<GridListTileBar title="Qualitative Profile"  style={{ textAlign: "center" }} /> </GridListTile>
					: null}
			</GridList>
		</div>

		<Dialog fullWidth  classes={{ paper: classes.dialogPaper }} open={open.O1 || open.O2 || open.O3 || open.O4 || open.O5} onClose={handleClose}>
			<ListItem dense> <ListItemText primary=''/> 
				<IconButton onClick={handleClose}>
					<CloseIcon/>
				</IconButton>
			</ListItem>
			<div style={{ display: 'block' , marginLeft: 'auto' , marginRight: 'auto' ,textAlign : "right", height: '80vh', width: '80vw'  }}>
					{
						open.O1 ? 
							<PcaChart
								PCAResult1={checked === "Absolute"? state.PCAResultOrg1 : state.PCAResult1}
								PCAResult2={checked === "Absolute"? state.PCAResultOrg2 : state.PCAResult2}
								PCAScore1 ={checked === "Absolute"? state.ScoreOrg1 : state.Score1}
								PCAScore2 ={checked === "Absolute"? state.ScoreOrg2 : state.Score2}
								RowNames={state.RowNames}
								Target = {state.Target}
								ProblemType = {state.ProblemType}
								MarkerNamesNum={state.MarkerNamesNum}
								setState = {setState} 
								ExportAs = {ExportAs}
								live = {false}
							/> 
						: open.O2 ?
							<FeatureProfileChart
								matrix={checked === "Absolute"? [state.MarkerNamesNum,...state.NumData]: state.NormalizedNumData}
								Target={state.Target}
								live = {false} 
								ProblemType = {state.ProblemType}
								RowNames={state.RowNames}
							/>
					 	: open.O3 ?

						 	state.ProblemType === "Regression"  ? 
							 <LineChart
								name ="Response"
								matrix={checked === "Absolute"? [state.MarkerNamesNum,...state.NumData]: state.NormalizedNumData}
								Target={state.Target}
								live = {false} 
								ProblemType = {state.ProblemType}
								RowNames={state.RowNames}
							/>
							:
							<NumHistoChart
								ExportAs = {ExportAs}
								matrix={checked === "Absolute"? state.NumData: state.NormalizedNumData.slice(1)}
								Target={state.Target}
								MarkerNamesNum={state.MarkerNamesNum}
							/>
							
							: open.O4 ?
							<TwoDScatter
									matrix={checked === "Absolute"? [state.MarkerNamesNum,...state.NumData]: state.NormalizedNumData}
									Target={state.Target}
									live = {false}
									ProblemType = {state.ProblemType}
									RowNames={state.RowNames}
							/>
										
							: open.O5 ?
								<TextHistoChart
									MarkerNamesTxt={state.MarkerNamesTxt}
									Target={state.Target}
									TextData={state.TextData}
									ExportAs = {ExportAs}
								/>
						: null
					}
				
				</div>	
		</Dialog>

	</>
}
export default React.memo(withContext(Visualization));



			
