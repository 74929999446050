import React, { useState } from 'react';
import {
  FormGroup, Divider, FormControlLabel, Toolbar, Button,
  ListItem, ListItemText, Checkbox, Hidden, DialogContent,
  DialogActions, Dialog, DialogTitle, IconButton, TextField
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { ExcelRenderer } from 'react-excel-renderer';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';
import { withContext } from '../../../../../../context';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { SelectForm, InputForm } from "../../../../../../Components";
import { Alert, Progress } from '../../../../../../Components';
import { Row, Collapse } from "reactstrap";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import fetch from "cross-fetch";

import helpers from "../../../../../../api"

const useStyles = makeStyles(theme => ({
  dropzone: {
    borderRadius: 5,
    border: '2px dashed white',
    padding: '30px 0px',
    height: 150,
    background: '#1e1e2f',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    width: 400,
    overflow: 'auto',
    borderColor: '#FFFAFA',
    textAlign: 'center',
  },
  inputFile1: {
    fontSize: 30,
    height: 150,
    width: 300,
    position: "absolute",
    opacity: 0
  },
  label: {
    fontSize: 15,
    fontFamily: 'sans-serif',
    fontStyle: 'normal',
  },
  db: {
    width: 400, [theme.breakpoints.down('sm')]: { width: '100%' }
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
  },

}));

const UploadData = ({ state, setState, disabled, setFile, token, setExist }) => {

  const
    classes = useStyles(),

    [dis, setDis] = useState(disabled),

    [dbInfo, setDbInfo] = useState({
      dbtype: 'mongodb',
      port: 27017,
      host: 'localhost',
      username: '',
      password: '',
      dbname: '',
      table: ''
    }),

    [error, setError] = useState(null),

    [loading, setLoading] = useState(false),

    [alert, setAlert] = useState({ alert: false, severity: "info", message: "" }),

    dbtypesOptions = [
      {
        label: "mongodb",
        value: "mongodb"
      },
      {
        label: "mysql",
        value: "mysql"
      },
    ],

    [open, setOpen] = React.useState(false),

    handleClick = () => setOpen(!open),

    [ShareData, setShareData] = useState(false),

    [options, setOptions] = useState([]),

    handleDB = async () => {
      setError(null);
      setLoading(true);

      const res = await fetch(`${state.path}/datafromDB`, {
        method: 'POST', mode: 'cors',
        body: JSON.stringify({
          type: dbInfo.dbtype,
          host: dbInfo.host,
          port: dbInfo.port,
          username: dbInfo.username,
          password: dbInfo.password,
          dbname: dbInfo.dbname,
          table: dbInfo.table,
        }),
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
      });

      if (res.status === 200) {
        const json = await res.json();
        setState(prevState => ({ ...prevState, Data: json }));
        handleClick();
      }
      else if (res.status === 402) setError("please check your table or collection name")
      else if (res.status === 403) setError("Your data is empty , please insert a valid data")
      else if (res.status === 401) setError("please check your database information")
      else setError("Somthinh went wrong, please try again");
      setLoading(false);
    },

    handleUpload = (e) => {
      if (!e || !e.target || !e.target.files || e.target.files.length === 0) return;

      else {
        setLoading(true);
        setState(prevState => ({ ...prevState, Data: [], TableHeader: false, TableLabels: false, Target: [], Index: null }));
        setDis(false);

        let fileObj = e.target.files[0];

        if (!fileObj.name.match(/.(csv|xlsx|txt)$/i)) {
          setAlert({ alert: true, severity: "error", message: "file format is not correct , please select a csv or txt file" });
          setLoading(false);
          return;
        }

        else if (fileObj.size > (1048576 * 500)) {
          setAlert({ alert: true, severity: "error", message: "Max file size is 500 Mb" });
          setLoading(false);
          return;
        }

        else {
          setFile(fileObj.name);
          // setOptions([]);
          ExcelRenderer(fileObj, (err, resp) => {
            if (err) setAlert({ alert: true, severity: "error", message: err });
            else {
              setState(prevState => ({ ...prevState, Data: resp.rows, TableHeader: false, TableLabels: false, NameFile: fileObj.name, Target: [], Index: null }));
              setAlert({ alert: true, severity: "success", message: "Data uploaded successfully" });
              setLoading(false);
              setExist(false);
              return;
            }
          });
        }
      }
    },

    getNumberOfEmptyCell = () => {
      let count = 0;
      for (let i = 0, len = state.Data.length; i < len; i++)
        for (let j = 0, l = state.Data[0].length; j < l; j++)
          count += (state.Data[i][j] === undefined) ? 1 : 0;
      return count;
    },
    count = getNumberOfEmptyCell(),

    handleChange = name => event => setState({ ...state, [name]: event.target.checked });

  React.useEffect(() => {

    if (ShareData)

      helpers.fetchFiles().then(
        (response) => { setOptions(response.data); }
      ).catch((err) => {
        err.response.status === 401 ?
          setAlert({ alert: true, message: "Token Expired , Please login again", severity: "error" }) :
          setAlert({ alert: true, message: 'Error Occured , Please try again', severity: "error" });
      });

    // async function fetchModels() {
    //   const
    //     header = { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } },
    //     resp = await fetch(`${state.path}/files`, header);

    //   if (resp.status === 200) {
    //     let files = await resp.json();
    //     setOptions(files);
    //   }
    // };

    // if (ShareData) fetchModels();

  }, [ShareData]);

  const handleFile =  (modelId, fileId) => {

     setLoading(true);

    helpers.linkFileById(modelId, fileId).then(
      (response) => {
        setState(prevState => ({ ...prevState, Data: response.data.data, TableHeader: true, TableLabels: true }));
        setAlert({ alert: true, message: "Data Uploaded Successfully", severity: "success" })
        setExist(true);
        setDis(true);
        setLoading(false);
      }
    ).catch((err) => {
      err.response.status === 401 ?
        setAlert({ alert: true, message: "Token Expired , Please login again", severity: "error" }) :
        setAlert({ alert: true, message: 'Error Occured , Please try again', severity: "error" });
        setLoading(false);
    });


    // const
    //   headers = { 'Content-Type': 'application/json', modelid: state.Id, 'Authorization': 'Bearer ' + token },

    //   res = await fetch(`${state.path}/files/${fileId}`, { method: 'GET', mode: 'cors', headers: headers });
    // const json = await res.json();
    // if (res.status === 200) {
    //   setState(prevState => ({ ...prevState, Data: json.data, TableHeader: true, TableLabels: true }));
    //   setExist(true);
    //   setDis(true);
    // }
    // else {
    //   setAlert({ alert: true, severity: "error", message: json.message });
    // }

    // if (value !== undefined || value != null) {
    //   setData(value);
    //   if (Object.entries(value).length > 0) {
    //     setError(null);
    //     const res = await fetch(`${state.path}/postData`, {
    //       method: 'POST', mode: 'cors',
    //       body: JSON.stringify({
    //         DataId: value,
    //         id: state.Id
    //       }),
    //       headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    //     });

    //     if (res.status === 200) {
    //       const json = await res.json();
    //       let MatrixData = await getMatrixData(json.data, state.Index);
    //       let MarkerNames = await getMarkerNames(json.data, state.Index)
    //       let RowNames = await getRowNames(json.data);
    //       setState(prevState => ({
    //         ...prevState, Data: json.data, TableHeader: true, TableLabels: true, NameFile: data.NameFile,
    //         NumData: MatrixData.NumData,
    //         TextData: MatrixData.TextData,
    //         IntData: MatrixData.IntData,
    //         MarkerNames: MarkerNames,
    //         MarkerNamesNum: MatrixData.MarkerNamesNum,
    //         MarkerNamesTxt: MatrixData.MarkerNamesTxt,
    //         MarkerNamesInt: MatrixData.MarkerNamesInt,
    //         RowNames: RowNames,
    //         DataIsSelected: true,
    //         Index: json.Index,
    //         Indexsaved: json.index,
    //         Target: json.Target
    //       }));
    //     }

    //     else if (res.status === 402) setError("error loading data")
    //     else if (res.status === 403) setError("Your data is empty , please insert a valid data")
    //     else if (res.status === 401) setError("error loading data")
    //     else setError("Something went wrong, please try again");
    //     setLoading(false);
    //   }
    //   else {
    //     setAlert({ alert: true, severity: "error", message: "Data is empty" });
    //   }
    // }
  };

  return <>

    <Row className='ml-2'>
      <ListItemText primary='Select a file to upload' secondary='accepted files : csv , txt ' />
    </Row>

    <Row className='m-2 mb-0'>
      <input className={classes.inputFile1} type="file" name="file" accept=".xlsx,.csv,.txt" onChange={handleUpload.bind(this)} />
      <div className={classes.dropzone}>
        <p> Drag or Click to upload a file ...</p>
        <CloudUploadIcon />
      </div>
    </Row>

    <Row className='m-2 mb-0'>
      <ListItem dense className={classes.db}>
        <ListItemText primary=' Select existing data' />
      </ListItem>
    </Row>

    <Row className='ml-2'>
      <ListItem dense className={classes.db}>
        <Autocomplete
          id="asynchronous-demo"
          onChange={(event, value) => handleFile(state.Id, value._id)}
          open={ShareData}
          onOpen={() => { setShareData(true); }}
          onClose={() => { setShareData(false); }}
          getOptionSelected={(option, value) => option.filename === value.filename}
          getOptionLabel={(option) => option.filename}
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Dataset"
              style={{ width: 350 }}
              variant="outlined"
              InputProps={{ ...params.InputProps }}
            />
          )}
        />
      </ListItem>
    </Row>

    <Row className='ml-2'>
      <ListItem dense className={classes.db}>
        <ListItemText primary=' Or click here to upload form a database' />
        <IconButton onClick={handleClick}> <SystemUpdateAltIcon /></IconButton>
      </ListItem>
    </Row>

    <Dialog fullWidth open={open}>

      <DialogTitle style={{ background: '#27293d' }} id="form-dialog-title" >Database Credentials</DialogTitle>

      <DialogContent dividers style={{ background: '#27293d' }}>

        <div className="container">
          <div className="col-lg-12 push-lg-4 personal-info">
            <form>

              <div className="form-group row">
                <label className="col-lg-4 col-form-label form-control-label">Database Type</label>
                <div className="col-lg-8 float-right">
                  <SelectForm setState={setDbInfo} stateName={dbInfo} stateParam={"dbtypes"} options={dbtypesOptions} />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-4 col-form-label form-control-label">Port Number</label>
                <div className="col-lg-8 float-right">
                  <InputForm setState={setDbInfo} stateName={dbInfo} stateParam={"port"} title={"Port Number"} type={"number"} min={0} max={1000000} step={1} />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-4 col-form-label form-control-label">Host Name</label>
                <div className="col-lg-8 float-right">
                  <InputForm setState={setDbInfo} stateName={dbInfo} stateParam={"host"} title={"Host Name"} type={"text"} />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-4 col-form-label form-control-label">Username</label>
                <div className="col-lg-8 float-right">
                  <InputForm setState={setDbInfo} stateName={dbInfo} stateParam={"username"} title={"User Name"} type={"text"} />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-4 col-form-label form-control-label">Password</label>
                <div className="col-lg-8 float-right">
                  <InputForm setState={setDbInfo} stateName={dbInfo} stateParam={"password"} title={"Password"} type={"password"} />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-4 col-form-label form-control-label">Database Name</label>
                <div className="col-lg-8 float-right">
                  <InputForm setState={setDbInfo} stateName={dbInfo} stateParam={"dbname"} title={"Database Name"} type={"text"} />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-4 col-form-label form-control-label">Table or Collection Name</label>
                <div className="col-lg-8 float-right">
                  <InputForm setState={setDbInfo} stateName={dbInfo} stateParam={"table"} title={"Table or Collection Name"} type={"text"} />
                </div>
              </div>

            </form>
          </div>
        </div>

        <div> {error && <><DialogTitle disableTypography={true} style={{ color: '#e74c3c' }}>{error}</DialogTitle></>} </div>

      </DialogContent>

      <DialogActions style={{ background: '#27293d' }} >
        <Button onClick={handleClick} disabled={loading}> cancel </Button>
        <Button type="submit" onClick={handleDB}
          disabled={loading || !dbInfo.host || !dbInfo.dbname || !dbInfo.table}
        > {loading ? 'Loading...' : 'Upload'}
        </Button>
      </DialogActions>
    </Dialog>

    <Collapse isOpen={state.Data.length !== 0}>

      <Divider style={{ maxWidth: 400 }} />

      <Hidden xsDown>
        <Toolbar variant="dense" style={{ minHeight: 20, maxWidth: 400 }}>
          <CheckCircleOutlineIcon style={{ color: "#2ecc71" }} />
          <ListItem dense><ListItemText primary='Number of rows' />{state.Data.length} </ListItem>
        </Toolbar>

        <Toolbar variant="dense" style={{ minHeight: 20, maxWidth: 400 }}>
          <CheckCircleOutlineIcon style={{ color: "#2ecc71" }} />
          <ListItem dense><ListItemText primary='Number of features ' /> {state.Data[0] ? state.Data[0].length : 0} </ListItem>
        </Toolbar>

        {count === 0 ? <Toolbar variant="dense" style={{ minHeight: 20, maxWidth: 400 }}>
          <CheckCircleOutlineIcon style={{ color: "#2ecc71" }} />
          <ListItem dense><ListItemText primary='No empty cells ' /></ListItem>
        </Toolbar>
          : <>
            <Toolbar variant="dense" style={{ minHeight: 20, maxWidth: 400 }} >
              <HighlightOffIcon style={{ color: "#e74c3c" }} />
              <ListItem dense><ListItemText primary=' Number of Empty cells ' />{count}</ListItem>
            </Toolbar>
            {/* <ListItem button dense><ListItemText primary='handle empty cells' /></ListItem> */}
          </>
        }
        <Divider style={{ maxWidth: 400 }} />
      </Hidden>

      <FormGroup style={{ marginLeft: 30 }}>
        <FormControlLabel classes={{ label: classes.label }}
          control={
            <Checkbox
              disabled={dis}
              checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
              icon={<span className={classes.icon} />}
              checked={state.TableHeader}
              onChange={handleChange('TableHeader')} />
          }
          label="Does your file have a header ?"
        />
        <FormControlLabel classes={{ label: classes.label }}
          control={
            <Checkbox
              disabled={dis}
              checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
              icon={<span className={classes.icon} />}
              checked={state.TableLabels}
              onChange={handleChange('TableLabels')} />
          }
          label="Does your file have row names ?"
        />
      </FormGroup>

    </Collapse>

    <Alert alert={alert} setAlert={setAlert} />

    {loading ? <Progress open={loading} /> : null}

  </>

}

export default withContext(UploadData);


