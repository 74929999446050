import React from 'react';

import Prediction from './Prediction';
import ApiGen from './ApiGen';
import { Share } from '../../../Components';


export const customStyles = {
    control: (base, state) => ({
        ...base,
        backgroundColor: '#1e1e2f',
        color: 'white',
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        borderColor: state.isFocused ? "blue" : "white",
        boxShadow: state.isFocused ? null : null,
    }),

    singleValue: (provided) => ({
        ...provided,
        color: 'white',
    }),

    option: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? 'grey' : 'white'
    }),

    menu: base => ({
        ...base,
        background: '#1e1e2f',
    }),

    menuList: base => ({
        ...base,
        background: '#212121',
    })
};

const CustomizedMenus = ({ path, Data, setData, Model, setOutClassPr, setEnablePr, setMadXpPr, setType, open, setOpen, handlesend, setAlert}) => {

    const handleClose = () => setOpen({ O1: false, O2: false, O3: false });

    return <>

        <Prediction
            open={open.O1}
            handleClose={handleClose}
            Data={Data}
            setData={setData}
            Model={Model}
            path={path}
            setOutClassPr={setOutClassPr}
            setEnablePr={setEnablePr}
            setMadXpPr={setMadXpPr}
            setType={setType}
            alert={alert}
            setAlert={setAlert}
        />

        <ApiGen
            open={open.O2}
            handleClose={handleClose}
            handlesend={handlesend}
            Model={Model} path={path}
            setAlert={setAlert}
        />

        <Share
            open={open.O3}
            handleClose={handleClose}
            id={Model.id}
            path={path}
            setAlert ={setAlert}
        />

    </>

}
export default CustomizedMenus;
