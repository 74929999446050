import React, { useState } from 'react';
import { Collapse } from "reactstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { IconButton, Slider } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { SelectForm, InputForm } from "../../../../Components";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    normal: {},
    disabled: {
        pointerEvents: 'none',
        opacity: 0.6
    }
}));

const Validation = ({ Model, state, setState, open, handelClose }) => {
    const
        classes = useStyles(),
        [edit, setEdit] = useState(false),
        handleEdit = () => setEdit(true),
        handelCancel = () => {
            setState(prevState => ({
                ...prevState,
                ValidMethod: Model.validMethod,
                TrainRate: Model.trainRate,
                TrainingMethod: Model.trainingMethod,
                MembershipModel: Model.membershipModel,
                MembershipIndex: Model.membershipIndex,
                KNumber: Model.kNumber,
                KernelFunction: Model.kernelFunction
            }));
            handelClose();
        };

    const
        marks = [{ value: 100, label: '100%' }, { value: 0, label: '0%' }],

        handleHoldOut = (e, value) => setState(prevState => ({ ...prevState, trainRate: value })),

        ValidMethodeOptions = [
            {
                label: "Leave One Out",
                value: "LeaveOneOut"
            },
            {
                label: "Hold Out",
                value: "HoldOut"
            },
        ],
        ModelOptions = state.problemType === "Regression" ?
            [
                {
                    label: "Support Vector Machine",
                    value: "SVM",
                }
            ]
            :
            [
                {
                    label: "LAMDA",
                    value: "LAMDA"
                },
                {
                    label: "K-Nearest Neighbors",
                    value: "kNN",
                },
                {
                    label: "Support Vector Machine",
                    value: "SVM",
                },
            ],

        MembershipOptions = [
            {
                label: "Gaussian",
                value: "gauss"
            },
            {
                label: "Binomial",
                value: "f_lamda"
            },
            {
                label: "Gaussian Diagonal",
                value: "gauss_diag"
            },
        ],

        KernelFunctionOptions = state.problemType === "Regression" ?
            [
                {
                    label: "Linear",
                    value: "linear"
                },
                {
                    label: "Gaussian",
                    value: "gaussian"
                },
                {
                    label: "Polynomial",
                    value: "polynomial"
                },

            ] :
            [

                {
                    label: "Linear",
                    value: "linear"
                },
                {
                    label: "Quadratic",
                    value: "quadratic"
                },
                {
                    label: "Polynomial",
                    value: "polynomial"
                },
                {
                    label: "RBF",
                    value: "RBF"
                },
                {
                    label: "LMP",
                    value: "LMP"
                },
            ],

        SolverRegOptions = [
            {
                label: "ISDA",
                value: "ISDA"
            },
            {
                label: "L1QP",
                value: "L1QP"
            },
            {
                label: "SMO",
                value: "SMO"
            },
        ];

    return (
        <>
            <Dialog fullWidth open={open} onClose={handelClose}>
                <div className="container">
                    <div style={{ background: '#27293d' }} className={"row"}>
                        <div className={"col-6"}>
                            <DialogTitle id="form-dialog-title">Model Settings</DialogTitle>
                        </div>
                        <div className={"col-6"}>
                            <IconButton className='float-right mt-2' onClick={handleEdit}>
                                <EditIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>

                <DialogContent dividers style={{ background: '#27293d' }}>
                    <div className="container">
                        <div className="col-lg-12  personal-info">
                            <form className={edit ? classes.normal : classes.disabled}>
                                <div className="form-group row">
                                    <label className="col-lg-4 col-form-label form-control-label">Valid Method</label>
                                    <div className="col-lg-8 float-right">
                                        <SelectForm setState={setState} stateName={state} stateParam={"validMethod"} options={ValidMethodeOptions} />
                                    </div>
                                </div>

                                <Collapse isOpen={state.validMethod === "HoldOut"}>
                                    <div className="form-group row">
                                        <label className="col-lg-4 col-form-label form-control-label">Train Rate : {state.trainRate}%</label>
                                        <div className="col-lg-8 ">
                                            <Slider min={0} value={state.trainRate} valueLabelDisplay="auto"
                                                onChange={handleHoldOut} onInput={handleHoldOut} marks={marks} track='inverted'
                                            />
                                            {state.trainRate === 0 ? <div className="alert alert-danger" role="alert"> Train rate should be greater than 0 </div> : null}
                                            {state.trainRate === 100 ? <div className="alert alert-danger" role="alert"> Train rate should be less than 100 </div> : null}
                                        </div>
                                    </div>
                                </Collapse>

                                <div className="form-group row">
                                    <label className="col-lg-4 col-form-label form-control-label">Training Method</label>
                                    <div className="col-lg-8 float-right">
                                        <SelectForm setState={setState} stateName={state} stateParam={"trainingMethod"} options={ModelOptions} />
                                    </div>
                                </div>

                                <Collapse isOpen={state.trainingMethod === 'LAMDA' || state.trainingMethod === 'kNN' || state.trainingMethod === 'SVM'}>
                                    {
                                        state.problemType !== "Regression" ? <>
                                            {
                                                state.trainingMethod === 'LAMDA' ?
                                                    <>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label form-control-label">Membership Function</label>
                                                            <div className="col-lg-8 float-right">
                                                                <SelectForm setState={setState} stateName={state} stateParam={"membershipModel"} options={MembershipOptions} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label form-control-label">Membership Index</label>
                                                            <div className="col-lg-8 float-right">
                                                                <InputForm setState={setState} stateName={state} stateParam={"membershipIndex"} title={"Must be number between 0 and 1"} type={"number"} min={0} max={1} step={0.1} />
                                                            </div>
                                                        </div>
                                                    </>

                                                    : state.trainingMethod === 'kNN' ?
                                                        <>
                                                            <div className="form-group row">
                                                                <label className="col-lg-4 col-form-label form-control-label">Number of Neighbors</label>
                                                                <div className="col-lg-8 float-right">
                                                                    <InputForm setState={setState} stateName={state} stateParam={"kNumber"} title={"Must be positive integer"} type={"number"} min={1} max={10} step={1} />
                                                                </div>
                                                            </div>
                                                        </>

                                                        : state.trainingMethod === 'SVM' ?
                                                            <>
                                                                <div className="form-group row">
                                                                    <label className="col-lg-4 col-form-label form-control-label">Kernel Function</label>
                                                                    <div className="col-lg-8 float-right">
                                                                        <SelectForm setState={setState} stateName={state} stateParam={"kernelFnc"} options={KernelFunctionOptions} />
                                                                    </div>
                                                                </div>

                                                                {
                                                                    state.kernelFnc === "Polynomial" ?
                                                                        <>
                                                                            <div className="form-group row">
                                                                                <label className="col-lg-4 col-form-label form-control-label">Poly Order</label>
                                                                                <div className="col-lg-8 float-right">
                                                                                    <InputForm setState={setState} stateName={state} stateParam={"kernelParam"} title={"Must be positive integer"} type={"number"} min={1} max={10} step={1} />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        : null
                                                                }
                                                                {
                                                                    state.kernelFnc === "RBF" ?
                                                                        <>
                                                                            <div className="form-group row">
                                                                                <label className="col-lg-4 col-form-label form-control-label">RBF Sigma</label>
                                                                                <div className="col-lg-8 float-right">
                                                                                    <InputForm setState={setState} stateName={state} stateParam={"kernelParam"} title={"RBF Sigma"} type={"text"} />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        : null
                                                                }
                                                                {
                                                                    state.kernelFnc === "LMP" ?
                                                                        <>
                                                                            <div className="form-group row">
                                                                                <label className="col-lg-4 col-form-label form-control-label"> LMP Param</label>
                                                                                <div className="col-lg-8 float-right">
                                                                                    <InputForm setState={setState} stateName={state} stateParam={"kernelParam"} title={"LMP Param"} type={"text"} />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        : null
                                                                }
                                                                <div className="form-group row">
                                                                    <label className="col-lg-4 col-form-label form-control-label">Box Constraint</label>
                                                                    <div className="col-lg-8 float-right">
                                                                        <InputForm setState={setState} stateName={state} stateParam={"BoxConstraint"} title={"Box Constraint"} type={"text"} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null
                                            }
                                        </> : <>
                                            {
                                                state.trainingMethod === 'SVM' ?
                                                    <>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label form-control-label">Kernel Function</label>
                                                            <div className="col-lg-8 float-right">
                                                                <SelectForm setState={setState} stateName={state} stateParam={"kernelFnc"} options={KernelFunctionOptions} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label form-control-label">Solver</label>
                                                            <div className="col-lg-8 float-right">
                                                                <SelectForm setState={setState} stateName={state} stateParam={"solverReg"} options={SolverRegOptions} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label form-control-label">Kernel Scale</label>
                                                            <div className="col-lg-8 float-right">
                                                                <InputForm setState={setState} stateName={state} stateParam={"kernelScale"} title={"Must be auto or number between 0 and 1"} type={"number"} min={0} max={1} step={0.1} />
                                                            </div>
                                                        </div>
                                                    </> : null
                                            }
                                        </>
                                    }
                                </Collapse>
                            </form>
                        </div>
                    </div>
                </DialogContent>

                <DialogActions style={{ background: '#27293d' }}>
                    <Button onClick={handelCancel} color="primary"> Cancel </Button>
                    <Button onClick={handelClose} color="primary"> Save </Button>
                </DialogActions>

            </Dialog>
        </>

    );
}

export default Validation;