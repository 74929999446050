import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Dialog, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReactTable from "react-table";
import "react-table/react-table.css";
import { HorizontalBar } from 'react-chartjs-2';
import _ from 'underscore'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { saveCsv } from '../../../Components/Exports/Exports';


const Prediction = ({ Data, MadXpPr, OutClassPr, SelectedHeader }) => {

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [idx, setIdx] = useState(0);
  const handleIndex = (index) => setIdx(index);
  

  const getSelectedData = () => {
    let SelectedData = [];
    for (let i = 0, len = Data.length; i < len; i++) {
      var row = [];
      for (let j = 0, len = Data[0].length; j < len; j++)
        if (SelectedHeader.includes( Data[0][j]))
            row.push(Data[i][j]);
        else continue;
      SelectedData.push(row)
    }
    return SelectedData;
  };

  let selecData = getSelectedData();

  let DTest = selecData.slice(1);
  let labels = selecData[0];

  const getDataTest = () => {
    let DataTest = [];
    for (let i = 0, len = DTest.length; i < len; i++)  DataTest.push(_.object(labels, DTest[i]));
    return DataTest;
  }

  const getTableData = () => {
    const TableData = [], DataTest = getDataTest();
    for (let i = 0, len = OutClassPr.length; i < len; i++)
      TableData.push({ predicted: OutClassPr[i], ...DataTest[i] })
    return TableData;
  }

  const getColumns = () => {
    let columns = [
      {
        Header: props => <ListItem dense > <ListItemText primary='Predicted' /> </ListItem>,
        accessor: 'predicted',
        style: { textAlign: 'left' },
        Cell: props =>
          <ListItem button dense onClick={handleOpen}>
            <Tooltip style={{ borderRaduis: 0 }} title={<div style={{ height: '25vh', width: '15vh' }}>
              <HorizontalBar data={chartData} options={HoverOptions} /> </div>} >
              <ListItemText style={{ textDecoration: 'underline ' }} primary={props.value} />
            </Tooltip>
          </ListItem>
      }
    ]
    for (let i = 0, len = labels.length; i < len; i++) {
      columns.push({
        Header: props => <ListItem dense > <ListItemText primary={labels[i]} /> </ListItem>,
        accessor: labels[i],
        style: { textAlign: 'left' },
        Cell: props => <ListItem dense > <ListItemText primary={props.value} /> </ListItem>
      })
    }
    return columns;
  }

  const HoverOptions = {
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      xAxes: [{ barThickness: 5, ticks: { fontColor: 'white' } }],
      yAxes: [{ barThickness: 5, ticks: { fontColor: 'white' } }]
    },
  }

  const chartData = { labels: labels, datasets: [{ backgroundColor: 'rgba(255,0,0)', data: MadXpPr[idx] }] }

  const options = {
    title: { display: true, text: 'Explainability', fontColor: 'white' },
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      xAxes: [{ barThickness: 5, scaleLabel: { display: true, labelString: 'Importance', fontColor: 'white' }, ticks: { fontColor: 'white' } }],
      yAxes: [{ barThickness: 5, scaleLabel: { display: true, labelString: 'Features', fontColor: 'white', lineHeight: 2.5 }, ticks: { fontColor: 'white' } }]
    },
  }

  return <>
    <ListItem dense>
      <ListItemText primary='' />
      <Tooltip title='export  to csv file' placement='top'>
        <IconButton onClick={() => { saveCsv('Predicted Table', getTableData()) }}> <SystemUpdateAltIcon fontSize='small' /> </IconButton>
      </Tooltip>
    </ListItem>

    <ReactTable
      minRows={0}
      data={getTableData()}
      columns={getColumns()}
      defaultPageSize={Data.length < 20 ? Data.length : 20}
      style={{ margin: "auto", background: 'rgb(39, 41, 61)', width: "92vw", height: "70vh", border: '2px solid  #212121' }}
      resizable={false}
      className="-striped -highlight"
      showPagination={true}
      onRowClick={handleOpen}
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          onMouseEnter: () => {
            if (column.id === 'predicted') handleIndex(rowInfo.index)
          }
        };
      }}
    />

    <Dialog open={open} onClose={handleClose} >
      <ListItem dense>
        <IconButton onClick={() => { saveCsv("MadXp Table", [labels, MadXpPr[idx]]) }}> <SystemUpdateAltIcon /> </IconButton>
        <ListItemText primary='' /> <IconButton><CloseIcon onClick={handleClose} /></IconButton>
      </ListItem>
      <div style={{ height: '60vh' }}>
        <HorizontalBar
          style={{ minHeight: '60vh', maxHeight: '40vh', minWidth: '20vw', maxWidth: '20vw' }}
          data={chartData} options={options} /> </div>
    </Dialog>

  </>


}
export default Prediction


