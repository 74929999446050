import React, {useState} from 'react';
import {CardBody, CardTitle, Col, Collapse} from "reactstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {IconButton} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import {SelectForm, InputForm } from "../../../../Components";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  normal : {} ,
  disabled: {
    pointerEvents: 'none',
    opacity: 0.6
  }
}));

const Features = ({ Model , state , setState , open , handelClose}) => {
    const classes = useStyles();const  [edit ,setEdit] = useState(false);

    const handleEdit =() => setEdit(true);

    const handelCancel = () => {

    setState(prevState  => ({...prevState ,
      ValidMethod : Model.validMethod , 
      TrainRate : Model.trainRate , 
      TrainingMethod : Model.trainingMethod , 
      MembershipModel : Model.membershipModel ,
      MembershipIndex : Model.membershipIndex , 
      KNumber : Model.kNumber, 
      KernelFunction : Model.kernelFnc
    }));
    handelClose();
  };

  const membershipOptions   = [
    {
      label: "Gaussian",
      value: "gauss"
    },
    {
      label: "Binomial",
      value: "f_lamda"
    },
  ];
  const FitMethodFSOptions  = [
    {
      label: "exact",
      value: "exact"
    },
    {
      label: "non",
      value: "non"
    },
    {
      label: "average",
      value: "average"
    },
  ];
  const SolverFSOptions     = [
    {
      label: "lbfgs",
      value: "lbfgs"
    },
    {
      label: "sgd",
      value: "sgd"
    },
    {
      label: "minibatch-lbfgs",
      value: "minibatch-lbfgs"
    },
  ];
  const LossFunctionFSOptions = [
    {
      label: "mad",
      value: "mad"
    },
    {
      label: "mse",
      value: "mse"
    },
    {
      label: "epsiloninsensitive",
      value: "epsiloninsensitive"
    },
  ];

  return (
      <>
        <Dialog fullWidth open={open} onClose={handelClose}>
          <div className="container">
            <div style={{ background: '#27293d' }} className={"row"}>
              <div className={"col-8"}>
                <DialogTitle id="form-dialog-title">Feature Selection Settings</DialogTitle>
              </div>
              <div className={"col-4"}>
                <IconButton className='float-right mt-2' onClick={handleEdit}>
                  <EditIcon/>
                </IconButton>
              </div>
            </div>
          </div>

          <DialogContent dividers style={{background: '#27293d'}}>

            <div className="container">
              <div className="col-lg-12  personal-info">
                <form className={edit ? classes.normal : classes.disabled }>
                  {
                    state.problemType !== "Regression" ? <>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label form-control-label">Membership Function</label>
                      <div className="col-lg-8 float-right">
                        <SelectForm setState = {setState} stateName={state} stateParam = {"membershipFS"} options={membershipOptions}/>
                      </div>
                    </div> </> : null
                  }

                  {
                     state.problemType === "Regression" ? <>

                       <div className="form-group row">
                         <label className="col-lg-4 col-form-label form-control-label">Lambda</label>
                         <div className="col-lg-8 float-right">
                           <InputForm setState={setState} stateName={state} stateParam={"lambda"} title={"Must be number between 0 and 1"} type={"number"} min = {0} max = {1} step = {0.1}  />
                         </div>
                       </div>

                       <div className="form-group row">
                         <label className="col-lg-4 col-form-label form-control-label">Fit Method</label>
                         <div className="col-lg-8 float-right">
                           <SelectForm setState = {setState} stateName={state} stateParam = {"fitMethodFS"} options={FitMethodFSOptions}/>
                         </div>
                       </div>

                       <div className="form-group row">
                         <label className="col-lg-4 col-form-label form-control-label">Solver</label>
                         <div className="col-lg-8 float-right">
                           <SelectForm setState = {setState} stateName={state} stateParam = {"solverFS"} options={SolverFSOptions}/>
                         </div>
                       </div>

                       <div className="form-group row">
                         <label className="col-lg-4 col-form-label form-control-label">Loss Function</label>
                         <div className="col-lg-8 float-right">
                           <SelectForm setState = {setState} stateName={state} stateParam = {"lossFunctionFS"} options={LossFunctionFSOptions}/>
                         </div>
                       </div>
                    </> : null
                  }


                      <div  className = {`form-group row ${ state.N == state.markerNames.length ? classes.normal : classes.disabled  }`} >
                        <label className="col-lg-4 col-form-label form-control-label">Threshold</label>
                        <div className="col-lg-8 float-right">
                          <InputForm setState={setState} stateName={state} stateParam={"threshold"} title={"Must be number between 0 and 1"} type={"number"} min = {0} max = {1} step = {0.1}  />
                        </div>
                      </div>

                      <div className = {` form-group row ${ state.threshold == 0 ? classes.normal : classes.disabled }`}>
                        <label className="col-lg-4 col-form-label form-control-label">Number of Markers</label>
                        <div className="col-lg-8 float-right">
                          <InputForm setState={setState} stateName={state} stateParam={"N"} title={`Must be number between 0 and ${state.markerNames.length}`} type={"number"} min = {0} max = {state.markerNames.length} step = {1}/>
                        </div>
                      </div>

                </form>
              </div>
            </div>
          </DialogContent>

          <DialogActions style={{ background: '#27293d' }}>
            <Button onClick={handelCancel} color="primary"> Cancel </Button>
            <Button onClick={handelClose} color="primary"> Save </Button>
          </DialogActions>

        </Dialog>
      </>
  );
}

export default Features;