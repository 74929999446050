import React, { useState } from 'react';
import { Toolbar } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ListItem, ListItemText } from '@material-ui/core';
import Summary from './ClusterSteps/Summary';
import Results from './ClusterSteps/Results';
import StateMap from './ClusterSteps/State_Map';
import { withContext } from '../../../../../../context';
import Divider from '@material-ui/core/Divider';
import _ from 'underscore';


const Cluster = ({ state , setState }) => {

    const [step, setStep] = useState('Step1');
    const handleStep = (e) => setStep(e.currentTarget.dataset.value);
    const [input, setInput] = React.useState(_.first(state.MarkerNamesNum, 5));

    return <>

        <CssBaseline/>

        <Toolbar variant='dense'>
            <ListItem dense selected={step === 'Step1'}
                button onClick={handleStep} data-value="Step1">
                <ListItemText primary="Summary"/>
            </ListItem>

            <ListItem dense selected={step === 'Step2'}
                button onClick={handleStep} data-value="Step2">
                <ListItemText primary="Results"/>
            </ListItem>

            <ListItem dense selected={step === 'Step3'}
                button onClick={handleStep} data-value="Step3" >
                <ListItemText primary="State Mapping"/>
            </ListItem>

        </Toolbar>

        <Divider/>

        { step === 'Step1' ?  <Summary />  : null }

        { step === 'Step2' ?  <Results  input = {input}  setInput = {setInput} />  : null }

        { step === 'Step3' ?  <StateMap  Target ={ state.Target }  setState = { setState }/>  : null }
    </>

}
export default withContext(Cluster) 